import React, { Component } from "react";
import "../../Styles/order-list.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getReviewsList,
  searchReviews,
  getReviewCountBySellerId,
} from "../../Redux/Actions/reviewAction";
import { debounce } from "lodash";

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};

class Review_List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      items: [],
      searchKeyword: "",
      hasMore: true,
      showLoader: false,
      isChecked: false,
    };
    this.productName = this.props.location.state;
  }

  componentDidMount() {
    this.productId = this.props?.match?.params?.productId;
    this.fetchMoreData(true);
    this.fetchReviewCount();
    // console.log('QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ')
  }

  componentWillReceiveProps(nextProps, prevProps) {
    // console.log(nextProps,"}}}}}}}}}}}}}}}}")

    // console.log(nextProps?.location.pathname !== this.props?.location?.pathname,"+++++++++++++++++++++++++++++++")
    // console.log(this.props,"________________________________")
    if (nextProps?.location.pathname !== this.props?.location?.pathname) {
      (async () => {
        const result = await this.props.getReviewsList(
          localStorage.getItem("sellerId"),
          0,
          nextProps?.match?.params?.productId
        );
        this.setState((prevState) => {
          return {
            ...prevState,
            items: result.data.data,
            showLoader: false,
          };
        });
      })();
      this.productId = nextProps?.match?.params?.productId;
      this.productName = this?.props?.location?.state;
    }
  }

  fetchReviewCount = async () => {
    await this.props.getReviewCountBySellerId(localStorage.getItem("sellerId"));
  };

  fetchMoreData = async (firstLoad) => {
    this.render();
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;

        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
          result = await this.props.searchReviews(
            localStorage.getItem("sellerId"),
            this.state.offset,
            this.state.searchKeyword.trim().replace(/[^a-zA-Z 0-9.]+/g, ""),
            this.productId
          );
        } else {
          result = await this.props.getReviewsList(
            localStorage.getItem("sellerId"),
            this.state.offset,
            this.productId
          );
          // console.log(result.data.data, "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        }
        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: prevState.items.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  handleChange = async (event, field) => {
    event.preventDefault();
    // console.log("event value is", +event.target.value);

    if (field === "maxLimit") {
      await this.setState({
        [field]:
          event.target.value === "all"
            ? event.target.value
            : +event.target.value,
      });
      this.fetchMoreData(false);
      this.fetchReviewCount();
    } else {
      await this.setState({
        [field]: event.target.value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
        maxLimit: 10,
      });

      this.debounceSearchCall();
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      items: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  handleCheck = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  render() {
    const orderItems =
      this.state.items && this.state.items.length > 0 ? (
        this.state.items.map((i) => {
          //   const fullAddress = i.address ? JSON.parse(i.address) : null;
          const date = i.created_ts
            ? new Date(i.created_ts).toISOString().substring(0, 10)
            : null;
          return (
            <tr key={i.id}>
              {/* <td align="center" key={i.id}>
              {i.order_id ? i.order_id : i.id}
            </td> */}
              <td>{i.id ? i.id : "-"}</td>
              {this.productId ? null : (
                <td>
                  <Link to={`/edit-product/${i.slug}`}>
                    {i.product_name ? i.product_name : "-"}
                  </Link>
                </td>
              )}
              <td>{i.email ? i.email : "-"}</td>
              <td>{i.rating ? i.rating : "-"}</td>
              <td>{i.status ? "yes" : "no"}</td>
              <td>{i.created_ts ? i.created_ts.slice(0, 10) : "-"}</td>
              <td>{i.is_reported ? "Yes" : "No"}</td>
              {/* <td>{i.comment ? i.comment : "There is no comment"}</td> */}
              {/* <td>{date}</td> */}
              {/* <td>{fullAddress ? fullAddress.city : null}</td> */}
              {/* <td>${(i.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td> */}
              {/* <td>
              <p className="orange">{i.status}</p>
            </td> */}
              {/* <td align="center" style={{ display: "grid" }}>
                          <Link to={"#"} className="orange">edit</Link>
                      </td> */}
              <td align="center" style={{ display: "grid" }}>
                <Link to={`/review/${i.order_id}`} className="orange">
                  edit
                </Link>
              </td>
            </tr>
          );
        })
      ) : (
        <p className="mx-auto"> There is no data found </p>
      );
    return (
      <div className="admin-card">
        <div className="admin-card-head">
          <h2>reviews list {this.productId ? " - " + this.productName : ""}</h2>
        </div>
        <div className="admin-card-box">
          <div className="vendor-table">
            <div className="vendor-table-head">
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="entries-drop">
                    <label>show</label>
                    <select
                      className="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="vendor-table-head-right">
                    <div className="vendor-table-search mr-2">
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChange(e, "searchKeyword")}
                        value={this.state.searchKeyword}
                        type="text"
                        placeholder="search"
                      />
                    </div>
                    {/* <div className="download-statement-btn">
                                            <button type="button" className="hyper-link-btn">download statement</button>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="vendor-table-body">
              <div className="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={() => {
                    this.fetchMoreData(false);
                    this.fetchReviewCount();
                  }}
                  hasMore={this.state.hasMore}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        {/* <td align="center">order id</td> */}
                        <td>Id</td>
                        {this.productId ? null : <td>Product</td>}
                        <td>Customer Email</td>
                        <td>rating</td>
                        <td>Is active</td>
                        <td>Created on</td>
                        <td>is reported</td>
                        <td>Action</td>
                        {/* <td>Comment</td> */}
                        {/* <td align="center">Action</td> */}
                      </tr>
                    </thead>
                    <tbody>
                      {orderItems}
                      {this.state.showLoader ? (
                        <tr>
                          <td colSpan={8} className="text-center">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getReviewsList, searchReviews, getReviewCountBySellerId },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Review_List);
