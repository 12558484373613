import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {getPageContent} from "../../Redux/Actions/dashboardAction";
import logo from "../../assets/images-2/logo.png";
import {useHistory} from "react-router";

const Page = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const pageSlug = props.match.params.slug;
    const [pageContent, setPageContent] = useState('')

    useEffect(()=>{
        loadPage()
    },[])

    const loadPage = async () => {
        const response = await dispatch(getPageContent(pageSlug))
        if(response && response.data){
            setPageContent(response.data)
        }
    }

  return (
    <div className="container mt-4 mb-4">
        <div className="mb-4">
            <img src={logo} alt={logo} onClick={()=>history.push("/")} style={{cursor:"pointer"}}/>
        </div>

        {
            pageContent && pageContent.content && <div dangerouslySetInnerHTML={{
                __html:pageContent.content
            }}/>
        }
      {/*<h4>PRIVACY POLICY</h4>
      <p>
        Subsciety LLC (referred to as “Company,” “we,” “us” or “our”) respects
        your privacy and is committed to protecting your privacy online. The
        purpose of this document (the “<strong>Privacy Policy</strong>”) is to
        explain to you how we use information that we have collected from and
        about you. If, after reviewing the Privacy Policy, you have questions
        about the types of information we collect, how we use it, or similar
        issues, please contact us at Info@subsciety.com.
      </p>
      <p>
        This Privacy Policy applies to the services (the “
        <strong>Services</strong>”) provided through the Company website located
        at Subsciety.com (the “<strong>Website</strong>”) and all mobile
        platforms made available by the Company to deliver the Services. It does
        not apply to the practices of other companies that Company does not own
        or control nor does it apply to information of any kind provided to the
        Company by advertisers or other business partners. Links to other
        websites are governed by their own policies.{" "}
      </p>
      <p>
        If this Privacy Policy is not acceptable to you, you should discontinue
        using the Services.
      </p>
      <p>The Privacy Policy will let you know:</p>
      <ul>
        <li>What information we collect from or about you</li>
        <li>What we use your information for</li>
        <li>In what ways we may disclose your information to other parties</li>
        <li>
          The kinds of security measures in place to protect against loss or
          misuse of your personal information
        </li>
        <li>How long we retain your information </li>
        <li>
          What cookies are, and other forms of tracking that may be in use on
          the Website
        </li>
        <li>
          Other privacy policies to which you may become subject when you use
          the Services
        </li>
        <li>
          How you can access, update or delete your Personally Identifiable
          Information
        </li>
        <li>What happens if we make changes to the Privacy Policy</li>
      </ul>
      <p>
        <strong>1. What information do we collect from or about you? </strong>
      </p>
      <p>
        In this Policy, we refer to two types of user information: Personally
        Identifiable Information (sometimes referred to as “PII”) and
        non-Personally Identifiable Information.
      </p>
      <p>
        Personally Identifiable Information refers to any information that can
        be used to uniquely identify, contact, or locate you. Examples of
        Personally Identifiable Information include a name, physical address,
        e-mail address, telephone number, date of birth, or a credit card
        number.
      </p>
      <p>
        The Company collects only the PII that you voluntarily provide to us,
        such as when you register to use the Services, set up a profile, create
        content, sign up for a special offer, enter a sweepstakes or promotion,
        or complete a survey.
      </p>
      <p>
        You might also be asked for, or choose to provide, additional
        information about yourself that does not, itself, specifically identify
        you. This is non-Personally Identifiable Information. However, some
        information of this kind, such as your age, gender, geographic location
        and shopping preferences may be required (or merely requested) when you
        register for the Services, and may be associated with your PII.
      </p>
      <p>
        Company may also automatically collect other types of non-Personally
        Identifiable Information as you use the Services, including technical
        information (such as your internet protocol (“IP”) address, mobile
        device ID, or browser type) and usage information (such as location
        data, preferred language, the parts of the Services you have visited,
        which links you have clicked and other information about how you
        interact with the Services). This information is anonymous, and is not
        associated with your PII, although it may be associated with other
        pieces of anonymous information about you.
      </p>
      <p>
        <strong>2. What do we use your information for? </strong>
      </p>
      <p>
        Your Personally Identifiable Information may be used in one of the
        following ways:
      </p>
      <ul>
        <li>
          To contact you regarding your account. These communications may
          include notices regarding changes to our policies, service updates,
          account management procedures and customer service transactional
          messages.
        </li>
        <li>
          To e-mail you newsletters or tips about how to use the Services,
          inform you of product updates, or notify you about our own special
          offers or those of our third-party partners.{" "}
        </li>
      </ul>
      <p>
        Non-Personally Identifiable Information may be used in one of the
        following ways:
      </p>
      <ul>
        <li>
          To enable us to generate aggregate data about site traffic and site
          interaction.
        </li>
        <li>
          To customize your experience (your information helps us to better
          respond to your individual needs).
        </li>
        <li>
          To show you advertisements most relevant to your demographic
          characteristics.
        </li>
        <li>To make improvements to the Website and Services</li>
        <li>
          To inform potential partners and advertisers about the size and
          characteristics of our audience
        </li>
      </ul>
      <p>
        <strong>3. Do we disclose any information to other parties? </strong>
      </p>
      <p>
        The Company does not sell or trade your Personally Identifiable
        Information to outside parties.. We may disclose your Personally
        Identifiable Information to vendors who help us operate the Website,
        conduct our business or provide the Services to you, such as customer
        service centers or e-mail marketing providers. All such partners are
        bound to keep your information confidential.
      </p>
      <p>
        We may disclose Personally Identifiable Information, including to local,
        state, or federal law enforcement officials, when we believe in good
        faith that the law requires such action, or when required to do so by
        order of a court or other compulsory legal or regulatory process. We may
        also share Personally Identifiable Information in order to investigate,
        prevent, or take action regarding illegal activities, suspected fraud,
        situations involving potential threats to the physical safety of any
        person, or to prevent harm to the Website.
      </p>
      <p>
        We may transfer your Personally Identifiable Information if we are
        acquired by or merge with another company. We will notify you in
        accordance with the procedures contained in this Privacy Policy if your
        information will become subject to a different privacy policy.
      </p>
      <p>
        We may use or distribute information about you and your use of the
        Services in ways that do not reveal Personally Identifiable Information.
        For example, we share anonymous user information with advertisers and
        advertising networks to enable the display of targeted, relevant
        advertisements. We may also provide anonymous usage data or summary
        characteristics of our audience to potential partners and advertisers or
        post such information on publicly accessible areas of the Website.{" "}
      </p>
      <p>
        <strong>4. How secure is your personal information?</strong>
      </p>
      <p>
        The security of our user data is very important to us. We store your
        information securely, using industry-compliant encryption technology.
        When you enter sensitive information on our web forms, we encrypt the
        transmission of that information using secure socket layer technology
        (SSL).
      </p>
      <p>
        <strong>5. How long do we retain your information?</strong>
      </p>
      <p>
        The retention period of collected information depends on the type of
        information and the reasons why we collect it.{" "}
      </p>
      <p>
        Your account information is retained while your account is active,
        unless you request us to delete the information or close your account in
        which case some information may still be retained for a reasonable time
        in case you decide to re-activate your account. We may also retain some
        information we believe in good faith is necessary for legal and
        regulatory compliance, research and development, business matters, and
        improvement of our Services, in which case we would anonymize and
        encrypt such information until complete deletion. Further, some
        information may be retained if it is contained in deleted emails or
        electronic documents which are archived by our company in compliance
        with our standard archival processes but which, in the ordinary course
        of operations, are not accessible by the individuals who created or
        received such emails or documents.{" "}
      </p>
      <p>
        If you request us to delete your information or close your account, some
        of the information you shared through the Services may still be
        accessible by those with whom you shared it.{" "}
      </p>
      <p>
        <strong>6. Do we use cookies or other forms of tracking? </strong>
      </p>
      <p>
        Cookies are small files that a website or one of its service providers
        transfers to your computer’s storage device via your Web browser (if
        your browser is configured to permit this) that enable its systems to
        recognize your browser and store certain information. We use “cookies”
        to collect information and improve our Services. We may use “persistent
        cookies” to save your user ID and password for future logins to the
        Service. We may use “session ID cookies” to enable certain features of
        the Service, to better understand how you interact with the Service and
        to monitor aggregate usage and web traffic routing on the Service.
      </p>
      <p>
        Cookies cannot be used to run programs or deliver viruses to your
        computer. Cookies are uniquely assigned to your computer, and can only
        be read by a web server of the website that issued the cookie to you.
      </p>
      <p>
        Depending on your web browser, you may have the ability to accept or
        decline cookies. Most web browsers default to accept cookies but enable
        you to configure settings to decline them. For further information about
        disabling cookies you can visit www.allaboutcookies.org. If you choose
        to decline cookies, you may not be able to sign in or use other features
        of the Services that depend on cookies.
      </p>
      <p>
        We may integrate with tools made available by social media companies
        such as Facebook, Instagram and Twitter, in order to offer social
        features such as content sharing. These companies may use cookies or
        other tracking technologies in order to recognize you when you use their
        tools in the future.
      </p>
      <p>
        Company maintains internal logs of usage of the Services. Logs include
        information such as IP address, browser type and version, computer
        processing speed, connection speed, clickstream data, and computer
        operating system. Company uses logs mainly for internal systems
        administration and diagnostic purposes, and to detect improper use of
        the site. Logs may also be used to generate aggregate, non-Personally
        Identifiable Information for advertisers or other third parties. The
        Company may collect such usage logs even if the App is not open on your
        device, or you do not have the Website open.
      </p>
      <p>
        Company does not use cookies, similar technologies, or its internal logs
        to associate your Personally Identifiable Information with data about
        your use of the site.
      </p>
      <p>
        f you register for any part of the Services, we may offer you the option
        to save certain information, such as your username and password, via
        Local Device Storage (LDS). You can disable LDS on your device or
        browser; however, doing so may render a portion of the Services to not
        function properly.{" "}
      </p>
      <p>
        <strong>7. Other privacy policies to which you may be subject</strong>
      </p>
      <p>
        The Services incorporate the Application Programming Interfaces (“
        <strong>API’s</strong>”) provided by Google Maps. This Privacy Policy
        incorporates by reference the Google privacy policy, which may be viewed
        at http://www.google.com/intl/en/privacy/privacy-policy.html.
      </p>
      <p>
        The Services incorporate the Application Programming Interfaces (“
        <strong>API’s</strong>”) provided by Facebook. This Privacy Policy
        incorporates by reference the Facebook privacy policy, which may be
        viewed at https://www.facebook.com/note.php?note_id=%20322194465300.
      </p>
      <p>
        The Services provide you with the ability to share content externally on
        Facebook, Twitter and other third-party websites and services. Your use
        of those sites and their use and display of the content that you share
        there is subject to their privacy policies and online terms of use, and
        is not under the control of Company.
      </p>
      <p>
        Occasionally, we may include or offer third-party products or services
        on the Website or App that include links to external websites. For
        example, advertisements, promotional offers, “free gifts,” and other
        links on the Company Website or included in e-mail from Company, may
        bring you to external web pages not controlled by Company. These
        third-party sites have separate and independent privacy policies and
        terms of service, which will govern the use of any Personally
        Identifiable Information that you provide. While Company is not
        responsible for the use of your information by third parties, we
        nevertheless seek to protect our users and the integrity of our Website
        and App, and we therefore invite your feedback about these sites.
      </p>
      <p>
        <strong>
          8. How can you access, update or delete your Personally Identifiable
          Information?
        </strong>
      </p>
      <p>
        All account settings can be changed from [settings page URL]. This
        includes reviewing and changing Personally Identifiable Information,
        setting e-mail preferences for non-account related e-mails, cancelling
        or deactivating your account.{" "}
      </p>
      <p>
        Administrative and account-related communications are necessary to
        ensure we provide our users with the highest level of service, therefore
        registered users are not permitted to opt out from receipt of these
        communications, except in that event that you cancel your registration
        for the Services entirely.
      </p>
      <p>
        New types of marketing communications might be added from time to time.
        Users who visit the [settings page URL] section can opt out of receiving
        future marketing communications or they can unsubscribe by following
        instructions contained in the messages they receive.{" "}
      </p>
      <p>
        Anonymous information relating to your past usage of the Services may
        remain in our archived records after your account has been deleted, and
        any content that you have contributed to the Services will remain
        visible to the same extent as if you remained a registered user of the
        Website. If you wish to have all information that you have posted to be
        deleted, please contact us at Info@subsciety.com and we will make
        reasonable efforts to honor your request. In response to your request,
        we may elect to make your content invisible to other users of the
        Services and the public rather than deleting it entirely.
      </p>
      <p>
        If you are unsure whether this Privacy Policy conflicts with the laws
        applicable to where you reside, you should not submit your Personally
        Identifiable Information through the Website or App. Users located in
        countries outside of the United States who submit PII do hereby consent
        to the transfer of that information to, and storage of the information
        in, the United States.
      </p>
      <p>
        If you are a resident of the European Economic Area (EEA), you have
        certain data protection rights covered by the General Data Protection
        Regulation (GDPR). In certain circumstances, you have the following
        rights:
      </p>
      <ol type="A">
        <li>to access, update or to delete the information we have on you;</li>
        <li>
          to have your information rectified if it is inaccurate or incomplete;
        </li>
        <li>to object to our processing of your PII;</li>
        <li>to request that we restrict the processing of your PII;</li>
        <li>
          to be provided with a copy of your PII in a structured,
          machine-readable and commonly used format; and
        </li>
        <li>
          to withdraw your consent at any time where we rely on your consent to
          process your PII.
        </li>
      </ol>
      <p>
        Please email us at info@subsciety.com if you wish to be informed what
        PII we hold about you and if you want it to be removed from our systems.
        However, we may not able to provide some or all of the Services without
        some necessary data. We may ask you to verify your identity before
        responding to such requests.{" "}
      </p>
      <p>
        You have the right to complain to a Data Protection Authority about our
        collection and use of your PII. For more information, please contact
        your local data protection authority in the EEA.
      </p>
      <p>
        <strong>9. What if we make changes to our Privacy Policy?</strong>
      </p>
      <p>
        Because enhancements to the Services we provide may affect the
        information that we collect and the manner in which we use it, this
        Privacy Policy may change from time to time. All changes will be
        incorporated into the written policy and posted here. The date of last
        revision to the Privacy Policy is indicated at the bottom of this page.
      </p>
      <p>
        The Company will provide you with the opportunity to accept or reject
        significant changes to the Privacy Policy during your next sign-on to
        the Services. If you do not agree to the modifications, your access to
        the Services may be discontinued.{" "}
      </p>
      <p>
        <strong>
          10. What if you have questions that are not answered here?
        </strong>
      </p>
      <p>
        If there are any questions regarding this Privacy Policy, you may
        contact us using the information below:
      </p>

      <div className="address-privacy">
        <p>Subsciety LLC</p>
        <p>Attention: Privacy</p>
        <p>211 W Wacker Drive, Ste 300</p>
        <p>Chicago, Illinois 60606</p>
      </div>
      <div className="address-privacy">
        <p>Last updated: June 25, 2021</p>
      </div>*/}
    </div>
  );
};

export default Page;
