import axios from 'axios';
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const addAttribute = (attributeInfo, userId) => async (dispatch) => {
    try {

        const res = await axios({
            method: 'post',
            url: `${URL}/api/v1/seller/attribute/add/${userId}`,
            data: attributeInfo,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        console.log('error at addAttribute action', error);
        dispatch(handleError(error));
    }
}

export const updateAttributeDetail = (attributeInfo, userId) => async (dispatch) => {
    try {

        const res = await axios({
            method: 'post',
            url: `${URL}/api/v1/seller/attribute/update/${userId}`,
            data: attributeInfo,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("updateAttributeDetail response is", res);
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}

export const getAttributeList = (userId, start) => async (dispatch) => {
    // console.log("getAttributeList parameters", categoryName);
    try {

        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/attribute/list/${userId}?offset=${start}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        console.log("getAttributeList data from backend", res);

        return res;

    } catch (error) {
        console.log("catch");
        dispatch(handleError(error));
    }
}

export const getAttributeDetail = (userId, attributeId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/attribute/detail/${userId}/${attributeId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;

    } catch (error) {
        console.log("catch");
        dispatch(handleError(error));
    }
}

export const searchAttribute = (offset, userId, searchKeyword) => async (dispatch) => {
    // console.log("getOrdersList offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/attribute/by/search/${userId}?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}

export const deleteAttribute = (userId, attributeId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "delete",
            url: `${URL}/api/v1/seller/attribute/delete/${userId}/${attributeId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        console.log("data from backend", res);

        return res;

    } catch (error) {
        console.log("catch");
        dispatch(handleError(error));
    }
}

