import React, { useState } from "react";
function StripeConnectAccount() {
  const [state, setstate] = useState({
    stripeEmail: "",
    bussinessType: "",
  });

  const handleChange = (e, key) => {
    const { value } = e.target;
    setstate((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="email"
          value={state.stripeEmail}
          onChange={(e) => handleChange(e, "stripeEmail")}
        />

        <select
          value={state.bussinessType}
          onChange={(e) => handleChange(e, "bussinessType")}
        >
          <option disabled={true} value={""}>
            select bussinessType type
          </option>
          <option value={"individual"}>individual</option>
          <option value={"company"}>company</option>
        </select>
        <input type="submit" value="submit" />
      </form>
    </div>
  );
}

export default StripeConnectAccount;
