// check users and replace with items
// confirm status column value in the list to display

import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from 'lodash';

import { getAttributeList, searchAttribute, deleteAttribute } from '../../Redux/Actions/attributeAction';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import AlertModal from "../../Components/AlertModal/AlertModal"; // Import css

class Attribute_List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            maxLimit: 10,
            offset: 0,
            prevOffset: 0,
            items: [],
            searchKeyword: "",
            hasMore: true,
            showLoader: false,
            alertModalData : {
                open : false,
                message : '',
                singleButton : true
            }
        };
    }

    componentDidMount() {
        this.fetchMoreData(true);
    }

    fetchMoreData = async (firstLoad) => {
        this.setState({
            showLoader: true
        })
        if (this.state.offset !== this.state.prevOffset || firstLoad) {
            if (this.state.hasMore && this.state.maxLimit && (this.state.maxLimit === "all" || this.state.maxLimit > this.state.offset)) {
                let result;
                if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
                    result = await this.props.searchAttribute(this.state.offset, localStorage.getItem("sellerId"), this.state.searchKeyword.trim());
                }
                else {
                    result = await this.props.getAttributeList(localStorage.getItem("sellerId"), this.state.offset);
                }

                // let result = await this.props.getUsersList(this.state.offset);
                if (result ?.data ?.data ?.length > 0) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            prevOffset: prevState.offset,
                            offset: prevState.offset + 10,
                            items: prevState.items.concat(result.data.data),
                            showLoader: false
                        }
                    })
                }
                else {
                    this.setState({ hasMore: false, showLoader: false });
                }
            } else {
                this.setState({
                    showLoader: false
                })
            }
        } else {
            this.setState({
                showLoader: false
            })
        }
    };

    handleChange = async (event, field) => {
        event.preventDefault();
        // console.log("event value is", +event.target.value);

        if (field === "maxLimit") {
            await this.setState({
                [field]: event.target.value === "all" ? event.target.value : +event.target.value
            })
            this.fetchMoreData(false);
        }
        else {
            await this.setState({
                [field]: event.target.value,
                offset: 0,
                prevOffset: 0,
                hasMore: true,
                maxLimit:10
            })

            this.debounceSearchCall();
        }
    };

    debounceSearchCall = debounce(() => {
        this.setState({
            items: []
        })
        this.fetchMoreData(true);
    }, 1000);


    showDeleteAttributePopup(attribute_id) {
        this.alertModal({
            message: 'Are you sure to delete the attribute?',
            positiveButton: 'Yes',
            data : attribute_id,
            negativeButton: 'No',
            singleButton : false,
            id : 2
        })
    }


    async ondeleteAttribute(attribute_id) {
        const deleteAttributeRes = await this.props.deleteAttribute(localStorage.getItem("sellerId"), attribute_id);
        if (deleteAttributeRes && deleteAttributeRes.data) {
            this.showResponseAlert(deleteAttributeRes.data.message)
        }
        else {
            // error to get respone
            this.showResponseAlert("Something went wrong. Please try again later.")
        }
    }

   async showResponseAlert(message) {
        confirmAlert({
            title: "",
            message: message,
            buttons: [
              {
                label: "Okay",
              },
            ],
          });
         const result = await this.props.getAttributeList(localStorage.getItem("sellerId"), 0)
          if(result?.data?.data?.length > 0){
          this.setState({
            ...this.state,
            items: result?.data?.data
          })
          } else {
            this.setState({ 
                ...this.state,
                items: result?.data?.data,
                hasMore: false, showLoader: false 
            });
          }
        // this.alertModal({
        //     message: message,
        //     postiveButton : 'Ok',
        //     data : [],
        //     singleButton : false,
        //     // id : 1,
        // })
    }

    async onClearState() {
        await this.setState({
            maxLimit: 10,
            offset: 0,
            prevOffset: 0,
            items: [],
            searchKeyword: "",
            hasMore: true,
            showLoader: false
        });
    }

    closeAlertModal = () => {
        this.setState({
            alertModalData : {
                open : false,
                message: '',
                singleButton : true
            }
        })
    }

    alertModal = (data) => {
        this.setState({
            alertModalData : {
                open : true,
                message: data.message,
                singleButton : data.singleButton,
                positiveButton : data.positiveButton,
                negativeButton : data.negativeButton,
                id : data.id,
                data : data.data,
            }
        })
    }

    handleAlertResponse = async (type, id, data) => {
        if(id === 1){
            if(type === "positive"){
                await this.onClearState();
                this.fetchMoreData(true);
            }
        } else if(id === 2){
            if(type === "positive"){
                await this.ondeleteAttribute(data)
            }
        }

        this.closeAlertModal()
    }


    render() {

        const attributesList = this.state.items.map(i => {
            return (
                <tr key={i.id}>
                    <td align="center">{i.id ? i.id : " "}</td>
                    <td style={{maxWidth:"250px" , wordBreak:"break-word", whiteSpace: 'normal'}}>{i.name ? i.name.replace(/_/g, ' ') : ""}</td>
                    <td style={{maxWidth:"200px" , wordBreak:"break-word", whiteSpace: 'normal'}}>{i.variation_values ? i.variation_values : ""}</td>

                    <td align="center">
                        <div className="vendor-table-btn">

                            <button type="button" className="orange-outline-btn" onClick={() => this.showDeleteAttributePopup(i.id)}>Delete</button>
                            <Link to={`edit-attribute/${i.id}`}>
                                <button type="button" className="orange-outline-btn">Edit</button>
                            </Link>


                            {/* <button type="button" className="orange-outline-btn">
                                <Link to={{ pathname: `https://subsciety.azurewebsites.net/product/${i.slug}` }} target="_blank">View</Link>
                            </button> */}
                            {/* <button type="button" className="orange-outline-btn">
                                <Link to={`edit-product/${i.slug}`}>Edit</Link>
                            </button> */}
                        </div>
                    </td>
                </tr>
            )
        })

        return (
            <div className="admin-card">
                <div className="admin-card-head">
                    <h2>Attribute list</h2>
                    <Link to={'/add-attribute'}>
                        <button type="text" className="orange-outline-btn">
                            <i className="fas fa-plus"></i> Add Attribute
                        </button>
                    </Link>
                </div>
                <div className="admin-card-box">
                    <div className="vendor-table">

                        <div className="vendor-table-head">
                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <div className="entries-drop">
                                        <label>show</label>
                                        <select className="entries-controls" onChange={(e) => this.handleChange(e, "maxLimit")} value={this.state.maxLimit}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="all">All</option>
                                        </select>
                                        <p>Entries</p>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <div className="vendor-table-head-right">
                                        <div className="vendor-table-search">
                                            <input className="form-control" onChange={(e) => this.handleChange(e, "searchKeyword")} value={this.state.searchKeyword} type="text" placeholder="search" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="vendor-table-body">
                            <div className="table-responsive">
                                <InfiniteScroll
                                    dataLength={this.state.items.length}
                                    next={() => this.fetchMoreData(false)}
                                    hasMore={this.state.hasMore}
                                >
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <td align="center">Id</td>
                                                <td>Name</td>
                                                <td>Variants</td>
                                                {/* <td>price</td> */}
                                                {/* <td>sales</td> */}
                                                {/* <td>active</td>
                                                <td>status</td> */}
                                                <td align="center">action</td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {attributesList}

                                            {/* <tr>
                                            <td align="center">5420</td>
                                            <td>Loren ipsum dolor</td>
                                            <td>$499.00</td>
                                            <td>1</td>
                                            <td>
                                                <p className="orange">pending</p>
                                            </td>
                                            <td align="center">
                                                <div className="vendor-table-btn">
                                                    <button type="button" className="orange-outline-btn">View</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center">5420</td>
                                            <td>Loren ipsum dolor</td>
                                            <td>$499.00</td>
                                            <td>1</td>
                                            <td>
                                                <p className="black">published</p>
                                            </td>
                                            <td align="center">
                                                <div className="vendor-table-btn">
                                                    <button type="button" className="orange-outline-btn">View</button>
                                                </div>
                                            </td>
                                        </tr> */}

                                            {this.state.showLoader ? (
                                                <tr>
                                                    <td colSpan={4} className="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (null)}
                                        </tbody>

                                    </table>
                                </InfiniteScroll>
                            </div>
                        </div>
                        <AlertModal
                            data={this.state.alertModalData}
                            closeAlertModal={this.closeAlertModal}
                            handleAlertResponse={(type, id, data) => this.handleAlertResponse(type, id, data)}
                        />

                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAttributeList, searchAttribute, deleteAttribute }, dispatch);
}

export default connect(null, mapDispatchToProps)(Attribute_List)
