import React, { Component } from "react";
import "./App.css";
import "./Styles/all.scss";
import "./Styles/bootstrap.min.css";
import "./Styles/media.scss";
import "./Styles/style.scss";
import PrivateRoutes from "./Routing/privateRoutes";
import PermissionError from "./Components/PermissionError/permissionError";
import SignUp from "./Containers/SignUp/signup";
import { Switch, Link } from "react-router-dom";
import { Route } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserDetail, setUserInfo } from "./Redux/Actions/userAction";

class App extends Component {
  userData = localStorage.getItem("sellerData");

  constructor(props) {
    super(props);
    if (this.userData) {
      this.fetchUserDetail();
    }
    this.childDiv = React.createRef();
  }

  componentDidMount() {
    this.handleScroll();
  }

  handleScroll = () => {
    const { index, selected } = this.props;
    if (index === selected) {
      setTimeout(() => {
        this.childDiv.current.scrollIntoView(true);
      }, 100);
    }
  };

  async fetchUserDetail() {
    const userDetail = await this.props.getUserDetail(
      JSON.parse(this.userData)
    );
    if (userDetail?.data?.data) {
      this.props.setUserInfo(userDetail.data.data);
    }
  }

  render() {
    return (
      <div ref={this.childDiv}>
        {this.props.storeSetupMessage && (
          <p className="notify-alert-head">
            Please complete your store and <Link to="/profile">profile</Link>{" "}
            information to send products for approval
          </p>
        )}
        {/* <Spinner /> */}
        <Switch>
          <Route exact path="/error" component={PermissionError} />
          {/* <Route exact path="/signup" component={SignUp} /> */}
          <Route path="/" component={PrivateRoutes} />
        </Switch>
      </div>
    );
  }
}

// function App() {

//   return (
//     <>
//       {/* <Spinner /> */}
//       <Switch>
//         <Route exact path="/error" component={PermissionError} />
//         {/* <Route exact path="/signup" component={SignUp} /> */}
//         <Route path="/" component={PrivateRoutes} />
//       </Switch>
//     </>
//   );
// }

const mapStateToProps = (state) => {
  return {
    ...state,
    storeSetupMessage: state.userReducer.storeSetupMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUserDetail, setUserInfo }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
