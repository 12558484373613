export const LOADING_STARTED = 'LOADING_STARTED'
export const LOADING_COMPLETED = 'LOADING_COMPLETED'

export const CREATE_MASTER_MEETING = 'CREATE_MASTER_MEETING'
export const GET_SELLER_MEETING_LIST = 'GET_SELLER_MEETING_LIST'

export const MEETING_ALREADY_EXISTS = 'MEETING_ALREADY_EXISTS'
export const CLOSE_MEETING_MODAL = 'CLOSE_MEETING_MODAL'

export const CREATE_RESCHDULE_MEETINGS = 'CREATE_RESCHDULE_MEETINGS'
export const DELETE_SELECTED_SLOT = 'DELETE_SELECTED_SLOT'

export const GET_MEETING_REQUEST_LIST = 'GET_MEETING_REQUEST_LIST'
export const UPDATE_MEETING_STATUS = 'UPDATE_MEETING_STATUS'

export const DELETE_MEETING = 'DELETE_MEETING'