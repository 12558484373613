

export const startLoadig = () => async (dispatch) => {
  dispatch({ type: "LOADING_STARTED" });
};

export const stopLoadig = () => async (dispatch) => {
  dispatch({ type: "LOADING_COMPLETED" });
};

export const handleError = (error) => async (dispatch) => {
  dispatch({ type: "LOADING_FAILURE" });
  if (error.response) {
    if (error.response.status === 401) {
      localStorage.removeItem('sellerId');
      localStorage.removeItem('sellerData');
      localStorage.removeItem('token');
      dispatch({ type: "LOGOUT" })
    } else if (error.response.data?.errorData) {
      dispatch({
        type: "ERROR",
        payload: { error: error.response.data, errorMessage: '' },
      });
    } else if (error.response.data?.error) {

      dispatch({
        type: "ERROR",
        payload: { errorMessage: error.response.data.error },
      });
    } else { }
  } else {
    dispatch({
      type: "ERROR",
      payload: { errorMessage: "Something went wrong. Please try again later." },
    });
  }
};

export const toggleSidebar = () => async (dispatch) => {
  dispatch({ type: "TOGGLE_SIDEBAR" });
};
