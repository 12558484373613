import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import CryptoJS from "react-native-crypto-js";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import {
  installShopify,
  getShopifyDetails,
  getCustomerFromShopify,
  getProductsFromShopify,
  getCategoriesFromShopify,
  addProductsToShopify,
  addCategoriesToShopify,
  addCustomersToShopify,
} from "../../Redux/Actions/userAction";
import { decryptAccessToken } from "../../Utils/helper";

const Shopify = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [state, setState] = useState({
    client_key: "",
    client_secret: "",
    shop: "",
    vendor_id: localStorage.getItem("sellerData")
      ? JSON.parse(localStorage.getItem("sellerData")).id
      : null,
  });
  const [savedStore, setSavedStore] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    (async () => {
      fetchShopifyDetails();
    })();
    if (window?.location?.href.includes("apiKey")) {
    fillFormDetailsAutomaticallyWhenConnectFromShopify()
    }
  }, []);

  const fillFormDetailsAutomaticallyWhenConnectFromShopify = async () => {
      const paramsObject = window?.location?.href?.split("?")[1]?.split("&")
      const clientKey = paramsObject[0]?.split("=")[1]
      const clientSecret = paramsObject[1]?.split("=")[1]
      const shop = paramsObject[2]?.split("=")[1]
      if (clientKey && clientSecret && shop) {
        setState({
          client_key: clientKey,
          client_secret: clientSecret,
          shop: shop,
          vendor_id: localStorage.getItem("sellerData")
            ? JSON.parse(localStorage.getItem("sellerData")).id
            : null,
        });
        await submitFormWhenConnectFromShopify(clientKey, clientSecret, shop)
      }
  }

  const submitFormWhenConnectFromShopify = async (clientKey, clientSecret, shop) => {
      const res = await dispatch(installShopify({
        client_key: clientKey,
        client_secret: clientSecret,
        shop: shop,
        vendor_id: localStorage.getItem("sellerData")? JSON.parse(localStorage.getItem("sellerData")).id: null
      }));
      if (res) {
        setCallbackUrl(res.data);
        fetchShopifyDetails();
      }
  }

  const postData = async (res) => {
    try {
      const response = await axios.post(
        // "https://store-sy.subsciety.com/subscietyapp/shopdata.php",  -------- previous URL
        "https://store-sy.subsciety.com/api/shopdata",
        res
      );
    } catch (error) {
      console.error(error);
    }
  };

  const disconnectShopifyAccountConfimationPopup = async (shop) => {
    confirmAlert({
      title: "",
      message: "Are you sure to disconnect the account?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => shopifyAccountDisconnected(shop),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const shopifyAccountDisconnected = async (shop) => {
    const res = await dispatch(
      installShopify({ shop: shop, vendor_id: state.vendor_id })
    );
    setAccessToken(null);
    if (res.data === false) {
      confirmAlert({
        title: "",
        message: "Account Disconnected!",
        buttons: [
          {
            label: "Okay",
          },
        ],
      });
    }
    const response = await dispatch(getShopifyDetails(state.vendor_id));

    // redirect to shopify app UI when user disconnects.
    // let shopName = shop.split(".")[0];
    // window.location = `https://admin.shopify.com/store/${shopName}/apps/subsciety-marketplace-1/app`;
    postData(response);
  };

  const fetchShopifyDetails = async () => {
    const res = await dispatch(getShopifyDetails(state.vendor_id));

    // split url data
    const localvalue = location.search.split("?");

    if (res?.data.isFound && res.data.data.access_token) {
      let shopName = res.data?.data?.shop?.split(".")[0];
      // add autoredirect to shopify app UI when shop name match.
      if (localvalue.includes(`shop=${res.data?.data?.shop}`)) {
        window.location = `https://admin.shopify.com/store/${shopName}/apps/subsciety-marketplace-1/app`;
      }

      const access_Token = decryptAccessToken(res.data.data.access_token);
      setSavedStore(res.data?.data.shop);
      setAccessToken(access_Token);
      setState((prevState) => ({
        ...prevState,
        client_key: res.data?.data.client_key,
        client_secret: res.data?.data.client_secret,
        shop: res.data?.data.shop,
      }));
    }
    postData(res);
    if (location.search.split("?")[1] === "disconnect") {
      await disconnectShopifyAccountConfimationPopup(res.data?.data.shop);
    }
  };

  const [callbackUrl, setCallbackUrl] = useState(null);
  const handleChange = (e, key) => {
    let newVal = e.target.value;
    setState((prev) => ({
      ...prev,
      [key]: newVal,
    }));
  };
  function isValidURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }

  const [clientKeyError, setClientKeyError] = useState("");
  const [clientSecretError, setClientSecretError] = useState("");
  const [shopError, setShopError] = useState("");
  const [reqMessage, setreqMessage] = useState("");
  const validateForm = async () => {
    let isValid = true;
    setClientKeyError("");
    setClientSecretError("");
    setShopError("");
    if (state?.client_key?.trim() === "") {
      isValid = false;
      setClientKeyError("please enter details in required field");
    }

    if (state?.client_secret?.trim() === "") {
      isValid = false;
      setClientSecretError("please enter details in required field");
    }
    if (state.shop === "" || !isValidURL(state.shop)) {
      isValid = false;
      setShopError(
        !isValidURL(state.shop)
          ? "please enter correct url"
          : "please enter details in required field"
      );
    }
    return isValid;
  };

  const disconnectShopifyStore = async () => {
    await disconnectShopifyAccountConfimationPopup(savedStore);
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   let isValidForm = await validateForm();
  //   if (isValidForm) {
  //     const res = await dispatch(installShopify(state));
  //     if (res) {
  //       setCallbackUrl(res.data);
  //       fetchShopifyDetails();
  //     }
  //   }
  // };

  const handleGetCustomers = async (e) => {
    e.preventDefault();
    if (accessToken && savedStore) {
      const res = await dispatch(
        getCustomerFromShopify({
          accessToken,
          shop: savedStore,
          vendor_id: state.vendor_id,
        })
      );
      if (res.data && !res.data.isError) {
        setreqMessage("request submitted successfully");
        setTimeout(() => {
          setreqMessage("");
        }, 5 * 1000);
      }
    }
  };

  const handleGetCategories = async (e) => {
    e.preventDefault();
    if (accessToken && savedStore) {
      const res = await dispatch(
        getCategoriesFromShopify({
          accessToken,
          shop: savedStore,
          vendor_id: state.vendor_id,
        })
      );
      if (res.data && !res.data.isError) {
        setreqMessage("request submitted successfully");
        setTimeout(() => {
          setreqMessage("");
        }, 5 * 1000);
      }
    }
  };

  const handleAddCustomers = async (e) => {
    e.preventDefault();
    if (accessToken && savedStore) {
      const res = await dispatch(
        addCustomersToShopify({
          accessToken,
          shop: savedStore,
          vendor_id: state.vendor_id,
        })
      );
      if (res.data && !res.data.isError) {
        setreqMessage("request submitted successfully");
        setTimeout(() => {
          setreqMessage("");
        }, 5 * 1000);
      }
    }
  };

  const handleAddCategories = async (e) => {
    e.preventDefault();
    if (accessToken && savedStore) {
      const res = await dispatch(
        addCategoriesToShopify({
          accessToken,
          shop: savedStore,
          vendor_id: state.vendor_id,
        })
      );
      if (res.data && !res.data.isError) {
        setreqMessage("request submitted successfully");
        setTimeout(() => {
          setreqMessage("");
        }, 5 * 1000);
      }
    }
  };

  const handleAddProducts = async (e) => {
    e.preventDefault();
    if (accessToken && savedStore) {
      const res = await dispatch(
        addProductsToShopify({
          accessToken,
          shop: savedStore,
          vendor_id: state.vendor_id,
        })
      );
      if (res.data && !res.data.isError) {
        setreqMessage("request submitted successfully");
        setTimeout(() => {
          setreqMessage("");
        }, 5 * 1000);
      }
    }
  };

  const connectShopifyAccount = async (url) => {
    window.open(url, "_blank");
    setCallbackUrl(null);
    fetchShopifyDetails();
  };
  return (
    <div>
      <div className="admin-card">
        <div className="admin-card-head">
          <h2>Shopify Account Connect</h2>

          {/* {accessToken && savedStore && (
            <button onClick={disconnectShopifyStore}>Disconnect</button>
          )} */}
        </div>
        <div className="admin-card-box">

        {/* <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="capital-case">shopify client key</label>
                  <input
                    type="text"
                    className="form-control"
                    value={state.client_key}
                    onChange={(e) => handleChange(e, "client_key")}
                  />
                </div>
                <span className="form-field-error custom-error">
                  {clientKeyError}
                </span>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="capital-case">shopify client secret</label>
                  <input
                    type="text"
                    className="form-control"
                    value={state.client_secret}
                    onChange={(e) => handleChange(e, "client_secret")}
                  />
                </div>
                <span className="form-field-error custom-error">
                  {clientSecretError}
                </span>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="capital-case">shopify shop url</label>
                  <input
                    type="text"
                    className="form-control"
                    value={state.shop}
                    onChange={(e) => handleChange(e, "shop")}
                  />
                </div>
                <span className="form-field-error custom-error">
                  {shopError}
                </span>
              </div>
            </div>
 
            <div className="form-btn mb-2">
              <button
                type="submit"
                value={"submit"}
                className="orange-outline-btn"
              >
                save
              </button>
            </div>
            <a
              href={process.env.REACT_APP_SHOPIFY_DOCUMENT_URL}
              download={process.env.REACT_APP_SHOPIFY_DOCUMENT_URL}
              target="_blank"
            >
              <span className="pdf-icon-tag">
                <i className="fas fa-file"></i>
              </span>{" "}
              Step by step Shopify connect document
            </a>
          </form> */}

          {callbackUrl && connectShopifyAccount(callbackUrl)}
          <div className="form-btn mb-2 text-center">
            {accessToken && savedStore ? (
              <p style={{ fontSize: "14px", color: "#0000FF" }}>
                {" "}
                Your Shopify account connected successfully
              </p>
            ) : 
           
           <h6>Your shopify account is not connected</h6>  
         }

            {reqMessage && (
              <div className="success-msg mb-3">
                <p>{reqMessage}</p>
              </div>
            )}
            {accessToken && savedStore && (
              <div className="group-btn-main-list">
                <div className="row">
                  {/* <div className="col-md-3 p-0">
                    <div className="border-list-button">
                      <button
                        className="orange-btn"
                        onClick={handleGetCustomers}
                      >
                        {" "}
                        Sync Customer from <br></br> shopify
                      </button>
                    </div>
                  </div> */}

                  {/* <div className="col-md-3 p-0">
                    <div className="border-list-button">
                      <button
                        className="orange-btn"
                        onClick={handleAddCustomers}
                      >
                        {" "}
                        Add Customer to <br></br> Shopify
                      </button>
                    </div>
                  </div> */}

                  <div className="col-md-3 p-0">
                    <div className="border-list-button">
                      <button
                        className="orange-btn capital-case"
                        onClick={handleGetCategories}
                      >
                        {" "}
                        Sync Collections and <br></br> Products from shopify
                      </button>
                    </div>
                  </div>

                  <div className="col-md-3 p-0">
                    <div className="border-list-button">
                      <button
                        className="orange-btn capital-case"
                        onClick={handleAddProducts}
                      >
                        {" "}
                        Add Products to <br></br> Shopify
                      </button>
                    </div>
                  </div>

                  <div className="col-md-3 p-0">
                    <div className="border-list-button">
                      <button
                        className="orange-btn capital-case"
                        onClick={handleAddCategories}
                      >
                        {" "}
                        Add categories to <br></br> Shopify
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shopify;
