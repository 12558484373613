import axios from "axios";
import moment from 'moment'
import { CREATE_MASTER_MEETING, LOADING_COMPLETED, DELETE_MEETING, UPDATE_MEETING_STATUS, GET_MEETING_REQUEST_LIST, LOADING_STARTED, GET_SELLER_MEETING_LIST, MEETING_ALREADY_EXISTS, DELETE_SELECTED_SLOT } from '../ActionType'
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

function convertIntoActualTime(day, slot, meetingType, meetingDurationType) {
    let { end, start } = slot[0];
    var timeByDay = moment().isoWeekday(day).format();
    const startDateStr = new Date(timeByDay).getFullYear() + "-" + (parseInt(new Date(timeByDay).getMonth()) + 1) + "-" + new Date(timeByDay).getDate() + " " + start;
    slot['start'] = startDateStr;
    const endDateStr = new Date(timeByDay).getFullYear() + "-" + (parseInt(new Date(timeByDay).getMonth() + 1)) + "-" + new Date(timeByDay).getDate() + " " + end;
    slot['end'] = endDateStr;
    return { end: new Date(endDateStr), start: new Date(startDateStr), meetingType, meetingDurationType }
}


export const createMeeting = (data) => async (dispatch) => {
    try {
        if(data.validate === false){
            dispatch({
                type: MEETING_ALREADY_EXISTS,
                payload: false
            })
        }
        dispatch({ type: LOADING_STARTED });
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/seller/schedule/createMasterMeeting`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data
        });
        dispatch({ type: LOADING_COMPLETED });
        if (res.data && !res.data.isError) {
            dispatch({
                type: CREATE_MASTER_MEETING,
                payload: convertIntoActualTime(data.day, data.slot, data.meetingType, data.meetingDurationType),
            });
        } else {
            dispatch({
                type: MEETING_ALREADY_EXISTS,
                payload: true
            })
        }
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const createRescheduleMeetting = (seller_id, product_id, formData, originalSlot, date, type) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/seller/schedule/createReschduleMeetings`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: {
                formData,
                originalSlot,
                type,
                date,
                seller_id,
                product_id
            }
        });
        dispatch({ type: LOADING_COMPLETED });
        if (res.data && !res.data.isError) {
            dispatch({
                type: CREATE_MASTER_MEETING,
                // payload: slot,
            });
        } else {
            dispatch({
                type: MEETING_ALREADY_EXISTS,
                payload: true
            })
        }
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const getMeetingsByProductIdAndSellerId = (seller_id, product_id, startOfWeekTime, endOfWeekTime, timezone, view, cancelToken) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/schedule/meetingList/${seller_id}/${product_id}?startOfWeekTime=${startOfWeekTime}&endOfWeekTime=${endOfWeekTime}&timezone=${timezone}&view=${view}`,
            cancelToken: cancelToken,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        dispatch({ type: LOADING_COMPLETED });
        dispatch({
            type: GET_SELLER_MEETING_LIST,
            payload: { data: res.data },
        });
    } catch (error) {
        dispatch(handleError(error));
    }
}


export const updateMeetingsDetails = (schedule_id, data) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios({
            method: "patch",
            url: `${URL}/api/v1/seller/schedule/meetingList/${schedule_id}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data
        });
        dispatch({ type: LOADING_COMPLETED });
        dispatch({
            type: GET_SELLER_MEETING_LIST,
            payload: { data: res.data },
        });
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const updateSelectedEvent = (data) => async (dispatch) => {
    try {
        // dispatch({ type: LOADING_STARTED });
        const res = await axios({
            method: "patch",
            url: `${URL}/api/v1/seller/schedule/updateSlotForRecurring`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data
        });
        // dispatch({ type: LOADING_COMPLETED });
        if (res.data && res.data.isError) {
            dispatch({
                type: MEETING_ALREADY_EXISTS,
                payload: true
            })
        }
        return res.data
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const deleteSelectedEvent = (data) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios({
            method: "patch",
            url: `${URL}/api/v1/seller/schedule/deleteSlotForRecurring`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data
        });
        dispatch({ type: LOADING_COMPLETED });
        return res.data
    } catch (error) {
        dispatch(handleError(error));
    }
}
export const validateCancelSlot = (data) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios({
            method: "patch",
            url: `${URL}/api/v1/seller/schedule/validateCancelSlot`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data
        });
        dispatch({ type: LOADING_COMPLETED });
        return res.data
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const deleteOnetimeMeeting = (meeting_id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios({
            method: "delete",
            url: `${URL}/api/v1/seller/schedule/deleteOneTimeMeeting/${meeting_id}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },

        });
        dispatch({ type: LOADING_COMPLETED });
        return res.data
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const updateOneTimeMeeting = async () => {

}


export const getMeetingRequestList = (seller_id, pageLimit, pageNumber, timezone) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/schedule/meetingRequestList/${seller_id}?limit=${pageLimit}&pageNumber=${pageNumber}&timezone=${timezone}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        if (pageNumber === 1) {
            dispatch({
                type: GET_MEETING_REQUEST_LIST,
                payload: {
                    data: res.data.data,
                    hasMore: (res.data.data.length >= pageLimit)
                },
            });
        } else {
            dispatch({
                type: 'ADD_MEETING_REQUEST_LIST',
                payload: {
                    data: res.data.data,
                    hasMore: (res.data.data.length >= pageLimit)
                },
            });
        }

    } catch (error) {
        dispatch(handleError(error));
    }
}

export const setSuccessMessage = (message) => async (dispatch) => {
    try {
        await dispatch({
            type: 'SET_SUCCESS',
            payload: {
                message
            }
        });

        setTimeout(() => {
            dispatch({
                type: 'SET_SUCCESS',
                payload: {
                    message: ""
                }
            });
        }, 5000)
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const setErrorMessage = (message) => async (dispatch) => {
    try {
        await dispatch({
            type: 'SET_ERROR',
            payload: {
                message
            }
        });

        setTimeout(() => {
            dispatch({
                type: 'SET_ERROR',
                payload: {
                    message: ""
                }
            });
        }, 5000)
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const updateMeetingStatus = (data) => async (dispatch) => {
    try {
        const res = await axios({
            method: "patch",
            url: `${URL}/api/v1/seller/schedule/update-meeting-status/${data.userId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: data
        });
        console.log(`resres`, res, data.meeting_id, data.status)
        if (res.data && !res.data.isError && data.status === 'accepted') {
            dispatch({
                type: UPDATE_MEETING_STATUS,
                payload: {
                    meeting_id: data.meeting_id,
                    status: data.status
                }
            });

            dispatch(setSuccessMessage(res.data.message));
        }
        if (res.data && !res.data.isError && data.status === 'rejected') {
            dispatch({
                type: DELETE_MEETING,
                payload: {
                    meeting_id: data.meeting_id,
                    status: data.status,
                    declineNotes: data.declineNotes
                }
            });
            dispatch(setSuccessMessage(res.data.message));
        }
        if (res.data && res.data.isError) {
            dispatch(setErrorMessage(res.data.message));
            dispatch({type: MEETING_ALREADY_EXISTS, payload: true})
        }
        return res.data
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const getMeetingsBySellerId = (seller_id, startOfWeekTime, endOfWeekTime, timezone, cancelToken) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/schedule/meetingList/${seller_id}?startOfWeekTime=${startOfWeekTime}&endOfWeekTime=${endOfWeekTime}&timezone=${timezone}`,
            cancelToken: cancelToken,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        dispatch({ type: LOADING_COMPLETED });
        dispatch({
            type: GET_SELLER_MEETING_LIST,
            payload: { data: res.data },
        });
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const getGlobalMeetingList = (seller_id, type, startOfWeekTime, endOfWeekTime, timezone,view, cancelToken) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/schedule/global-meeting-list/${seller_id}?timezone=${timezone}&view=${view}&type=${type}&startOfWeekTime=${startOfWeekTime}&endOfWeekTime=${endOfWeekTime}`,
            cancelToken: cancelToken,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        return res.data

    } catch (error) {
        dispatch(handleError(error));
    }
}


export const sendResheduleMailFromForm = (body) => async (dispatch) => {
    try {
        const res = await axios({
            method: "patch",
            url: `${URL}/api/v1/seller/schedule/send_mail_using_form`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: body
        });
        return res.data

    } catch (error) {
        dispatch(handleError(error));
    }
}