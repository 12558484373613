import React, { Component } from "react";
import "../../Styles/track-orders.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrderTracking } from "../../Redux/Actions/orderAction";

class Order_Track extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetail: {},
      orderTrackRecord: [],
      orderTrackStatusRecord: [],
      orderTrackingTemplate: [],
      orderTrackType: [],
      showLoader: false,
      shippingStatus: "",
      service: "",
    };
  }

  componentDidMount() {
    this.fetchOrdertrackInfo(true);
  }

  fetchOrdertrackInfo = async (firstFetch) => {
    this.setState({
      orderTrackRecord: [],
      orderTrackStatusRecord: [],
      orderTrackingTemplate: [],
      showLoader: firstFetch ? false : true,
    });
    const result = await this.props.getOrderTracking(
      this.props.match.params.trackId
    );

    if (result?.data && result?.data?.data) {
      if (result.data.data?.trackRes?.CompletedTrackDetails?.length > 0) {
        this.createFedexTrackingDetail(result);
      } else if (result.data.data.trackRes?.elements?.length > 0) {
        this.createUspsTrackingDetail(result.data.data);
      } else if (result.data.data && (result.data.data?.warnings || result.data.data?.package)) {
        this.createUpsTrackingDetail(result.data);
      } else {
        console.log("order detail found but track response was not found");
        this.setState({
          orderDetail: result.data.data,
          showLoader: false,
        });
      }
    } else {
      console.log("tracking info not found");
      this.setState({
        showLoader: false,
      });
    }
  };

  createFedexTrackingDetail = (trackdetail) => {
    let shippingStatus = trackdetail.data.data.Status;
    let trackDetail =
    trackdetail.data.data.trackRes.CompletedTrackDetails[0].TrackDetails;
    let trackStatus = [];
    trackStatus.push(trackDetail.status);
    let trackingDetails = trackDetail[0]?.Events?.map((trackItem, index) => {
      trackStatus.push(trackItem.EventDescription);
      if(trackStatus.includes("Delivered")){
        shippingStatus = "Delivery"
      }else if(trackStatus.includes("Picked up")){
        shippingStatus = "pickedUp"
      }else if(trackStatus.includes("In transit")){
        shippingStatus = "shipped"
      }
      const timestamp = trackItem.Timestamp
        ? new Date(trackItem.Timestamp).toLocaleString()
        : `-`;
      const address = trackItem.Address;
      return (
        <tr key={index}>
          <td>
            <div className="track-time"><p>{timestamp}</p></div>
          </td>
          <td>
            <div className="track-status">
              <p>{trackItem.EventDescription ? trackItem.EventDescription : "-"}</p>
            </div>
          </td>
        </tr>
      );
    });

    this.setState({
      shippingStatus: shippingStatus,
      trackDetail,
      orderTrackRecord: trackDetail,
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      showLoader: false,
      service: "Fedex",
    });

    return;
  };

  createUspsTrackingDetail = (trackDetail) => {
    let Shippingstatus = trackDetail.Status;
    let trackDetails = trackDetail.trackRes;
    let trackStatus = [];
    let trackingDetails;
     trackingDetails = trackDetails.elements.map((trackItem, index) => {
      trackStatus.push(trackItem?.elements[0]?.text);
      return (
        <tr key={index}>
          <td>
            <div className="track-time">
              <p>
                <span></span>
              </p>
            </div>
          </td>
          <td>
            <div className="track-status">
              <p>
                {trackItem.elements[0].text ? trackItem.elements[0].text : "-"}
              </p>
            </div>
          </td>
        </tr>
      );
    });
    for(let track of trackStatus){
      if(track.includes("delivered")){
        Shippingstatus = "Delivery"
        break;
      }else if(track.includes("Arrived at USPS Facility")){
        Shippingstatus = "pickedUp"
        break;
      }else if(track.includes("In Transit")){
        Shippingstatus = "shipped"
        break;
      }
    }
    this.setState({
      shippingStatus: Shippingstatus,
      trackDetails,
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      showLoader: false,
      service: "Usps",
    });
  };

  createUpsTrackingDetail = (trackDetail) => {
    let shippingStatus = trackDetail.shippingStatus;

    let trackStatus = [];
    let trackType = [];
    let trackingDetails = []
    trackDetail.data.package && trackDetail.data.package.map((trackItem, index) => {
      trackItem.activity.map((i)=>{
        trackStatus.push(i.status.description)
      })
      if(trackStatus.includes("Delivered")){
        shippingStatus = "Delivery"
      }else if(trackStatus.includes("Pickup Scan ")){
        shippingStatus = "pickedUp"
      }else if(trackStatus.includes("Loaded on Delivery Vehicle ")){
        shippingStatus = "shipped"
      }
      trackingDetails = trackItem.activity.map((trackItem)=>{
        let timestamp = trackItem.date;

        if(timestamp[7]){
          timestamp = timestamp.slice(0,4)+"-"+timestamp.slice(4,6)+"-"+timestamp.slice(6,8)
        }

        const address = trackItem.location.address;
        return (
            <tr key={index}>
              <td>
                <div className="track-time">
                  {/* <p><span></span> Monday, June 8, 2020 04:07 PM, Seattle wa us</p> */}
                  <p>
                    <span></span> {timestamp}, {address.city ? address.city : null}{" "}
                    {address.stateProvince ? address.stateProvince : null}{" "}
                    {address.country ? address.country : null}
                  </p>
                </div>
              </td>
              <td>
                <div className="track-status">
                  <p>
                    {trackItem.status?.description
                        ? trackItem.status?.description
                        : null}
                  </p>
                </div>
              </td>
            </tr>
        );
      })
      // trackStatus.push(trackItem.status.description);
      trackType.push(trackItem.messages);
    });

    this.setState({
      orderTrackRecord: trackDetail.data.data,
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      orderTrackType: trackType,
      showLoader: false,
      service: "Ups",
      shippingStatus: shippingStatus,
    });
  };

  render() {
    let Uspsstage4 = 
    (this.state.service === "Usps" ||
        this.state.service === "Ups" ||
        this.state.service === "Fedex") &&
        this.state.shippingStatus === "Delivery"
        ? "Delivery"
        : null;

    let Uspsstage3 =
      (this.state.service === "Usps" ||
        this.state.service === "Ups" ||
        this.state.service === "Fedex") &&
        this.state.shippingStatus === "shipped"
        ? "shipped"
        : null;

    let Uspsstage2 =
      (this.state.service === "Usps" ||
        this.state.service === "Ups" ||
        this.state.service === "Fedex") &&
        this.state.shippingStatus === "processing"
        ? "processing"
        : null;

    let pickedUp = (this.state.service === "Usps" ||
        this.state.service === "Ups" ||
        this.state.service === "Fedex") &&
    this.state.shippingStatus === "pickedUp"
        ? "pickedUp"
        : null;

    return (
      <div className="admin-card">
        <div className="admin-card-head">
          <h2>order history</h2>
        </div>
        
        <div className="admin-card-box">
        {this.state.shippingStatus !== "canceled" ? (
          <div className="track-order-box">
            <ul>
              <li className="active">
                <div className="track-order-icon">
                  <i className="fas fa-shopping-basket"></i>
                </div>
                <p>order received</p>
              </li>
              
              <li
                className={
                                this.state.shippingStatus === "processing" ||
                                Uspsstage4 ||
                                Uspsstage3 ||
                                pickedUp
                                  ? "active"
                                  : null
                              }
              >
                <div className="track-order-icon">
                  <i className="fas fa-box"></i>
                </div>
                <p>pick up</p>
              </li>
              <li
                className={
                                this.state.shippingStatus === "shipped" ||
                                Uspsstage4 ||
                                Uspsstage3
                                  ? "active"
                                  : null
                              }
              >
                <div className="track-order-icon">
                  <i className="fas fa-truck"></i>
                </div>
                <p>in transit</p>
              </li>
              <li
                className={
                                this.state.shippingStatus === "Delivered" ||
                                Uspsstage4
                                  ? "active"
                                  : null
                              }
              >
                <div className="track-order-icon">
                  <i className="fas fa-check-circle"></i>
                </div>
                <p>delivered</p>
              </li>
            </ul>
          </div>
          ) : (
          <div className="order-tracker">
            <div className="track-border"></div>
            <ul>
              <li className="active">
                <div className="order-tracker-dots"></div>
                <p>Cancelled</p>
              </li>
            </ul>
          </div>
          )}

          <div className="vendor-table">
            <div className="vendor-table-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <td colspan="2">tracking details:</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orderTrackingTemplate?.length > 0 &&
                      this.state.shippingStatus !== "canceled"
                      ? this.state.orderTrackingTemplate
                      : "Tracking details not found"}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="vendor-table-bottom">
              <button
                onClick={() => this.fetchOrdertrackInfo(false)}
                type="button"
                className="orange-outline-btn"
              >
                retrack
                {(() => {
                  if (this.state?.showLoader) {
                    return (
                      <span className="spinner-border spinner-border-sm ml-1"></span>
                    );
                  }
                })()}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOrderTracking }, dispatch);
};

export default connect(null, mapDispatchToProps)(Order_Track);
