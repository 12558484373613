import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getStatisticsData = (sellerId, timeframe, startDate, endDate) => async (dispatch) => {
  try {

    let url = "";

    if (timeframe === "date") {
      if (startDate && startDate != "" && endDate && endDate != "") {
        url = `${URL}/api/v1/seller/stats/${sellerId}/${timeframe}?startDate=${startDate}&endDate=${endDate}`
      }
      else if (startDate && startDate != "") {
        url = `${URL}/api/v1/seller/stats/${sellerId}/${timeframe}?startDate=${startDate}`
      }
      else if (endDate && endDate != "") {
        url = `${URL}/api/v1/seller/stats/${sellerId}/${timeframe}?endDate=${endDate}`
      }
    }
    else {
      url = `${URL}/api/v1/seller/stats/${sellerId}/${timeframe}`
    }


    const res = await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });

    return res;
  }
  catch (error) {
    dispatch(handleError(error));
  }
}

export const getPageContent = (slug) => async (dispatch) => {
  try{
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/stats/page-content/${slug}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });

    return res.data;
  }catch (e) {
    dispatch(handleError(e));
  }
}
