import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import TimePicker from "react-time-picker";
import DatePicker from "react-date-picker";
import { getProductDetail } from "../../Redux/Actions/productAction";
import {
  createOneOnOneMeeting,
  oneOnOneMeetingDetail,
  approveOneOnOneMeetingRequest,
} from "../../Redux/Actions/oneOnOneMeetingAction";
import { ONE_TIME, BI_WEEKLY, MONTHLY, WEEKLY } from "../../Constant/Constant";
import { Link } from "react-router-dom";
import { getOrderDetail } from "../../Redux/Actions/orderAction";
import { addToCart } from "../../Redux/Actions/cartAction";
import Sidebar from "../Sidebar/Sidebar";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "../../Styles/profile.scss";
import AlertModal from "../../Components/AlertModal/AlertModal";

const OneOnOneMeetingsDetail = (props) => {
  const dispatch = useDispatch();
  const email = props.location.state;
  const [orderDetail, setOrderDetail] = useState({});

  const [showLoader, setShowLoader] = useState(false);
  const [showApproveMeetingLoader, setShowApproveMeetingLoader] =
    useState(false);
  const [rejectMeetingLoader, setRejectMeetingLoader] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [showHide, setShowHide] = useState(false);
  let [eventSelectedDay, setEventSelectedDay] = useState(new Date());
  const [meetingType, setMeetingType] = useState(undefined); // meeting type = 0 for recurring and 1 for one time
  const [reqMessage, setreqMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState({
    time: "",
    date: "",
  });
  const [isDisableButton, setIsDisableButton] = useState(false);
  const [meetingDurationType, setMeetingDurationType] = useState(WEEKLY);
  const [productTimeFrame, setProductTimeFrame] = useState();
  const [formData, setFormData] = useState({
    start: "",
    end: "",
    meetingDate: moment().format("YYYY-MM-DD"),
  });
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    singleButton: true,
  });
  const [acceptDetails, setAcceptDetails] = useState({});
  const [error, setError] = useState("")

  const [meetingMessage, setMeetingMessage] = useState();

  useEffect(() => {
    fetchOneOnOneMeetingDetails();
  }, []);

  const fetchOneOnOneMeetingDetails = async () => {
    const sellerTimeZone = JSON.parse(
      localStorage.getItem("sellerData")
    ).timezone;
    const res = await dispatch(
      oneOnOneMeetingDetail(props.match.params.meetingId, sellerTimeZone)
    );
    setOrderDetail(res.data.data);
  };

  const handleRequestMeeting = () => {
    setShowHide(true);
    setMeetingMessage();
  };

  const meetingHeader = () => {
    if (orderDetail && orderDetail.length > 0) {
      return (
        <div className="cart-box w-100">
          <div className="cart-box-detail w-100">
            <div className="cart-box-img">
              <img src={orderDetail[0].imageName} />
            </div>
            <div className="cart-box-item-detail w-100">
              <h2>{orderDetail[0].productName}</h2>
            </div>
          </div>
          {!orderDetail[orderDetail.length - 1].order_id &&
            orderDetail[orderDetail.length - 1].status !== "rejected" && (
              <button
                className="orange-btn white-nowrap"
                onClick={handleRequestMeeting}
              >
                Request new time
              </button>
            )}
        </div>
      );
    }
  };
  const handleModalShowHide = () => {
    setShowHide(false);
    setFormData({ start: "", end: "" });
    setMeetingType(undefined);
  };


  const handleApproveRejectRevokeMeeting = async (meetingStatus) => {
      if(meetingStatus === "approve"){
      const lastMeetingType = orderDetail[orderDetail.length -1]["meeting_type"];
      if(!lastMeetingType || lastMeetingType.length === 0 || lastMeetingType === "select"){
        setError("Please select a meeting type!")
        setTimeout(()=>setError(""),3000)
        return null;
      }
    }
    confirmAlert({
      title: "",
      message: `Do you want to ${
        meetingStatus == "pending" ? "revoke approval from" : meetingStatus
      } this meeting request?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            meetingStatus == "rejected" ? setRejectMeetingLoader(true) : setShowApproveMeetingLoader(true);
            const res = await dispatch(
              approveOneOnOneMeetingRequest(
                orderDetail[orderDetail.length - 1].id,
                meetingStatus,
                email,
                  props.match.params.meetingId,
                  orderDetail[orderDetail.length - 1]["meeting_type"]
              )
            );
            if (!res?.data.isError) {
              await fetchOneOnOneMeetingDetails();
            }
            setShowApproveMeetingLoader(false);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const createNewEvent = async () => {
    const startTimeDate = new Date(
      moment(new Date(eventSelectedDay)).format("MM-DD-YYYY") +
        " " +
        formData["start"]
    );
    const endTimeDate = new Date(
      moment(new Date(eventSelectedDay)).format("MM-DD-YYYY") +
        " " +
        formData["end"]
    );
    if (
      startTimeDate.getTime() <= Date.now() ||
      formData["start"] == formData["end"]
    ) {
      setErrorMsg({
        time: "Enter valid time",
        date: "",
      });
      setTimeout(() => {
        setErrorMsg({ time: "" });
      }, 2000);
      return;
    }
    if (startTimeDate.getTime() > endTimeDate.getTime()) {
      setErrorMsg({
        time: "Enter valid start date",
        date: "",
      });
      setTimeout(() => {
        setErrorMsg({ time: "" });
      }, 2000);
      return;
    }
    setIsDisableButton(true);
    setShowLoader(true);
    // if (props.match.params.meetingId) {
    //   isExistingMeeting = true;
    // }
    let body = {
      productId: orderDetail[0].product_id,
      userId: localStorage.getItem("sellerId"),
      sender: "vendor",
      meeting_type: productTimeFrame,
      meeting_date: moment(new Date(eventSelectedDay)).format("YYYY-MM-DD"),
      start_time: formData["start"],
      end_time: formData["end"],
      meeting_day: moment(new Date(eventSelectedDay)).day(),
      meetingId: props.match.params.meetingId,
      message: meetingMessage,
      validate: true,
    };
    setAcceptDetails(body);
    setShowHide(false);
    setAlertState({
      open: true,
      message:
        "For this slot meeting already Exists! Do you still want to continue?",
      singleButton: false,
      positiveButton: "Yes, Continue",
      negativeButton: "No, Cancel",
      id: 1,
    });
  };

  const handleMeetingTypeChange = (event) =>{
    const meetingList = [...orderDetail]
    meetingList[meetingList.length -1]["meeting_type"] = event.target.value
    setOrderDetail(meetingList)
  }

  console.log("orderDetail : ",orderDetail)
  const OrderDetail = () => {
    const date = orderDetail.createTS
      ? new Date(orderDetail.createTS).toISOString().substring(0, 10)
      : null;
    let variationKey = [];
    let variationValue = [];
    if (orderDetail.variant_values) {
      const variants = JSON.parse(orderDetail.variant_values);
      for (let key in variants) {
        variationKey.push(key);
        variationValue.push(variants[key]);
      }
    }
    const orderItems =
      orderDetail && orderDetail.length > 0 ? (
        orderDetail.map((i, index) => {
          return (
            <>
              <div
                key={i.id}
                className={`meeting-main ${
                  i.sender == "vendor" ? "right-meeting" : null
                }`}
              >
                <p>
                  <strong>Sender: </strong>
                  {i.sender}
                </p>
                <p>
                  <strong>Timeframe : </strong>
                  {i.timeframe.replace("_", " ")}
                </p>
                <p>
                  <strong>Status: </strong>
                  <span
                    className={`${
                      i.status == "pending"
                        ? "pendingColor"
                        : i.status == "approved"
                        ? "approvedColor"
                        : "rejectedColor"
                    }`}
                  >
                    {i.status}
                  </span>
                </p>
                <p>
                  <strong>Meeting Type: </strong>

                  {
                    index === (orderDetail.length -1) && i.meetingStatus === "pending" && i.status !== "approved"
                      ?
                        <select
                            value={i.meeting_type}
                            onChange={handleMeetingTypeChange}
                        >
                          <option value={null}>select</option>
                          <option value={"one_time"}>one time</option>
                          <option value={"weekly"}>weekly</option>
                          <option value={"bi_weekly"}>bi-weekly</option>
                          <option value={"monthly"}>monthly</option>
                        </select>

                        :
                        <>{i.meeting_type?.replace("_", " ")}</>
                  }
                  {
                    error.length>0 && <p className="form-field-error">{error}</p>
                  }

                </p>
                {i.message && (
                  <p>
                    <strong>Message: </strong>
                    {i.message}
                  </p>
                )}
                <p>
                  <strong>Meeting date: </strong>
                  {i.meeting_date?.slice(0, 10)}
                  <span className="item-custom-desc"></span>
                </p>
                <p>
                  <strong>Meeting day: </strong>
                  {i.meeting_day}
                </p>
                <p>
                  <strong>Meeting time: </strong>
                  <span className="colorTheme f600">
                    {i.start_time?.slice(0, 5)} - {i.end_time?.slice(0, 5)}
                  </span>
                </p>
                <p className="time-meeting">
                  {moment(i.created_ts).format("MM-DD-YYYY HH:mm A")}
                </p>
              </div>
              <div className="text-center mt-4">
              {index == orderDetail.length - 1 && i.status == "pending" && (
                <>
                  <button
                    className="orange-btn mr-2"
                    onClick={() => handleApproveRejectRevokeMeeting("approve")}
                  >
                    {" "}
                    Approve meeting request
                    {(() => {
                      if (showApproveMeetingLoader) {
                        return (
                          <span className="spinner-border spinner-border-sm ml-1"></span>
                        );
                      }
                    })()}
                  </button>
                  <button
                    className="orange-btn mr-2"
                    onClick={() => handleApproveRejectRevokeMeeting("rejected")}
                  >
                    {" "}
                    Reject meeting request
                    {(() => {
                      if (rejectMeetingLoader) {
                        return (
                          <span className="spinner-border spinner-border-sm ml-1"></span>
                        );
                      }
                    })()}
                  </button>
                </>
              )}
              {index == orderDetail.length - 1 &&
                i.status == "approved" &&
                !i.order_id && (
                  <button
                    className="orange-btn mr-2"
                    onClick={() => handleApproveRejectRevokeMeeting("pending")}
                  >
                    {" "}
                    Revoke Approval
                    {(() => {
                      if (showApproveMeetingLoader) {
                        return (
                          <span className="spinner-border spinner-border-sm ml-1"></span>
                        );
                      }
                    })()}
                  </button>
                )}
                </div>
            </>
          );
        })
      ) : (
        <h3>Loading...</h3>
      );
    return orderItems;
  };

  const handleChange = async (e, name) => {
    formData[name] = e ? e : "";
    if (name == "meetingDate") {
      setEventSelectedDay(e);
    }
    setFormData({ ...formData });
  };

  const modal = () => (
    <Modal
      show={showHide}
      onHide={handleModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={() => handleModalShowHide()}>
          <i className="fas fa-times"></i>
        </span>
        <h5 className="text-center w-100 m-0">Request One-on-One Meeting</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="details-slot">
          {reqMessage && (
            <div className="success-msg">
              <p>{reqMessage}</p>
            </div>
          )}
          {/* TODO */}
          {/*{console.log(productTimeFrame, "******************productTimeFrame")}
          <p>Frequency : {!meetingType ? productTimeFrame : "one time"}</p>*/}
          {/*<div className="d-flex align-items-center justify-content-center">
            <button
              className={`${
                meetingType === 0
                  ? "orange-btn-nohover"
                  : "orange-outline-btn-nohover"
              } mr-2`}
              onClick={(e) => handleSetMeetingEvent(e, 0)}
            >
              Recurring
            </button>
            <button
              className={
                meetingType === 1
                  ? "orange-btn-nohover"
                  : "orange-outline-btn-nohover"
              }
              onClick={(e) => handleSetMeetingEvent(e, 1)}
            >
              One Time
            </button>
          </div>*/}
          <h6 className="mt-3 text-center">
            {moment(new Date(eventSelectedDay)).format("MMMM Do YYYY")}
          </h6>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              {/* <label>
                <b>PRODUCT NAME</b>
              </label> */}
              <p>{props.product?.title}</p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
               <label><b>Meeting Type</b></label>
              <select
                  value={productTimeFrame}
                  onChange={(e)=> setProductTimeFrame(e.target.value)}
                  className="form-control"
              >
                <option value={null}>select</option>
                <option value={"one_time"}>one time</option>
                <option value={"weekly"}>weekly</option>
                <option value={"bi_weekly"}>bi-weekly</option>
                <option value={"monthly"}>monthly</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>
                <b>DATE</b>
              </label>
              <DatePicker
                name="date"
                dayPlaceholder={"dd"}
                monthPlaceholder={"mm"}
                yearPlaceholder={"yyyy"}
                required
                clearIcon={null}
                onChange={(e) => handleChange(e, "meetingDate")}
                className="form-control"
                // value={new Date(formData["meetingDate"])}
                value={eventSelectedDay}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                <b>START TIME</b>
              </label>
              <TimePicker
                value={formData["start"]}
                format="hh:mm a"
                name="start"
                disableClock={true}
                onChange={(e) => handleChange(e, "start")}
                required={true}
                clearIcon={null}
                className="form-control"
              />
              <span className="clock-icon">
                <i className="far fa-clock"></i>
              </span>
              <span className="form-field-error">{errorMsg.time}</span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>
                <b>END TIME</b>
              </label>
              <TimePicker
                value={formData["end"]}
                format="hh:mm a"
                name="end"
                disableClock={true}
                onChange={(e) => handleChange(e, "end")}
                required={true}
                clearIcon={null}
                className="form-control"
              />
              <span className="clock-icon">
                <i className="far fa-clock"></i>
              </span>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>
                <b>Message</b>
              </label>
              <textarea
                className="form-control"
                placeholder="Enter text message"
                onChange={(e) => setMeetingMessage(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="button-center">
          <button
            onClick={() => createNewEvent()}
            className="orange-btn"
            disabled={isDisableButton || !productTimeFrame || productTimeFrame.length === 0 || productTimeFrame === "select"}
          >
            Save Changes
            {(() => {
              if (showLoader) {
                return (
                  <span className="spinner-border spinner-border-sm ml-1"></span>
                );
              }
            })()}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  const handleAlertResponse = async (type, id, data) => {
    if (id === 1) {
      if (type === "positive") {
        await confirmCreateMeeting();
        await fetchOneOnOneMeetingDetails();
      }
    }
    closeAlertModal();
  };

  const closeAlertModal = () => {
    setAlertState({
      open: false,
      message: "",
      singleButton: true,
    });
    setShowLoader(false);
  };

  const confirmCreateMeeting = async () => {
    const result = await dispatch(
      createOneOnOneMeeting({ ...acceptDetails, validate: false })
    );
    if (!result.isError) {
      setreqMessage("request submitted successfully");
      setIsDisableButton(false);
      setShowLoader(false);
      setTimeout(() => {
        setShowHide(false);
        setreqMessage("");
      }, 1000);
      setIsDisableButton(false);
    }
  };

  return (
    <main className="mainContainer">
      <section className="profile">
        <div className="container">
          <div className="row">
            <Sidebar />
            <div className="col-lg-9">
              <section className="track-section">
                <div className="container">
                  {!!errorMessage.success && (
                    <div className="alert alert-success">
                      {errorMessage.success}
                    </div>
                  )}
                  {!!errorMessage.error && (
                    <div className="alert alert-danger">
                      {errorMessage.error}
                    </div>
                  )}
                  <div className="track-section-head sub-heading-track">
                    <div>
                      <h2>one on one meeting detail</h2>
                      <p>one on one meeting detail</p>
                    </div>
                  </div>
                  {meetingHeader()}
                  <div className="one-one-metting">
                    <OrderDetail />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      {modal()}
      <AlertModal
        data={alertState}
        closeAlertModal={closeAlertModal}
        handleAlertResponse={(type, id, data) =>
          handleAlertResponse(type, id, data)
        }
      />
    </main>
  );
};
export default OneOnOneMeetingsDetail;
