export const initialState = {
    documents: [],
    images: [],
    audios: [],
    videos: [],
    acsCatDocuments: [],
    acsCatImages: [],
    acsCatAudios: [],
    acsCatVideos: []
};

export const azureStorageReducer = (state = initialState, action) => {
    switch (action.type) {
        case "AZ_DOCUMENTS":
            return {
                ...state,
                documents: action.payload.files
            }
        case "ADD_AZ_DOCUMENTS":
            return {
                ...state,
                documents: [...state.documents, ...action.payload.files]
            }
        case "AZ_IMAGES":
            return {
                ...state,
                images: action.payload.files
            }
        case "ADD_AZ_IMAGES":
            return {
                ...state,
                images: [...state.images, ...action.payload.files]
            }
        case "AZ_AUDIOS":
            return {
                ...state,
                audios: [...action.payload.files]
            }
        case "ADD_AZ_AUDIOS":
            return {
                ...state,
                audios: [...state.audios, ...action.payload.files]
            }
        case "AZ_VIDEOS":
            return {
                ...state,
                videos: [...action.payload.files]
            }
        case "ADD_AZ_VIDEOS":
            return {
                ...state,
                videos: [...state.videos, ...action.payload.files]
            }
        case "AZ_ACS_CAT_DOCUMENTS":
            return {
                ...state,
                acsCatDocuments: action.payload.files
            }
        case "ADD_AZ_ACS_CAT_DOCUMENTS":
            return {
                ...state,
                acsCatDocuments: [...state.acsCatDocuments, ...action.payload.files]
            }
        case "AZ_ACS_CAT_IMAGES":
            return {
                ...state,
                acsCatImages: action.payload.files
            }
        case "ADD_AZ_ACS_CAT_IMAGES":
            return {
                ...state,
                acsCatImages: [...state.acsCatImages, ...action.payload.files]
            }
        case "AZ_ACS_CAT_AUDIOS":
            return {
                ...state,
                acsCatAudios: [...action.payload.files]
            }
        case "ADD_AZ_ACS_CAT_AUDIOS":
            return {
                ...state,
                acsCatAudios: [...state.acsCatAudios, ...action.payload.files]
            }
        case "AZ_ACS_CAT_VIDEOS":
            return {
                ...state,
                acsCatVideos: [...action.payload.files]
            }
        case "ADD_AZ_ACS_CAT_VIDEOS":
            return {
                ...state,
                acsCatVideos: [...state.acsCatVideos, ...action.payload.files]
            }
        case "AZ_UPDATE_FILE":
            let tempStatePer = state;
            let files = tempStatePer[action.payload.type];
            let file = files[action.payload.index];
            file[action.payload.key] = action.payload.value;
            return {
                ...tempStatePer,
            }
        default:
            return state
    }
}