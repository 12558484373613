import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getZoomDetails = (userId) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/zoom/details?userId=${userId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res?.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const installZoomApp = (userId, code) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/zoom/installApp?code=${code}&userId=${userId}`,
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res?.data;
        // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const updateZoomDetails = (data) => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: "patch",
            url: `${URL}/api/v1/seller/zoom/details`,
            data: data,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        dispatch({ type: "LOADING_COMPLETED" });
        return res?.data;
    } catch (error) {
        dispatch(handleError(error));
    }
};

//add wishlist api
export const generateToken = (data) => async (dispatch) => {

    try {
        dispatch({type: "LOADING_STARTED"});
        const res = await axios({
            method: 'post',
            url: `${URL}/api/v1/seller/zoom-service/generate-token`,
            data: data,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        dispatch({type: "LOADING_COMPLETED"});

        return res;
    } catch (error) {
        dispatch(handleError(error));
    }
}