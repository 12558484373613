import React, { Component } from "react";
import "../../Styles/profile.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from "react-select";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import profileAvtar from "../../assets/images-2/profile-img.png";

import {
  updateProfile,
  getCountriesList,
  getStatesList,
  getCitiesList,
  getCitiesListByStateCode,
  connectStripeAccount,
  checkStripeDetailsCompleted,
  installZoomApp,
} from "../../Redux/Actions/userAction";
import TimePicker from 'react-time-picker';
import moment from "moment";

class Profile extends Component {
  fieldsList = {
    id: null,
    firstname: "",
    lastname: "",
    email: "",
    imagename: "",
    role: "",
    isacceptedtnc: null,
    isactive: null,
    reset: "",
    createts: "",
    updatedts: "",
    addressId: null,
    phonenumber: "",
    address: "",
    address_2: "",
    returnShipmentAddress_2: "",
    country: "",
    countryCode: "",
    state: "",
    stateCode: "",
    city: "",
    returnCity: "",
    returnCountry: "",
    returnState: "",
    zipCode: "",
    returnShipmentZipcode: "",
    type: "",
    sellerStoreId: null,
    storename: "",
    storeOverView: "",
    return_shipment_address: "",
    storeEmail: "",
    vendorSubscriptionOrderId: null,
    subscriptionPackageId: null,
    fee_percent: 0,
    subscriptionStart: null,
    subscriptionEnd: null,
    subscriptionName: "",
    subscriptionDescription: null,
    subscriptionOptions: "",
    subscriptionTimeFrame: "",
    subscriptionAmount: 0,
    subscriptionStatus: null,
    timestamp: "",
    successMessage: "",
    errorMessage: "",
    stripeErrorMessage: "",
    errorData: {},
    showLoader: false,
    countriesList: [],
    defaultSelectedCountry: [],
    returnCountriesList: [],
    returnDefaultSelectedCountry: [],
    statesList: [],
    returnStatesList: [],
    defaultSelectedState: [],
    returnDefaultSelectedState: [],
    citiesList: [],
    returnCitiesList: [],
    defaultSelectedCity: [],
    returnDefaultSelectedCity: [],
    timezoneList: [
      { value: "AKDT", label: "Alaska Daylight Time - AKDT" },
      { value: "CDT", label: "Central Daylight Time - CDT" },
      { value: "EDT", label: "Eastern Daylight Time - EDT" },
      { value: "HST", label: "Hawaii-Aleutian Standard Time - HST" },
      { value: "MDT", label: "Mountain Daylight Time - MDT" },
      { value: "MST", label: "Mountain Standard Time - MST" },
      { value: "PDT", label: "Pacific Daylight Time - PDT" },
    ],
    timezone: { value: "EDT", label: "Eastern Daylight Time - EDT" },
    // availableFrom: "",
    availableFrom: localStorage.getItem("sellerData") ? JSON.parse(localStorage.getItem("sellerData")).available_from : '',
    // availableTo: "",
    availableTo: localStorage.getItem("sellerData") ? JSON.parse(localStorage.getItem("sellerData")).available_to : "",
    stripeEmail: "",
    userIp: null,
    stripeUrl: null,
    stripe_vendor_account_id: null,
    stripe_details_submitted: 0,
    stripe_vendor_email: "",
    stripeEmailError: {},
    showLoaderForStripe: false,
    isChatActive: 2,
    isDetailSubmitted: false,
    bottomErrorMessage: "",
    src: null,
    crop: {
      unit: "%",
      width: 30,
      aspect: 1,
    },
    selectedImg: null,
    profileNote: "",
  };

  constructor(props) {
    super(props);
    this.state = { ...this.fieldsList };
    this.fileSelectHandler = this.fileSelectHandler.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.fileUpload = React.createRef();
  }

  componentDidMount() {   
    if (this.props.authData) {
      // this.getIpForUser();
      this.setUserState(this.props.authData);
      if (this.props.authData.stripe_vendor_account_id) {
        this.checkstripeDetails(
          this.props.authData.stripe_vendor_account_id,
          this.props.authData.id
        );
      }
    }
    if (this.state.countriesList.length === 0 || this.state.returnDefaultSelectedCountry.length === 0) {
      this.fetchCountriesList();
    } else {
      this.createCountrySelectOptions();
    }

    if (this.state.statesList.length === 0 || this.state.returnStatesList.length === 0) {
      this.fetchStatesList();
    } else {
      this.createStateSelectOptions();
    }
  }

  // async getIpForUser() {
  //   window
  //     .fetch("https://api.ipify.org/?format=json")
  //     .then((resp) => resp.json())
  //     .then((data) =>
  //       this.setState({
  //         userIp: data.ip,
  //       })
  //     );
  // }

  checkstripeDetails = async (accountId, userId) => {
    const res = await this.props.checkStripeDetailsCompleted(accountId, userId);

    this.setState({
      isDetailSubmitted: res?.data?.details_submitted
    })
    
  };
  async fileSelectHandler(e) {
    let imgStatus = false;
    if (e.target.files[0] !== undefined)
      if (e.target.files[0].type !== undefined) {
        let imgType = e.target.files[0].type.split("/");
        let MimeTypeArray = ["jpg", "jpeg", "png", "gif"];
        if (imgType[1] !== undefined) {
          if (MimeTypeArray.includes(imgType[1])) {
            if (e.target && e.target.files[0]) {
              let img = e.target.files[0];
              this.setState({
                selectedImg: img,
              });
              this.readFile(img);
            }
          } else {
            imgStatus = true;
          }
        }
      }
    this.setState({ invalidImage: imgStatus });
  }

  handleDrop = (files) => {
    if (files.length > 1) {
      //  toast.error("You can add only one file");
      return;
    }
    this.setState({
      selectedImg: files[0],
    });
  };

  setUserState(userInfo) {
    // console.log(JSON.parse(userInfo.return_shipment_address),"***************************userInfo")

    if (userInfo.state_code && userInfo.state_code.trim().length > 0) {
      if (this.state.citiesList.length === 0 || this.state.returnCitiesList.length === 0) {
        this.fetchCitiesListByStateCode(userInfo.state_code);
      } else {
        this.createCitiesSelectOptions();
      }
    }
    const timezone = this.state.timezoneList?.find(
      (item) => item.value === userInfo.timezone
    );
    const returnShipmentDetails =  (userInfo?.return_shipment_address &&  userInfo?.return_shipment_address!== null &&userInfo?.return_shipment_address!== "" )? JSON.parse(userInfo?.return_shipment_address): null
    this.setState({
      ...userInfo,
      timezone: timezone ? timezone : this.state.timezone,
      storeOverView: userInfo.store_overview? userInfo.store_overview: null,
      return_shipment_address: (userInfo?.return_shipment_address!== null &&userInfo.return_shipment_address!== "" )? returnShipmentDetails?.return_shipment_address : null,
      returnShipmentAddress_2: (userInfo?.return_shipment_address!== null &&userInfo.return_shipment_address!== "" ) ? returnShipmentDetails?.returnShipmentAddress_2 : null,
      returnShipmentZipcode:(userInfo?.return_shipment_address!== null &&userInfo.return_shipment_address!== "" ) ? returnShipmentDetails?.returnShipmentZipcode: null,
      isChatActive: userInfo?.isChatActive ? userInfo.isChatActive : 2,
      defaultSelectedCountry: [
        {
          value: `${JSON.stringify({
            country_code: userInfo.country_code,
            country_name: userInfo.country,
          })}`,
          label: `${userInfo.country}`,
        },
      ],
      defaultSelectedState: userInfo.state ? [
        {
          value: `${JSON.stringify({
            stateCode: userInfo.state_code,
            stateName: userInfo.state,
          })}`,
          label: `${userInfo.state}`,
        },
      ]: [],
      defaultSelectedCity: userInfo.city ? [
        {
          value: `${JSON.stringify({ cityName: userInfo.city })}`,
          label: `${userInfo.city}`,
        },
      ]: [],
      returnDefaultSelectedCountry:[
        {
          value: `${JSON.stringify({
            country_code: userInfo.country_code,
            country_name:  (userInfo?.return_shipment_address!== null &&userInfo?.return_shipment_address!== "" ) ? returnShipmentDetails?.returnCountry : null,
          })}`,
          label:  (userInfo?.return_shipment_address!== null && userInfo?.return_shipment_address!== "" ) ? `${returnShipmentDetails?.returnCountry}`: null,
        },
      ],
      returnDefaultSelectedState: returnShipmentDetails?.returnState ? [
        {
          value: `${JSON.stringify({
            stateCode: userInfo.country_code,
            stateName:  (userInfo.return_shipment_address!== null &&userInfo.return_shipment_address!== "" ) ? returnShipmentDetails?.returnState : null,
          })}`,
          label: `${returnShipmentDetails?.returnState}`,
        },
      ] : [],
      returnDefaultSelectedCity: returnShipmentDetails?.returnCity ? [
        {
          value:  (userInfo?.return_shipment_address!== null && userInfo?.return_shipment_address!== "" )?`${JSON.stringify({cityName: returnShipmentDetails?.returnCity,})}`: null,
          label: (userInfo?.return_shipment_address!== null && userInfo?.return_shipment_address!== "" )? `${returnShipmentDetails?.returnCity}`: null,
        },
      ]: [],
      returnCountry: returnShipmentDetails?.returnCountry,
      returnState: returnShipmentDetails?.returnState,
      returnCity: returnShipmentDetails?.returnCity,
    });
  }

  async fetchCountriesList() {
    let countries = await this.props.getCountriesList();
    if (countries?.data?.data?.length > 0) {
      // this.createPlacesOption(countries.data.data, "countriesList")
      await this.setState({
        countriesList: countries.data.data,
        returnCountriesList: countries.data.data,
      },()=>{
      });
      this.createCountrySelectOptions();
    }
  }

  createCountrySelectOptions = () => {
    const countryList = this.state.countriesList;
    const selectedCountry = this.state.country;
    let defaultSelectedOptions = [];

    let selectOptions = countryList.map((item) => {
      if (selectedCountry == item.name) {
        defaultSelectedOptions.push({
          value: `${JSON.stringify({
            countryId: item.id,
            country_code: item.country_code,
            country_name: item.name,
          })}`,
          label: `${item.name}`,
        });
      }
      return {
        value: `${JSON.stringify({
          countryId: item.id,
          country_code: item.country_code,
          country_name: item.name,
        })}`,
        label: `${item.name}`,
      };
    });

    this.setState({
      defaultSelectedCountry: defaultSelectedOptions,
      countriesList: selectOptions,
      returnDefaultSelectedCountry: defaultSelectedOptions,
      returnCountriesList: selectOptions
    });

    // return { defaultSelectedOptions, selectOptions }
  };

  async fetchStatesList() {
    let statesList = await this.props.getStatesList(1);
    if (statesList?.data?.data?.length > 0) {
      // this.createPlacesOption(countries.data.data, "countriesList")
      await this.setState({
        statesList: statesList.data.data,
        returnStatesList: statesList.data.data
      });
      this.createStateSelectOptions();
    }
  }

  createStateSelectOptions = () => {
    const stateList = this.state.statesList;
    let selectOptions = stateList.map((state) => {
      return {
        value: `${JSON.stringify({
          stateId: state.id,
          stateCode: state.state_code,
          stateName: state.state_name,
        })}`,
        label: `${state.state_name}`,
      };
    });

    this.setState({
      statesList: selectOptions,
      returnStatesList: selectOptions
    });
  };

  async fetchCitiesListByStateCode(state_code) {
    let citiesList = await this.props.getCitiesListByStateCode(state_code);
    if (citiesList?.data?.data?.length > 0) {
      // this.createPlacesOption(countries.data.data, "countriesList")
      await this.setState({
        citiesList: citiesList.data.data,
        returnCitiesList: citiesList.data.data,
      });
      this.createCitiesSelectOptions();
    }
  }

  createCitiesSelectOptions = () => {
    const citiesList = this.state.citiesList;
    let selectOptions = citiesList.map((city) => {
      return {
        value: `${JSON.stringify({ cityId: city.id, cityName: city.city })}`,
        label: `${city.city}`,
      };
    });

    this.setState({
      citiesList: selectOptions,
      returnCitiesList: selectOptions
    });
  };

  componentWillReceiveProps(nextProps, prevState) {
    if (Object.keys(this.state).length > 0) {
      if (
        nextProps.authData &&
        nextProps.authData?.timestamp !== this.state.timestamp
      ) {
        this.setUserState(nextProps.authData);
        if (nextProps.authData.stripe_vendor_account_id) {
          this.checkstripeDetails(
            nextProps.authData.stripe_vendor_account_id,
            nextProps.authData.id
          );
        }
        // if (this.state.countriesList.length === 0) {
        //     this.fetchCountriesList();
        // } else {
        //     this.createCountrySelectOptions();
        // }
        // this.setState({
        //     ...nextProps.authData
        // })
      }
    } else if (nextProps.authData !== null) {
      this.setUserState(nextProps.authData);
      if (nextProps.authData.stripe_vendor_account_id) {
        this.checkstripeDetails(
          nextProps.authData.stripe_vendor_account_id,
          nextProps.authData.id
        );
      }
      // if (this.state.countriesList.length === 0) {
      //     this.fetchCountriesList();
      // } else {
      //     this.createCountrySelectOptions();
      // }
      // this.props.userInfo = {...nextProps.userInfo}
      // this.setState({
      //     ...nextProps.authData
      // })
    }
  }

  handleChange = (e, key) => {
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }

    let value  = (key === "phonenumber" || key === "availableTo" || key === "availableFrom") ? e : e.target?.value;


    if (key === "phonenumber" || key === "zipCode" || key === "returnShipmentZipcode") {
      value = value?.replace(/\D+/g, "");
    }
  
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  handleSelectChange = (e, key) => {
    console.log(`evenet`, e, key)
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }
    this.setState((prevState) => ({
      ...prevState,
      [key]: key === "timezone" ? e : e.target.value,
    }));
  };

  validateUserInfo = () => {
    let isFormValid = true;
    this.state.errorData = {
      firstname: [],
      lastname: [],
      phonenumber: [],
      address: [],
      zipCode: [],
      city: [],
      state: [],
      country: [],
      returnCity: [],
      returnCountry: [],
      returnState: [],
      returnShipmentZipcode: [],
      storename: [],
      storeOverView:[],
      return_shipment_address: [],
      storeEmail: [],
      timezone: [],
      availableFrom: [],
      availableTo: [],
    };

    if (this.state.firstname.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.firstname = ["Firstname field cannot be empty."];
    }
    // else if (this.state.firstname.search("^[a-zA-Z ]+$")) {
    //   isFormValid = false;
    //   this.state.errorData.firstname = ["First Name only contain Characters"];
    // }
    else if (this.state.firstname.includes(`"`) || this.state.firstname.includes(`'`) || this.state.firstname.includes("`")) {
      isFormValid = false;
      this.state.errorData.firstname = ["This field cannot contain quotes"];
    }

    if (this.state.lastname.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.lastname = ["Lastname field cannot be empty."];
    } 
    // else if (this.state.lastname.search("^[a-zA-Z]+$")) {
    //   isFormValid = false;
    //   this.state.errorData.lastname = ["last Name only contain Characters"];
    // }
    else if (this.state.lastname.includes(`"`) || this.state.lastname.includes(`'`) || this.state.lastname.includes("`")) {
      isFormValid = false;
      this.state.errorData.lastname = ["This field cannot contain quotes"];
    }

    if (!this.state.phonenumber || this.state.phonenumber.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.phonenumber = [
        "Phone number field cannot be empty.",
      ];
    }

    if (!this.state.address || this.state.address.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.address = ["Address field cannot be empty."];
    }
    else if (this.state.address.includes(`"`) || this.state.address.includes(`'`) || this.state.address.includes("`")) {
      isFormValid = false;
      this.state.errorData.address = ["This field cannot contain quotes"];
    }

    if (!this.state.zipCode || this.state.zipCode.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.zipCode = ["Zip code field cannot be empty."];
    }

    if (!this.state.city || this.state.city.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.city = ["City field cannot be empty."];
    }
    if (!this.state.returnCity || this.state.returnCity.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.returnCity = ["City field cannot be empty."];
    }

    if (!this.state.state || this.state.state.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.state = ["State field cannot be empty."];
    }
    if (!this.state.returnState || this.state.returnState.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.returnState = ["State field cannot be empty."];
    }

    if (!this.state.country || this.state.country.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.country = ["Country field cannot be empty."];
    }
    if (!this.state.returnCountry || this.state.returnCountry.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.returnCountry = ["Country field cannot be empty."];
    }

    if (!this.state.return_shipment_address || this.state.return_shipment_address.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.return_shipment_address = ["Return shipment address field cannot be empty."];
    }
    if (!this.state.returnShipmentZipcode || this.state.returnShipmentZipcode.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.returnShipmentZipcode = ["Zipcode field cannot be empty."];
    }
    if (!this.state.storename || this.state.storename.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.storename = ["Store name field cannot be empty."];
    }
    if (!this.state.storeOverView || this.state.storeOverView.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.storeOverView = ["Store overview field cannot be empty."];
    }

    // else if (this.state.storename.search("^[a-zA-Z0-9 ]+$")) {
    //   isFormValid = false;
    //   this.state.errorData.storename = ["Store name cannot contain special characters"];
    // }
    // else if (this.state.storename.includes(`"`) || this.state.storename.includes(`'`) || this.state.storename.includes("`")) {
    //   isFormValid = false;
    //   this.state.errorData.storename = ["This field cannot contain quotes"];
    // }

    if (!this.state.storeEmail || this.state.storeEmail.trim().length === 0 ) {
      isFormValid = false;
      this.state.errorData.storeEmail = ["Email field cannot be empty."];
    }

    if (!this.isEmailAddress(this.state.storeEmail)) {
      isFormValid = false;
      this.state.errorData.storeEmail = ["Enter Correct Email Id."];
    }

    

    if (!this.state.timezone || !this.state.timezone.value) {
      isFormValid = false;
      this.state.errorData.timezone = ["Timezone field cannot be empty."];
    }
    if (!this.state.availableFrom) {
      isFormValid = false;
      this.state.errorData.availableFrom = ["From field cannot be empty."];
    }
    if (!this.state.availableTo) {
      isFormValid = false;
      this.state.errorData.availableTo = ["To field cannot be empty."];
    }
    if (this.state.availableFrom > this.state.availableTo) {
      isFormValid = false;
      this.state.errorData.availableTo = [
        "To field cannot be grater then From field.",
      ];
    }

    if(!isFormValid){
      this.setState({
        bottomErrorMessage: "Please Fill All the (*) Details"
      })
    }

    let tempState = this.state;
    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  handleSubmit = async () => {
    let st = {...this.state};
    st.storeOverView = this.state.storeOverView?.trim()
    st.storename = this.state.storename?.trim()
    await this.setState((prevState) => ({
      ...st,
      successMessage: "",
      errorMessage: "",
      showLoader: true,
      profileNote: "",
      bottomErrorMessage: "",
    }));

    if (this.validateUserInfo()) {
      const returnShipmentAddress = {
        return_shipment_address: st.return_shipment_address,
        returnShipmentAddress_2: st.returnShipmentAddress_2,
        returnCountry: st.returnCountry,
        returnState: st.returnState,
        returnCity: st.returnCity,
        returnShipmentZipcode: st.returnShipmentZipcode,
      }
      console.log("returnShipmentAddress",JSON.stringify(returnShipmentAddress));
      const profile = {...this.state};
      profile["return_shipment_address"]=JSON.stringify(returnShipmentAddress)
      profile.timezone = profile.timezone.value;
      console.log("profileprofileprofile",profile);

      const result = await this.props.updateProfile({ ...profile });

      if (result.status === 201) {
        localStorage.setItem('timezone', profile.timezone)
        localStorage.setItem('availableFrom', profile.availableFrom)
        localStorage.setItem('availableTo', profile.availableTo)
        this.setState((prevState) => ({
          ...prevState,
          timestamp: Date.now(),
          successMessage: "Profile updated successfully!",
          showLoader: false,
          src: null,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          errorMessage: "Something went wrong! Images should be less than 8 MB",
          showLoader: false,
          src: null,
        }));
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
    if (this.state.successMessage || this.state.errorMessage){
      window.scrollTo(0, 0);
    }
  };

  handleCountryChange = async (event, key) => {
    const countryValue = JSON.parse(event.value);
    if (
      this.state.errorData &&
      this.state.errorData["country"] &&
      this.state.errorData["country"][0]
    ) {
      this.state.errorData["country"][0] = null;
    }
    let statesList = await this.props.getStatesList(countryValue.countryId);
    if (statesList?.data?.data?.length > 0) {
      statesList = statesList.data.data.map((state) => ({
        value: `${JSON.stringify({
          stateId: state.id,
          stateCode: state.state_code,
          stateName: state.state_name,
        })}`,
        label: `${state.state_name}`,
      }));

      // if (
      //   this.state.errorData &&
      //   this.state.errorData["state"] &&
      //   this.state.errorData["state"][0]
      // ) {
      //   this.state.errorData["state"][0] = null;
      // }
     if(key== "fullfillment_country"){
       this.setState((prevState) => ({
         ...prevState,
         country: countryValue.country_name,
         countryCode: countryValue.country_code.toUpperCase(),
         defaultSelectedCountry: event,
         statesList: statesList,
         state: "",
         defaultSelectedState: [],
         citiesList: [],
         defaultSelectedCity: [],
         city: "",
       }));
     }
     if(key == "return_shipment_country"){
      if (
        this.state.errorData &&
        this.state.errorData["returnCountry"] &&
        this.state.errorData["returnCountry"][0]
      ) {
        this.state.errorData["returnCountry"][0] = null;
      }
      this.setState((prevState) => ({
        ...prevState,
        returnCountry: countryValue.country_name,
        returnCountryCode: countryValue.country_code.toUpperCase(),
        returnDefaultSelectedCountry: event,
        returnStatesList: statesList,
        returnState: "",
        returnDefaultSelectedState: [],
        returnCitiesList: [],
        returnDefaultSelectedCity: [],
        returnCity: "",
      }));

     }
    }
  };

  handleStateChange = async (event, key) => {
    const stateValue = JSON.parse(event.value);
    let citiesList = await this.props.getCitiesList(stateValue.stateId);
    if (citiesList?.data?.data?.length > 0) {
      citiesList = citiesList.data.data.map((city) => ({
        value: `${JSON.stringify({ cityId: city.id, cityName: city.city })}`,
        label: `${city.city}`,
      }));

      if (
        this.state.errorData &&
        this.state.errorData["state"] &&
        this.state.errorData["state"][0]
      ) {
        this.state.errorData["state"][0] = null;
      }
     if(key == "fullfillment_state"){
      // this.setState({

      // });
       this.setState((prevState) => ({
         ...prevState,
         citiesList: citiesList,
         defaultSelectedCity: [],
         defaultSelectedState: event,
         state: stateValue.stateName,
         stateCode: stateValue.stateCode,
       }));
      
     }
     else if (key == "return_shipment_state"){
      if (
        this.state.errorData &&
        this.state.errorData["returnState"] &&
        this.state.errorData["returnState"][0]
      ) {
        this.state.errorData["returnState"][0] = null;
      }
      this.setState((prevState) => ({
        ...prevState,
        returnCitiesList: citiesList,
        returnDefaultSelectedCity: [],
        returnDefaultSelectedState: event,
        returnState: stateValue.stateName,
        returnStateCode: stateValue.stateCode,
      }));
     }
    }
  };
  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageURL = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({
        croppedImageUrl: croppedImageURL,
        selectedImg: croppedImageURL,
      });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      var dataURL = canvas.toDataURL();
      resolve(dataURL);
    });
  }
  
  triggerClick = () => {
    this.fileUpload.current.click();
  };
  onImageLoaded = (image) => {
    this.imageRef = image;
    this.setState({
      profileNote : "Please Click On Save Button Below To Save Uploaded Image"
    })
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  handleCityChange = (event, key) => {
    const cityValue = JSON.parse(event.value);
    if (
      this.state.errorData &&
      this.state.errorData["city"] &&
      this.state.errorData["city"][0]
    ) {
      this.state.errorData["city"][0] = null;
    }
    if(key== "fullfillment_city"){
      this.setState((prevState) => ({
        ...prevState,
        defaultSelectedCity: event,
        city: cityValue.cityName,
      }));
    }
    else if (key== "return_shipment_city"){
      if (
        this.state.errorData &&
        this.state.errorData["returnCity"] &&
        this.state.errorData["returnCity"][0]
      ) {
        this.state.errorData["returnCity"][0] = null;
      }
      this.setState((prevState) => ({
        ...prevState,
        returnDefaultSelectedCity: event,
        returnCity: cityValue.cityName,
      }));
    }
  };

  isEmailAddress(email) {
    var pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(String(email).toLowerCase()); // returns a boolean
  }

  checkEmailValidOrNot = () => {
    let isValid = true;
    this.state.errorData = {
      stripe_vendor_email: [],
    };
    if (!this.isEmailAddress(this.state.stripe_vendor_email)) {
      isValid = false;
      this.state.errorData.stripe_vendor_email = [
        "Please enter valid email address",
      ];
    }
    if (
      this.state.stripe_vendor_email === "" ||
      this.state.stripe_vendor_email === null ||
      this.state.stripe_vendor_email.trim().length === 0
    ) {
      isValid = false;
      this.state.errorData.stripe_vendor_email = [
        "Please enter correct details",
      ];
    }

    let tempdata = this.state;
    this.setState({
      ...tempdata,
    });
    return isValid;
  };

  handleConnectStripeAccount = async (event, keyChange) => {
    event.preventDefault();

    if (this.validateUserInfo()){

      this.setState({
        stripeErrorMessage: "",
      })
      let isValidDetails = await this.checkEmailValidOrNot();
      if (isValidDetails) {
        await this.setState({
          showLoaderForStripe: true,
        });
        let emailChange = false;
        if (
          this.props.authData?.stripe_vendor_email !==
          this.state.stripe_vendor_email
        ) {
          emailChange = true;
        }
        console.log(` this.state.stripe_vendor_email,
          this.state.id,
          this.state.stripe_vendor_account_id,
          emailChange`, this.state.stripe_vendor_email,
            this.state.id,
            this.state.stripe_vendor_account_id,
            emailChange)
        const res = await this.props.connectStripeAccount(
          this.state.stripe_vendor_email,
          this.state.id,
          this.state.stripe_vendor_account_id,
          emailChange
        );
        if (!res?.isError && res?.isError !== undefined) {
          const profile = this.state;
          const returnShipmentAddress = {
          return_shipment_address: profile.return_shipment_address,
          returnShipmentAddress_2: profile.returnShipmentAddress_2,
          returnCountry: profile.returnCountry,
          returnState: profile.returnState,
          returnCity: profile.returnCity,
          returnShipmentZipcode: profile.returnShipmentZipcode,
        }
          profile.timezone = profile.timezone.value;
          profile["return_shipment_address"]=JSON.stringify(returnShipmentAddress)
          const result = await this.props.updateProfile({ ...profile });
          this.setState({
            stripeUrl: res?.accountLink.url,
            stripe_vendor_account_id: res?.accountId,
            showLoaderForStripe: false,
            stripe_details_submitted: !emailChange && keyChange ? 1 : 0,
          });
        }else{
          if (res.statusCode){
            this.setState((prevState) => ({
              ...prevState,
              stripeErrorMessage: res?.raw?.message,
              showLoaderForStripe: false,
            }))
          }else{
            this.setState((prevState) => ({
              ...prevState,
              stripeErrorMessage: res?.message,
              showLoaderForStripe: false,
            }));
          }
        }
      }
    }

  };

  /*handleConnectZoomAccount = async (event) => {
    event.preventDefault();
    const res = await this.props.installZoomApp();
    this.setState({
      zoomUrl: res,
    });
  };*/

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    return (
      <div className="admin-card">
        <div className="admin-card-head">
          <h2>profile</h2>
        </div>
        {(() => {
          if (
            this.state.successMessage !== "" &&
            this.state.successMessage !== undefined
          ) {
            return (
              <div className="alert alert-success">
                {this.state.successMessage}
              </div>
            );
          } else if (
            this.state.errorMessage !== "" &&
            this.state.errorMessage !== undefined
          ) {
            return (
              <div className="alert alert-danger">
                {this.state.errorMessage}
              </div>
            );
          }
        })()}
        <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8">
              <div className="admin-card-box">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>
                  first name
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  maxLength="15"
                  value={this.state.firstname}
                  onChange={(event) => this.handleChange(event, "firstname")}
                  className="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.firstname &&
                    this.state.errorData.firstname[0]}
                </span>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>
                  last name
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  maxLength="15"
                  value={this.state.lastname}
                  onChange={(event) => this.handleChange(event, "lastname")}
                  className="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.lastname &&
                    this.state.errorData.lastname[0]}
                </span>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>
                  email address
                  <sup>*</sup>
                </label>
                <input
                  disabled={true}
                  type="email"
                  value={this.state.email}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>
                  phone number
                  <sup>*</sup>
                </label>
                {/* <input
                  type="text"
                  maxLength="15"
                  value={this.state.phonenumber}
                  onChange={(event) => this.handleChange(event, "phonenumber")}
                  className="form-control"
                  placeholder="Phone Number"
                /> */}

                      <PhoneInput
                       className="phone-input"
                        country={'us'}
                        value={this.state.phonenumber}
                        onChange={(event) => this.handleChange(event, "phonenumber")}
                        placeholder="Phone Number"
                        onlyCountries={["us"]}
                      />

                <span className="form-field-error">
                  {this.state.errorData?.phonenumber &&
                    this.state.errorData.phonenumber[0]}
                </span>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <label>
                  fullfillment address
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  maxlength="50"
                  className="form-control"
                  placeholder="Address"
                  value={this.state?.address}
                  onChange={(event) => this.handleChange(event, "address")}
                />
                <span className="form-field-error">
                  {this.state.errorData?.address &&
                    this.state.errorData.address[0]}
                </span>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <label>address line 2</label>
                <input
                  type="text"
                  maxlength="50"
                  className="form-control"
                  placeholder="Address line 2"
                  value={this.state?.address_2}
                  onChange={(event) => this.handleChange(event, "address_2")}
                />
                {/* <span className="form-field-error">
                                    {this.state.errorData ?.address_2 &&
                                        this.state.errorData.address_2[0]}
                                </span> */}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  country
                  <sup>*</sup>
                </label>
                {/* <input type="text" maxlength="15" className="form-control" placeholder="Country" value={this.state ?.country} onChange={(event) => this.handleChange(event, 'country')} /> */}
                <Select
                  value={this.state.defaultSelectedCountry}
                  name="value"
                  options={this.state.countriesList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Country"
                  onChange={(event) => this.handleCountryChange(event, "fullfillment_country")}
                />
                <span className="form-field-error">
                  {this.state.errorData?.country &&
                    this.state.errorData.country[0]}
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  state
                  <sup>*</sup>
                </label>
                {/* <input type="text" maxlength="15" className="form-control" placeholder="State" value={this.state ?.state} onChange={(event) => this.handleChange(event, 'state')} /> */}
                <Select
                  value={this.state.defaultSelectedState}
                  name="value"
                  options={this.state.statesList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="State"
                  onChange={(event) => this.handleStateChange(event, "fullfillment_state")}
                />
                <span className="form-field-error">
                  {this.state.errorData?.state && this.state.errorData.state[0]}
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  city
                  <sup>*</sup>
                </label>
                {/* <input type="text" maxlength="15" className="form-control" placeholder="City" value={this.state ?.city} onChange={(event) => this.handleChange(event, 'city')} /> */}
                <Select
                  value={this.state.defaultSelectedCity}
                  name="value"
                  options={this.state.citiesList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="City"
                  onChange={(event) => this.handleCityChange(event, "fullfillment_city")}
                />
                <span className="form-field-error">
                  {this.state.errorData?.city && this.state.errorData.city[0]}
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  zip code
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  maxlength="6"
                  className="form-control"
                  placeholder="Zip Code"
                  value={this.state?.zipCode}
                  onChange={(event) => this.handleChange(event, "zipCode")}
                />
                <span className="form-field-error">
                  {this.state.errorData?.zipCode &&
                    this.state.errorData.zipCode[0]}
                </span>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label className="custom-label-product">
                  return shipment address
                  <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                      <p>Address for customer returns
                      <span class="arrow-down"></span>
                      </p>
                      </div>
                    </div>
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  maxLength="100"
                  value={this.state.return_shipment_address}
                  onChange={(event) => this.handleChange(event, "return_shipment_address")}
                  className="form-control"
                  placeholder="Return Shipment Address"
                />
                <span className="form-field-error">
                  {this.state.errorData?.return_shipment_address &&
                    this.state.errorData.return_shipment_address[0]}
                </span>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <label>address line 2</label>
                <input
                  type="text"
                  maxlength="50"
                  className="form-control"
                  placeholder="Address line 2"
                  value={this.state?.returnShipmentAddress_2}
                  onChange={(event) => this.handleChange(event, "returnShipmentAddress_2")}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  country
                  <sup>*</sup>
                </label>
                <Select
                  value={this.state.returnDefaultSelectedCountry}
                  name="value"
                  options={this.state.returnCountriesList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Country"
                  onChange={(event) => this.handleCountryChange(event, "return_shipment_country")}
                />
                <span className="form-field-error">
                  {this.state.errorData?.returnCountry &&
                    this.state.errorData.returnCountry[0]}
                </span>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  state
                  <sup>*</sup>
                </label>
                <Select
                  value={this.state.returnDefaultSelectedState}
                  name="value"
                  options={this.state.returnStatesList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="State"
                  onChange={(event) => this.handleStateChange(event, "return_shipment_state")}
                />
                <span className="form-field-error">
                  {this.state.errorData?.returnState && this.state.errorData.returnState[0]}
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  city
                  <sup>*</sup>
                </label>
                <Select
                  value={this.state.returnDefaultSelectedCity}
                  name="value"
                  options={this.state.returnCitiesList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="City"
                  onChange={(event) => this.handleCityChange(event, "return_shipment_city")}
                />
                <span className="form-field-error">
                  {this.state.errorData?.returnCity && this.state.errorData.returnCity[0]}
                </span>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  zip code
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  maxlength="6"
                  className="form-control"
                  placeholder="Zip Code"
                  value={this.state?.returnShipmentZipcode}
                  onChange={(event) => this.handleChange(event, "returnShipmentZipcode")}
                />
                <span className="form-field-error">
                  {this.state.errorData?.returnShipmentZipcode &&
                    this.state.errorData.returnShipmentZipcode[0]}
                </span>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label>
                  name of store
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  maxLength="50"
                  value={this.state.storename}
                  onChange={(event) => this.handleChange(event, "storename")}
                  className="form-control"
                  placeholder="Store Name"
                />
                <span className="form-field-error">
                  {this.state.errorData?.storename &&
                    this.state.errorData.storename[0]}
                </span>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label className="custom-label-product">
                 Store Overview {`(100 Characters)`}  
                 <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                      <p>Overview will be shown to the customers
                      <span class="arrow-down"></span>
                      </p>
                      </div>
                    </div>
                 <sup>*</sup>   
                </label>
                <input
                  type="text"
                  maxLength="100"
                  value={this.state.storeOverView}
                  onChange={(event) => this.handleChange(event, "storeOverView")}
                  className="form-control"
                  placeholder="Store Overview"
                />
                <span className="form-field-error">
                  {this.state.errorData?.storeOverView &&
                    this.state.errorData.storeOverView[0]}
                </span>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label className="custom-label-product">
                  email to use for contact form
                  <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                      <p>Used for customer outreach
                      <span class="arrow-down"></span>
                      </p>
                      </div>
                    </div>
                  <sup>*</sup>
                </label>
                <input
                  type="email"
                  maxLength="50"
                  value={this.state.storeEmail}
                  onChange={(event) => this.handleChange(event, "storeEmail")}
                  className="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.storeEmail &&
                    this.state.errorData.storeEmail[0]}
                </span>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <label>
                  Timezone
                  <sup>*</sup>
                </label>
                <Select
                  value={this.state.timezone}
                  name="value"
                  options={this.state.timezoneList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Timezone"
                  onChange={(event) =>
                    this.handleSelectChange(event, "timezone")
                  }
                />
                <span className="form-field-error">
                  {this.state.errorData?.timezone &&
                    this.state.errorData.timezone[0]}
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  From
                  <sup>*</sup>
                </label>
                <div>
                 {/* <input
                    type="time"
                    name="availableFrom"
                    min="00:00"
                    max="23:59"
                    value={this.state.availableFrom}
                    onChange={(event) => this.handleChange(event, "availableFrom")}
                    required
                  />*/}
                  <TimePicker
                      value={this.state.availableFrom ? this.state.availableFrom : null}
                      format="hh:mm a"
                      name="availableFrom"
                      disableClock={true}
                      clearIcon={null}
                      onChange={(event) => this.handleChange(event, "availableFrom")}
                      required={true}
                      className="form-control"
                      />
                      <span className="clock-icon"><i class="far fa-clock"></i></span>

                  
                  {/* <TimePicker
                    amPmAriaLabel={true}
                    onChange={(event) =>
                      this.handleSelectChange(event, "availableFrom")
                    }
                    value={this.state.availableFrom}
                  /> */}
                </div>
                <span className="form-field-error">
                  {this.state.errorData?.availableFrom &&
                    this.state.errorData.availableFrom[0]}
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  To
                  <sup>*</sup>
                </label>
                <div>
                  {/*<input
                    type="time"
                    name="availableTo"
                    min="00:00"
                    max="23:59"
                    value={this.state.availableTo}
                    onChange={(event) =>
                      this.handleChange(event, "availableTo")
                    }
                    required
                  />*/}
                  <TimePicker
                      value={this.state.availableTo? this.state.availableTo : null}
                      format="hh:mm a"
                      name="availableTo"
                      disableClock={true}
                      clearIcon={null}
                      onChange={(event) => this.handleChange(event, "availableTo")}
                      required={true}
                      className="form-control"
                      />
                      <span className="clock-icon"><i class="far fa-clock"></i></span>
                </div>
                <span className="form-field-error">
                  {this.state.errorData?.availableTo &&
                    this.state.errorData.availableTo[0]}
                </span>
              </div>
            </div>
              
            <div className="col-lg-12 bb1 bd-t mb-3 pt-3">
              <div className="form-group mb-0">
                <h5>Allow customers to contact</h5>
                <label>Chat Active?</label>
                <div className="custom-radio-input">
                  <p className="container-sub mr-2">
                    Inactive
                    <span>
                      <input
                        type="radio"
                        value="2"
                        onChange={(event) =>
                          this.handleChange(event, "isChatActive")
                        }
                        checked={this.state.isChatActive == 2}
                        name="isChatActiveRadio"
                      />
                    </span>
                    <span className="checkmark"></span>
                  </p>

                  <p className="container-sub mr-2">
                    Active
                    <span>
                      <input
                        type="radio"
                        value="1"
                        onChange={(event) =>
                          this.handleChange(event, "isChatActive")
                        }
                        checked={this.state.isChatActive == 1}
                        name="isChatActiveRadio"
                      />
                    </span>
                    <span className="checkmark"></span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label>your choose subscription plan</label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="subscription-box">
                      <div className="subscription-box-top">
                        <h3>Starter</h3>
                        <p>Free</p>
                        <div className="subscription-box-check">
                          <label className="custon-radio">
                            <input
                              checked
                              type="checkbox"
                              name="starter"
                              readOnly
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="subscription-box-bottom">
                        <div className="subscription-detail">
                          <ul>
                            <li>
                              <p>{this.state.fee_percent}% + Transaction Fee</p>
                            </li>
                          </ul>
                        </div>
                        <div className="subscription-view">
                          <a href={`${process.env.REACT_APP_VENDOR_URL}/subscription-packages`} target="_blank">
                          {/* <Link to={"/subscription-packages"}> */}
                            <button type="button" className="orange-outline-btn">
                              View
                            </button>
                          {/* </Link> */}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>                   
            <div className="col-md-12">
            <label className="custom-label-product label-form">
                  Enter Stripe Email
                  <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                      <p>Enter Stripe Email
                      <span class="arrow-down"></span>
                      </p>
                      </div>
                    </div>
                  <sup>*</sup>
                </label>
              {this.state.stripe_details_submitted !== 1 ? (
                !this.state.stripe_vendor_account_id ? (
                  <>
                  <form
                    onSubmit={this.handleConnectStripeAccount}
                    className="stripe-email"
                  >             
                    <input
                      type="text"
                      placeholder="Add stripe email"
                      value={this.state.stripe_vendor_email}
                      onChange={(event) =>
                        this.handleChange(event, "stripe_vendor_email")
                      }
                      className="form-control"
                    />
                    <span className="form-field-error custom-error mt-4">
                      {" "}
                      {this.state.errorData?.stripe_vendor_email &&
                        this.state.errorData?.stripe_vendor_email[0]}
                    </span>
                    <button
                      onClick={this.handleConnectStripeAccount}
                      className="orange-outline-btn"
                    >
                      Connect/ Create Stripe Account
                      {(() => {
                        if (this.state?.showLoaderForStripe) {
                          return (
                            <span className="spinner-border spinner-border-sm ml-1"></span>
                          );
                        }
                      })()}
                    </button>
                  </form>
                  {this.state?.stripeErrorMessage?.length ? <p className="form-field-error">
                      {" "}
                      {this.state.stripeErrorMessage}
                    </p> : null}
                    {
                      this.state.isDetailSubmitted ? 
                    <p style={{ fontSize: "14px", color: "#0000FF" }}>
                      {" "}
                      Your stripe account connected successfully
                    </p>
                    :
                    null
                    }
                    <p style={{ fontSize: "14px", color: "#E25E52" }}>
                      Please update your address and go to{" "}
                      <a href="https://stripe.com/" target="_blank">
                        {" "}
                        stripe.com{" "}
                      </a>{" "}
                      to add bank details to enable fund transfer
                    </p>
                  </>
                ) : (
                  // {this.state.errorData?.stripe_vendor_email[0]}
                  <div>
                    <div className="stripe-email mb-4">
                      <input
                        // disabled={true}
                        type="text"
                        placeholder="update your stripe account"
                        value={this.state.stripe_vendor_email}
                        onChange={(event) =>
                          this.handleChange(event, "stripe_vendor_email")
                        }
                      />
                      <span className="form-field-error custom-error mt-4">
                        {this.state.errorData?.stripe_vendor_email &&
                          this.state.errorData?.stripe_vendor_email[0]}
                      </span>
                      <button
                        onClick={this.handleConnectStripeAccount}
                        className="orange-outline-btn w-50"
                      >
                        Connect/ Create Stripe Account
                        {(() => {
                          if (this.state?.showLoaderForStripe) {
                            return (
                              <span className="spinner-border spinner-border-sm ml-1"></span>
                            );
                          }
                        })()}
                      </button>
                      <a href={process.env.REACT_APP_STRIPE_DOCUMENT_URL} download={process.env.REACT_APP_STRIPE_DOCUMENT_URL} target="_blank" className="ml-2 mt-1"> <span className="pdf-icon-tag"><i className="fas fa-file"></i></span></a>
                    </div>
                    {this.state?.stripeErrorMessage?.length ? <p className="form-field-error">
                      {" "}
                      {this.state.stripeErrorMessage}
                    </p> : null}
                    {
                      this.state.isDetailSubmitted ? 
                    <p style={{ fontSize: "14px", color: "#0000FF" }}>
                      {" "}
                      Your stripe account connected successfully
                    </p>
                    :
                    null
                    }
                    <p style={{ fontSize: "14px", color: "#E25E52" }}>
                      Please update your address and go to{" "}
                      <a href="https://stripe.com/" target="_blank">
                        {" "}
                        stripe.com{" "}
                      </a>{" "}
                      to add bank details to enable fund transfer
                    </p>
                  </div>
                )
              ) : (
                <div>
                  <div className="stripe-email mb-4">
                    <input
                      type="text"
                      placeholder="update your stripe account"
                      value={this.state.stripe_vendor_email}
                      onChange={(event) =>
                        this.handleChange(event, "stripe_vendor_email")
                      }
                    />
                    <span className="form-field-error custom-error mt-4">
                      {this.state.errorData?.stripe_vendor_email &&
                        this.state.errorData?.stripe_vendor_email[0]}
                    </span>
                    <button
                      onClick={(e) => this.handleConnectStripeAccount(e, true)}
                      className="orange-outline-btn w-50"
                    >
                      Connect/ Create Stripe Account
                      {(() => {
                        if (this.state?.showLoaderForStripe) {
                          return (
                            <span className="spinner-border spinner-border-sm ml-1"></span>
                          );
                        }
                      })()}
                    </button>
                  </div>
                  {this.state?.stripeErrorMessage?.length ? <p className="form-field-error">
                      {" "}
                      {this.state.stripeErrorMessage}
                    </p> : null}
                    {
                      this.state.isDetailSubmitted ? 
                  <p style={{ fontSize: "14px", color: "#0000FF" }}>
                    {" "}
                    Your stripe account connected successfully
                  </p>
                  :
                  null
                    }
                </div>
              )}
              {this.state.stripeUrl && (
                <>
                  <a href={this.state.stripeUrl} target="_blank">
                    Click here to Setup stripe address
                    <span className="ml-2">
                      <i className="fas fa-external-link-alt"></i>
                    </span>
                  </a>
                </>
              )}
            </div>

            {/*<div className="col-lg-12 col-md-12">
              {!this.state.zoom_refresh_token ? (
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <button
                          onClick={this.handleConnectZoomAccount}
                          className="orange-outline-btn"
                      >
                        Connect Zoom Account
                      </button>
                      {this.state.zoomUrl && (
                          <label>
                            <a href={this.state.zoomUrl}>
                              Click here connect zoom account
                              <span className="ml-2">
                                  <i className="fas fa-external-link-alt"></i>
                                </span>
                            </a>
                          </label>
                      )}
                    </div>
                  </div>
              ) : (
                  <div>
                    <p style={{ fontSize: "14px", color: "#0000FF" }}>
                      Your zoom account connected successfully
                    </p>
                  </div>
              )}
              <div className="col-lg-12 col-md-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label> Zoom Api Key </label>
                      <input
                          type="text"
                          className="form-control"
                          placeholder="Zoom Api Key"
                          value={this.state?.zoomApiKey}
                          onChange={(event) => this.handleChange(event, "zoomApiKey")}
                      />
                      <span className="form-field-error">
                      {this.state.errorData?.zoomApiKey &&
                      this.state.errorData.zoomApiKey[0]}
                    </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label> Zoom Api Secret </label>
                      <input
                          type="text"
                          className="form-control"
                          placeholder="Zoom Api Secret"
                          value={this.state?.zoomApiSecret}
                          onChange={(event) => this.handleChange(event, "zoomApiSecret")}
                      />
                      <span className="form-field-error">
                      {this.state.errorData?.zoomApiSecret &&
                      this.state.errorData.zoomApiSecret[0]}
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}

            <div className="col-lg-12 col-md-12">
              <div className="form-btn text-center">
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  className="orange-outline-btn"
                >
                  save
                  {(() => {
                    if (this.state?.showLoader) {
                      return (
                        <span className="spinner-border spinner-border-sm ml-1"></span>
                      );
                    }
                  })()}
                </button>
                <div>
                  <p style={{ color: "red" }}>
                    {this.state.bottomErrorMessage}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
              </div>
              <div className="col-lg-4 col-md-5 order-md-2 order-1">
              <div className="profile-picture custom-profie-picture">
                <div className="profile-picture-head">
                  <h3>Profile Picture</h3>
                </div>
                <div className="user-profile-img">
                  {croppedImageUrl ? (
                    <img alt="Crop" src={croppedImageUrl} className="w-100" />
                    ) : (
                      <img
                      src={
                        this.state?.imagename && this.state?.imagename !== "undefined"
                        ? this.state.imagename
                        : profileAvtar
                      }
                      className="w-100"
                      />
                      )}
                </div>
                <div className="upload-btn">
                  <button onClick={this.triggerClick} type="button">
                    upload image
                  </button>  
                </div>
                <div
                  style={{ display: src ? "block" : "none" }}
                  className="profile-picture"
                >
                  <div>
                    <input
                      ref={this.fileUpload}
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={this.onSelectFile}
                    />
                  </div>
                  {src && (
                    <ReactCrop
                      src={src}
                      crop={crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  )}
                </div>
                <span className="form-field-error">
                  {this.state.errorData?.productImage &&
                    this.state.errorData.productImage[0]}
                     {this.state.profileNote}
                </span>
              </div>
            </div>
            </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authData: state.userReducer.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateProfile,
      getCountriesList,
      getStatesList,
      getCitiesList,
      getCitiesListByStateCode,
      connectStripeAccount,
      checkStripeDetailsCompleted,
      installZoomApp,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
