import React from 'react';
import '../../Styles/subscription.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSubscriptionPackages } from '../../Redux/Actions/subscriptionAction';

class Subscription_Packages extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            packages: []
        };
    }

    componentDidMount() {
        this.fetchSubscriptionPackages();
    }

    fetchSubscriptionPackages = async () => {
        const result = await this.props.getSubscriptionPackages();
        if (result ?.data ?.data ?.length > 0) {
            this.setState({
                packages: result.data.data
            })
        }
    }



    render() {

        const packageList = this.state.packages.map((i) => {
            const options = i.options ? JSON.parse(i.options) : [];
            return (
                <div key={i.id} className="col-lg-4 col-md-4">
                    <div className="subscription-box">
                        <div className="subscription-box-head">
                            <h4>{i.name}</h4>
                            <p>{i.fee_percent}% + Transaction Fee</p>
                            <h2>${i.amount}
                                <span>/Month</span>
                            </h2>
                        </div>
                        <div className="subscription-box-mid">
                            <ul>
                                {options.map((option, index) => {
                                    return (
                                        <li key={index}>
                                            <p>{option}</p>
                                        </li>
                                    )
                                })}
                                {/* <li>
                                    <p>Publish on Marketplace</p>
                                </li>
                                <li>
                                    <p>Access Customers</p>
                                </li>
                                <li>
                                    <p>Easy to set up and user-friendly</p>
                                </li>
                                <li>
                                    <p>Back end Support</p>
                                </li>
                                <li>
                                    <i className="fas fa-ellipsis-h"></i>
                                </li> */}
                            </ul>
                        </div>
                        <div className="subscription-box-bottom">
                            <button type="button" className="orange-outline-btn">get {i.name}</button>
                            {/* <p>$199 Set Up Cost</p> */}
                        </div>
                    </div>
                </div>
            )
        })

        return (
            <div className="admin-card">
                <div className="admin-card-head">
                    <h2>subscription packages</h2>
                </div>
                <div className="admin-card-box">
                    <div className="subscription">
                        <div className="subscription-heading">
                            <h2>Start up your Bussiness today</h2>
                            <p>Choose a plan that suits you the best.</p>
                        </div>

                        <div className="subscription-package">
                            <div className="row">

                                {packageList}

                                 {/* <div className="col-lg-4 col-md-4">
                                    <div className="subscription-box">
                                        <div className="subscription-box-head">
                                            <h4>Starter</h4>
                                            <p>12.25% + Transaction Fee</p>
                                            <h2>FREE</h2>
                                        </div>
                                        <div className="subscription-box-mid">
                                            <ul>
                                                <li>
                                                    <p>Publish on Marketplace</p>
                                                </li>
                                                <li>
                                                    <p>Access Customers</p>
                                                </li>
                                                <li>
                                                    <p>Easy to set up and user-friendly</p>
                                                </li>
                                                <li>
                                                    <p>Back end Support</p>
                                                </li>
                                                <li>
                                                    <i className="fas fa-ellipsis-h"></i>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="subscription-box-bottom">
                                            <button type="button" className="orange-outline-btn">get starter</button>
                                            <p>$199 Set Up Cost</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="subscription-box">
                                        <div className="subscription-box-head">
                                            <h4>Standard</h4>
                                            <p>10% + Transaction Fee</p>
                                            <h2>$49
                                            <span>/Month</span>
                                            </h2>
                                        </div>
                                        <div className="subscription-box-mid">
                                            <ul>
                                                <li>
                                                    <p>Featured on New Arrivals Page</p>
                                                </li>
                                                <li>
                                                    <p>Access to Product Analytics</p>
                                                </li>
                                                <li>
                                                    <p>Marketing Options</p>
                                                </li>
                                                <li>
                                                    <p>Dedicated Vendor Support</p>
                                                </li>
                                                <li>
                                                    <i className="fas fa-ellipsis-h"></i>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="subscription-box-bottom">
                                            <button type="button" className="orange-outline-btn">get standard</button>
                                            <p>$199 Set Up Cost</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="subscription-box">
                                        <div className="subscription-box-head">
                                            <h4>premium</h4>
                                            <p>8% + Transaction Fee</p>
                                            <h2>$149
                                            <span>/Month</span>
                                            </h2>
                                        </div>
                                        <div className="subscription-box-mid">
                                            <ul>
                                                <li>
                                                    <p>Advanced & Customizable Marketing Options</p>
                                                </li>
                                                <li>
                                                    <p>Subscription Industry Trends</p>
                                                </li>
                                                <li>
                                                    <p> Storefront Customization</p>
                                                </li>
                                                <li>
                                                    <p>Dedicated Vendor Support</p>
                                                </li>
                                                <li>
                                                    <p>Vendor Success Manager</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="subscription-box-bottom">
                                            <button type="button" className="orange-outline-btn">get premium</button>
                                            <p>$199 Set Up Cost</p>
                                        </div>
                                    </div>
                                </div> */}
                                
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getSubscriptionPackages }, dispatch);
}

export default connect(null, mapDispatchToProps)(Subscription_Packages);
