import React from "react";
import PrivateGuard from "./privateGuard";
import { Switch, Redirect, Route, Router } from "react-router-dom";
import Sidebar from "../Containers/Sidebar/Sidebar";
import Header from "../Components/Header/Header";
import Dashboard from "../Containers/Dashboard/Dashboard";
import Order_List from "../Containers/Order-List/Order_List";
import Order_Detail from "../Containers/Order-Detail/Order_Detail";
import Order_Track from "../Containers/Order-Track/Order_Track";

import Add_Product from "../Containers/Add-Product/Add_Product";
import Product_List from "../Containers/Product-List/Product_List";
import Profile from "../Containers/Profile/Profile";
import Change_Password from "../Containers/Change-Password/Change_Password";
import Subscription_Packages from "../Containers/Subscription-Packages/Subscription_Packages";

import Login from "../Containers/login/Login";
import SignUp from "../Containers/SignUp/signup";
import ForgotPassword from "../Containers/ForgotPassword/ForgotPassword";
import ResetPassword from "../Containers/ResetPassword/ResetPassword";
import Attribute_List from "../Containers/Attribute_List/Attribute_List";
import Add_Attribute from "../Containers/Add-Attribute/Add_Attribute";
import StripeConnect from "../Containers/StripeConnect/StripeConnectAccount";
import PaymentList from "../Containers/PaymentList/PaymentList";
import Shopify from "../Containers/Shopify/Shopify_Account";
import Woocommerce from "../Containers/WooCommerce/woocommerce";
import Messages from "../Containers/Messages/messages";

import Zoom from "../Containers/ZoomMeeting/Zoom";
import ZoomMeeting from "../Containers/ZoomMeeting/zoomMeeting";
import JoinZoomMeeting from "../Containers/ZoomMeeting/joinZoomMeeting";
import Calendar from "../Containers/Add-Product/Calendar";
import MeetingList from "../Containers/MeetingList/MeetingList";
import GlobalCalendar from "../Containers/GlobalCalendar/GlobalCalendar";
import Page from "../Containers/Page/page";
import DocumentList from "../Containers/Document_List/DocumentList";
import Dispute_List from "../Containers/Dispute-List/Dispute_List";
import Dispute_Detail from "../Containers/Dispute-Detail/Dispute_Detail";
import Review_List from "../Containers/Review-List/Review_List";
import Review_Detail from "../Containers/Review-Detail/Review_Detail";
import OneOnOneMeetings from "../Containers/OneOnOneMeetings/OneOnOneMeetings";
import OneOnOneMeetingsDetail from "../Containers/OneOnOneMeetings/OneOnOneMeetingDetail";
import Order_return_request from "../Containers/Order-return-request/Order_return_request";
// import Dispute_Edit from "../Containers/Dispute-Edit/Dispute_Edit";

class PrivateRoutes extends React.Component {
  render() {
    return (
      <div>
        <div className="PrivateArea__content">
          <div className="main_sec">
            {/* admin routes */}
            <Switch>
              <Route
                path="/"
                component={() => <Redirect to="/login" />}
                exact
              />
              <Route path="/page/:slug" component={Page} exact />

              <PrivateGuard path="/login" component={Login} exact />

              <PrivateGuard path="/signup" component={SignUp} exact />

              <PrivateGuard path="/dashboard" component={Container} exact />

              <PrivateGuard path="/messages" component={Container} exact />

              <PrivateGuard path="/orders" component={Container} exact />
              <PrivateGuard path="/disputes" component={Container} exact />
              <PrivateGuard
                path="/one-on-one-meeting-list"
                component={Container}
                exact
              />
              <PrivateGuard
                path="/one-on-one-meetings/:meetingId"
                component={Container}
                exact
              />
              <PrivateGuard path="/reviews" component={Container} exact />
              <PrivateGuard path="/payments" component={Container} exact />

              <PrivateGuard
                path="/orders/:orderId"
                component={Container}
                exact
              />
              <PrivateGuard
                path="/dispute/:disputeId"
                component={Container}
                exact
              />
              <PrivateGuard
                path="/review/:orderId"
                component={Container}
                exact
              />
              <PrivateGuard
                path="/reviews/product/:productId"
                component={Container}
                exact
              />

              <PrivateGuard
                path="/orders/track/:trackId"
                component={Container}
                exact
              />

              <PrivateGuard path="/add-product" component={Container} exact />

              <PrivateGuard
                path="/edit-product/:slug"
                component={Container}
                exact
              />

              <PrivateGuard path="/zoom" component={Container} exact />
              <PrivateGuard path="/zoom-meeting" component={Container} exact />
              <PrivateGuard
                path="/zoom-meeting/join"
                component={Container}
                exact
              />

              <PrivateGuard path="/meeting-list" component={Container} exact />

              <PrivateGuard
                path="/global-calendar"
                component={Container}
                exact
              />

              <PrivateGuard path="/product-list" component={Container} exact />
              <PrivateGuard
                path="/attribute-list"
                component={Container}
                exact
              />
              <PrivateGuard path="/add-attribute" component={Container} exact />
              <PrivateGuard
                path="/vendor/store-sy"
                component={Container}
                exact
              />
              <PrivateGuard
                path="/vendor/woocommerce"
                component={Container}
                exact
              />

              <PrivateGuard
                path="/edit-attribute/:attributeId"
                component={Container}
                exact
              />

              <PrivateGuard path="/profile" component={Container} exact />

              <PrivateGuard
                path="/change-password"
                component={Container}
                exact
              />
              <PrivateGuard
                path="/stripe-connect"
                component={Container}
                exact
              />

              <Route path="/document-list" component={DocumentList} exact />

              <PrivateGuard
                path="/subscription-packages"
                component={Container}
                exact
              />

              <PrivateGuard path="/calender" component={Container} exact />
              <PrivateGuard path="/order-return-request" component={Container} exact />

              <PrivateGuard
                path="/forgot-password"
                component={ForgotPassword}
                exact
              />

              <PrivateGuard
                path="/reset-password"
                component={ResetPassword}
                exact
              />

              {/* wildcard route */}
              <Route
                path="*"
                component={() => <Redirect to="/login" />}
                exact
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const Container = ({ match }) => {
  return (
    <div className="main-body">
      <Sidebar />
      <div className="main-content">
        <div className="container-fluid">
          <Header />
          <Switch>
            <Route path={"/dashboard"} component={Dashboard} exact />
            <Route path={"/messages"} component={Messages} exact />

            <Route path={"/orders"} component={Order_List} exact />
            <Route path={"/disputes"} component={Dispute_List} exact />
            <Route
              path={"/one-on-one-meeting-list"}
              component={OneOnOneMeetings}
              exact
            />
            <Route
              path={"/order-return-request"}
              component={Order_return_request}
              exact
            />
            <Route
              path={"/one-on-one-meetings/:meetingId"}
              component={OneOnOneMeetingsDetail}
              exact
            />
            <Route path={"/reviews"} component={Review_List} exact />
            {/* <Route path={"/disputes/"} component={Dispute_List} exact /> */}

            <Route path={"/global-calendar"} component={GlobalCalendar} exact />
            <Route path="/page/:slug" component={Page} exact />

            <Route path="/orders/:orderId" component={Order_Detail} exact />
            <Route
              path="/dispute/:disputeId"
              component={Dispute_Detail}
              exact
            />
            <Route path="/review/:orderId" component={Review_Detail} exact />
            <Route
              path="/reviews/product/:productId"
              component={Review_List}
              exact
            />

            <Route
              path="/orders/track/:trackId"
              component={Order_Track}
              exact
            />
            <Route path={"/add-product"} component={Add_Product} exact />

            <Route path="/edit-product/:slug" component={Add_Product} exact />

            <Route path={"/zoom"} component={Zoom} exact />
            <Route path={"/zoom-meeting"} component={ZoomMeeting} exact />
            <Route
              path="/zoom-meeting/join"
              component={JoinZoomMeeting}
              exact
            />

            <Route path={"/product-list"} component={Product_List} exact />

            <Route path={"/profile"} component={Profile} exact />
            <Route path={"/calender"} component={Calendar} exact />

            <Route
              path={"/change-password"}
              component={Change_Password}
              exact
            />

            <Route
              path={"/subscription-packages"}
              component={Subscription_Packages}
              exact
            />
            <Route path={"/attribute-list"} component={Attribute_List} exact />
            <Route path={"/add-attribute"} component={Add_Attribute} exact />

            <Route
              path={"/edit-attribute/:attributeId"}
              component={Add_Attribute}
              exact
            />
            <Route path={"/vendor/store-sy"} component={Shopify} exact />
            <Route path="/vendor/woocommerce" component={Woocommerce} exact />
            <Route path={"/payments"} component={PaymentList} exact />
            <Route path={"/stripe-connect"} component={StripeConnect} exact />
            <Route path="/meeting-list" component={MeetingList} exact />
          </Switch>
          <div className="main-footer">
            <p>© {new Date().getFullYear()} Subsciety. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateRoutes;
