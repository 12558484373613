import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const createOneOnOneMeeting = (body) => async (dispatch) => {
  const { productId, userId } = body;
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/one-on-one-meeting/create-one-on-one-meeting/${userId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: body,
    });

    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getOneOnOneMeetingList =
  (sellerId, offset, limit = 10) =>
  async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/seller/one-on-one-meeting/get-all/${sellerId}?offset=${offset}&limit=${limit}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const approveOneOnOneMeetingRequest =
  (id, meetingStatus, email, meetingId, meetingType) => async (dispatch) => {
    try {
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/seller/one-on-one-meeting/approve-meeting/${id}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: { meetingStatus,  email,meetingId, meetingType },
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getDisputeDetail = (disputeId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/dispute/dispute/${disputeId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "ORDER_STATUS", payload: res.data?.data?.status });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const searchOneOnOneMeeting =
  (sellerId, offset, searchKeyword) => async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/seller/one-on-one-meeting/by/search/${sellerId}?offset=${offset}&search=${searchKeyword}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const oneOnOneMeetingDetail =
  (meetingId, timezone) => async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/seller/one-on-one-meeting/${meetingId}?timezone=${timezone}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

// export const withdrawOneOnOneMeeting = (id) => async (dispatch) => {
//   try {
//     const res = await axios({
//       method: "post",
//       url: `${URL}/api/v1/seller/one-on-one-meetings/withdraw/${id}`,
//       headers: {
//         Authorization: "Bearer " + localStorage.getItem("token"),
//       },
//     });
//     return res;
//   } catch (error) {
//     dispatch(handleError(error));
//   }
// };
//   -----------------------------------------------------------------------------------------------

export const requestRefund = (disputeId, body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/dispute/request-refund/${disputeId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: body,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const createDisputeMessage = (disputeId, body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/dispute/create-message/${disputeId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: body,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getDisputeConversationById =
  (disputeId, offset) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/seller/dispute/get-dispute-conversation/${disputeId}?offset=${offset}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getDisputeDetailByOrderId = (orderId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/dispute/get-dispute/${orderId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const openDispute = (disputeId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/dispute/open/${disputeId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getOneOnOneMeetingCountBySellerId =
  (sellerId) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/seller/one-on-one-meeting/${sellerId}/count`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch({
        type: "UNREAD_0NE_ON_ONE_MEETING_COUNT",
        payload: { data: res.data },
      });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const reportToSubsciety = (disputeId, role) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "patch",
      url: `${URL}/api/v1/dispute/report-to-subsciety/${disputeId}/${role}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};
