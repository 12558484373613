import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getOrdersList = (sellerId, offset, limit = 10) => async (
  dispatch
) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/order/list/${sellerId}?offset=${offset}&limit=${limit}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};


export const searchOrder = (sellerId, offset, searchKeyword) => async (
  dispatch
) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/order/by/search/${sellerId}?offset=${offset}&search=${searchKeyword}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

// export const getOrderDetail = (userId,orderId) => async (dispatch) => {
//   try {
//     const res = await axios({
//       method: "get",
//       url: `${URL}/api/v1/seller/order/${userId}/${orderId}`,
//       headers: {
//         Authorization: "Bearer " + localStorage.getItem("token"),
//       },
//     });
//     dispatch({ type: "ORDER_STATUS", payload: res.data?.data?.status });
//     return res;
//   } catch (error) {
//     dispatch(handleError(error));
//   }
// };
export const getOrderDetail = (orderId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/order/${orderId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "ORDER_STATUS", payload: res.data?.data?.status });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};


export const updateOrderDetail = (orderDetail) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/order/update-order`,
      data: orderDetail,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateOrderStatusByTrackingId = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/order/updateOrderStatusByTrackingId`,
      data: {orderId},
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getOrderTracking = (trackingId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/fedex/gettracks/${trackingId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};


export const refundOrderReturnsByOrderId = (orderId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/order/refund-order-returns/${orderId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};
