import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./zoom.scss";

import { generateToken } from "../../Redux/Actions/zoomAction";

const JoinZoomMeeting = (props) => {
    const dispatch = useDispatch();

    const leaveUrl = window.location.origin + "/edit-product/" + props.location.state?.productSlug;

    useEffect(() => {
        fetchInitialData()
    }, []);

    const fetchInitialData = async () => {
        const res = await dispatch(generateToken({
            productId: props.location.state?.productId
        }));
        if (res && res.data){
            const { ZoomMtg } = await import(`@zoomus/websdk`);
            ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.5/lib', '/av');
            ZoomMtg.preLoadWasm();
            ZoomMtg.prepareJssdk();

            setTimeout(()=>{
                ZoomMtg.init({
                    leaveUrl: leaveUrl,
                    isSupportAV: true,
                    success: (success) => {
                        ZoomMtg.join({
                            signature: res.data.signature,
                            meetingNumber: res.data.meetingId,
                            userName: res.data.userName,
                            apiKey: res.data.apiKey,
                            userEmail: res.data.userEmail,
                            passWord: res.data.passWord,
                            success: (success) => {
                            },
                            error: (error) => {
                                console.error(error)
                            }
                        })
                    },
                    error: (error) => {
                        console.error(error)
                    }
                })
            }, 1000)
        } else{
            props.history.push(leaveUrl)
        }
    };

    return (
        <div className="admin-card">
            <div className="admin-card-head">
                <h2 style={{textTransform:"capitalize"}}>Join Zoom Meeting</h2>
            </div>
            <div className="admin-card-box">
                <div id="zmmtg-root" className="zoom-wrapper"/>
            </div>
        </div>
    );
};

export default JoinZoomMeeting;