import React, { Component } from "react";
import logo from "../../assets/images-2/logo.png";
import "../../Styles/sidebar.scss";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleSidebar } from "../../Redux/Actions/commonAction";
import { getMessageCountBySellerId } from "../../Redux/Actions/messageAction";
import { getOrderReturnRequestCountBySellerId } from "../../Redux/Actions/messageAction";
import { getDisputeCountBySellerId } from "../../Redux/Actions/disputeAction";
import { getReviewCountBySellerId } from "../../Redux/Actions/reviewAction";
import { getOneOnOneMeetingCountBySellerId } from "../../Redux/Actions/oneOnOneMeetingAction";
class Sidebar extends Component {
  sellerId = localStorage.getItem("sellerId");
  constructor(props) {
    super(props);
    this.state = {
      isShowSidebar: false,
    };
  }

  componentDidMount() {
    this.props.getMessageCountBySellerId(this.sellerId);
    this.props.getDisputeCountBySellerId(this.sellerId);
    this.props.getReviewCountBySellerId(this.sellerId);
    this.props.getOneOnOneMeetingCountBySellerId(this.sellerId);
    this.props.getOrderReturnRequestCountBySellerId(this.sellerId);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isShowSidebar !== this.props.isShowSidebar) {
      this.setState({
        isShowSidebar: nextProps.isShowSidebar,
      });
    }
  }

  render() {
    console.log(
      "this.props.unreadDisputeCount ",
      this.props.unreadDisputeCount
    );
    const countMessageCount = () => {
      this.props.getMessageCountBySellerId(this.sellerId);
      this.props.getDisputeCountBySellerId(this.sellerId);
      this.props.getOneOnOneMeetingCountBySellerId(this.sellerId);
      this.props.getReviewCountBySellerId(this.sellerId);
      this.props.getOrderReturnRequestCountBySellerId(this.sellerId);
    };
    return (
      <div
        className={
          this.props.isShowSidebar ? "sidebar sidebar-show" : "sidebar"
        }
      >
        <div className="sidebar-head">
          <img src={logo} />
          <div className="toggle-close-btn">
            <button onClick={() => this.props.toggleSidebar()} type="button">
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul>
            <li>
              <Link
                onClick={countMessageCount}
                to={"/dashboard"}
                className={
                  this.props.location.pathname === "/dashboard"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                >
                  <g>
                    <path
                      d="M13.45,5.01c-0.11-0.1-0.15-0.21-0.14-0.35c0.01-0.85,0.01-1.7,0-2.55c0-0.4-0.17-0.72-0.55-0.88
		c-0.35-0.15-0.68-0.1-0.98,0.16c-0.22,0.19-0.31,0.45-0.31,0.74c0,0.29,0,0.58,0,0.9c-0.07-0.06-0.11-0.1-0.15-0.14
		C10.8,2.35,10.27,1.82,9.74,1.3c-1-1-2.49-1-3.49,0c-1.97,1.97-3.93,3.93-5.9,5.9C0.29,7.26,0.23,7.33,0.18,7.4
		c-0.32,0.43-0.19,1.06,0.28,1.33c0.4,0.22,0.81,0.15,1.17-0.21c1.97-1.97,3.93-3.93,5.9-5.9c0.33-0.33,0.61-0.33,0.94,0
		c1.96,1.96,3.91,3.91,5.87,5.87c0.06,0.06,0.12,0.12,0.18,0.17c0.3,0.23,0.63,0.26,0.97,0.1C15.77,8.62,15.91,8.37,16,8.1
		c0-0.1,0-0.21,0-0.31c-0.07-0.29-0.26-0.5-0.47-0.7C14.83,6.39,14.15,5.7,13.45,5.01z"
                    />
                    <path
                      d="M8.39,4.33c-0.27-0.27-0.5-0.27-0.78,0C5.88,6.06,4.15,7.79,2.41,9.52c-0.14,0.14-0.2,0.29-0.2,0.49c0,1.24,0,2.48,0,3.72
		c0,0.1,0,0.2,0.02,0.3c0.14,0.86,0.8,1.42,1.68,1.43c0.82,0,1.64,0,2.45,0c0.04,0,0.08,0,0.14-0.01c0-1.36,0-2.71,0-4.07
		c1,0,1.98,0,2.98,0c0,1.36,0,2.7,0,4.07c0.06,0,0.09,0.01,0.13,0.01c0.83,0,1.66,0,2.48,0c0.1,0,0.21,0,0.31-0.02
		c0.79-0.14,1.36-0.82,1.37-1.63c0.01-1.27,0-2.53,0-3.8c0-0.2-0.07-0.35-0.2-0.48C11.85,7.79,10.12,6.06,8.39,4.33z"
                    />
                  </g>
                </svg>
                dashboard
              </Link>
            </li>
            <li>
              <Link
                onClick={countMessageCount}
                to={"/global-calendar"}
                className={
                  this.props.location.pathname === "/global-calendar"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 17 19"
                >
                  <desc>Created with Sketch.</desc>
                  <g>
                    <path
                      d="M14,3v0.5C14,3.8,13.8,4,13.5,4S13,3.8,13,3.5V3H9v0.5C9,3.8,8.8,4,8.5,4S8,3.8,8,3.5V3H4v0.5C4,3.8,3.8,4,3.5,4
		S3,3.8,3,3.5V3H1.5C1.2,3,1,3.2,1,3.5V5h15V3.5C16,3.2,15.8,3,15.5,3H14z M14,2h1.5C16.3,2,17,2.7,17,3.5v14c0,0.8-0.7,1.5-1.5,1.5
		h-14C0.7,19,0,18.3,0,17.5v-14C0,2.7,0.7,2,1.5,2H3V0.5C3,0.2,3.2,0,3.5,0S4,0.2,4,0.5V2h4V0.5C8,0.2,8.2,0,8.5,0S9,0.2,9,0.5V2h4
		V0.5C13,0.2,13.2,0,13.5,0S14,0.2,14,0.5V2z M16,6H1v11.5C1,17.8,1.2,18,1.5,18h14c0.3,0,0.5-0.2,0.5-0.5V6z M12,12h-1.4
		c0,1-0.2,1.9-0.5,2.6C11.1,14.1,11.8,13.2,12,12L12,12z M12,11c-0.2-1.2-0.9-2.1-1.9-2.6c0.3,0.7,0.5,1.6,0.5,2.6H12L12,11z M5,12
		c0.2,1.2,0.9,2.1,1.9,2.6C6.6,13.9,6.5,13,6.4,12H5L5,12z M5,11h1.4c0-1,0.2-1.9,0.5-2.6C5.9,8.9,5.2,9.8,5,11L5,11z M9.6,12H7.4
		c0.1,1.7,0.6,3,1.1,3S9.5,13.7,9.6,12z M7.4,11h2.2C9.5,9.3,8.9,8,8.5,8S7.5,9.3,7.4,11z M13,11.5C13,14,11,16,8.5,16S4,14,4,11.5
		S6,7,8.5,7S13,9,13,11.5z"
                    />
                  </g>
                </svg>
                global calendar
              </Link>
            </li>
            <li>
              <Link
                onClick={countMessageCount}
                to={"/messages"}
                className={
                  this.props.location.pathname === "/messages"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M15.88,2.18c-0.33-0.98-1.18-1.57-2.27-1.57c-3.74,0-7.48,0-11.22,0c-0.16,0-0.31,0.01-0.47,0.03
	C0.78,0.85,0.01,1.77,0,2.93c0,1.55,0,3.1,0,4.65c0,1,0,2,0,3c0,1.01,0.6,1.86,1.55,2.18c0.27,0.09,0.57,0.11,0.86,0.13
	c0.25,0.02,0.51,0,0.78,0c0,0.08,0,0.13,0,0.19c0,0.58,0,1.16,0.01,1.73c0,0.12,0.03,0.26,0.1,0.37c0.16,0.26,0.48,0.27,0.76,0.05
	c0.96-0.74,1.92-1.48,2.88-2.22c0.11-0.08,0.21-0.11,0.34-0.11c2.11,0,4.22,0,6.33,0c1.01,0,1.75-0.44,2.19-1.35
	c0.1-0.21,0.14-0.44,0.21-0.66c0-2.75,0-5.5,0-8.25C15.96,2.48,15.93,2.33,15.88,2.18z M13.61,11.91c-2.17,0-4.33,0-6.5,0
	c-0.24,0-0.44,0.06-0.63,0.21c-0.72,0.57-1.45,1.12-2.17,1.68c-0.04,0.03-0.08,0.06-0.15,0.11c0-0.34,0-0.65,0-0.96
	c0-0.16,0-0.32,0-0.48c0-0.35-0.2-0.55-0.56-0.55c-0.43,0-0.86,0-1.3,0c-0.64-0.01-1.11-0.38-1.28-0.99
	c-0.04-0.13-0.05-0.28-0.05-0.42c0-2.5,0-5,0-7.5c0-0.87,0.55-1.41,1.41-1.41c3.74,0,7.49,0,11.23,0c0.7,0,1.18,0.35,1.36,0.99
	c0.04,0.13,0.05,0.28,0.05,0.42c0,2.5,0,5,0,7.5C15.02,11.37,14.48,11.91,13.61,11.91z"
                  />
                </svg>
                Messages
                {this.props.unreadMessageCount > 0 && (
                  <span
                    className="badge badge-dark"
                    style={{ marginLeft: "10px" }}
                  >
                    {this.props.unreadMessageCount}
                  </span>
                )}
              </Link>
            </li>
            <li>
              <Link
                onClick={countMessageCount}
                to={"/orders"}
                className={
                  this.props.location.pathname === "/orders" ||
                  this.props.match.path == "/orders/:orderId" ||
                  this.props.match.path == "/orders/track/:trackId"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M15.37,2.84c-3.7,0.01-7.4,0-11.09,0c-0.07,0-0.13,0-0.2,0C3.97,2.35,3.86,1.88,3.75,1.4C3.69,1.12,3.53,0.97,3.27,0.97
	c-0.94-0.01-1.87,0-2.81,0c-0.2,0-0.35,0.11-0.42,0.29C-0.09,1.58,0.14,1.9,0.5,1.91c0.55,0,1.09,0,1.64,0c0.25,0,0.51,0,0.77,0
	C3.47,4.45,4.03,6.98,4.6,9.52C4.55,9.54,4.52,9.56,4.48,9.58c-0.59,0.32-0.86,1-0.67,1.64c0.19,0.6,0.72,0.99,1.38,0.99
	c0.22,0,0.45,0,0.67,0c-0.73,0.17-1.23,0.79-1.18,1.47c0.06,0.76,0.62,1.31,1.37,1.34c0.66,0.03,1.3-0.49,1.41-1.14
	c0.06-0.39,0-0.75-0.23-1.07c-0.22-0.32-0.54-0.51-0.93-0.6c2.06,0,4.12,0,6.21,0c-0.88,0.18-1.27,0.88-1.21,1.5
	c0.07,0.76,0.66,1.29,1.39,1.3c0.67,0.01,1.3-0.52,1.39-1.16c0.11-0.81-0.32-1.42-1.2-1.65c0.24,0,0.43,0,0.63,0
	c0.37,0,0.59-0.17,0.59-0.47c0-0.3-0.22-0.47-0.59-0.47c-2.75,0-5.5,0-8.25,0c-0.37,0-0.59-0.17-0.59-0.47
	c0-0.3,0.22-0.47,0.59-0.47c2.75,0,5.51,0,8.26,0c0.35,0,0.49-0.11,0.59-0.44c0.51-1.77,1.01-3.55,1.52-5.32
	C15.77,4.2,15.89,3.82,16,3.44c0-0.08,0-0.17,0-0.25C15.88,2.92,15.66,2.84,15.37,2.84z M13.19,13.62c0,0.25-0.21,0.47-0.46,0.47
	c-0.25,0-0.47-0.21-0.47-0.46c0-0.25,0.21-0.47,0.46-0.47C12.97,13.16,13.18,13.37,13.19,13.62z M6.56,13.62
	c0,0.25-0.21,0.47-0.47,0.47c-0.25,0-0.47-0.21-0.47-0.47c0-0.25,0.21-0.47,0.47-0.47C6.35,13.16,6.56,13.37,6.56,13.62z M13.31,9.4
	c-2.59,0-5.17,0-7.77,0C5.12,7.54,4.7,5.67,4.28,3.79c3.54,0,7.07,0,10.62,0C14.37,5.67,13.84,7.53,13.31,9.4z"
                  />
                </svg>
                orders list
              </Link>
              <Link
                onClick={countMessageCount}
                to={"/disputes"}
                className={
                  this.props.location.pathname.includes("dispute")
                    ? "active"
                    : "null"
                }
              >
                <svg
                  width="100pt"
                  height="100pt"
                  version="1.1"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path d="m21.883 14.004c-7.5117 0-13.645 6.1289-13.645 13.641v55.621-0.003906c0 0.84375 0.50781 1.6055 1.2852 1.9258 0.77734 0.32422 1.6758 0.14453 2.2695-0.44922l11.543-11.543h18.238c0.83203-0.003906 1.5859-0.5 1.9141-1.2695l23.43-55.023c0.27344-0.64453 0.20312-1.3789-0.17969-1.9609-0.38672-0.58594-1.0352-0.93359-1.7344-0.9375zm0 4.1641 39.973 0.003906-21.66 50.855h-17.719c-0.55469 0-1.082 0.21875-1.4766 0.60938l-8.5977 8.5977v-50.59c0-5.2578 4.2227-9.4766 9.4805-9.4727z" />
                    <path d="m24.207 27.219c-1.1484 0.003906-2.0781 0.93359-2.082 2.0781v6.2852c0.003906 1.1484 0.93359 2.0781 2.082 2.082 0.55078 0 1.0859-0.21484 1.4766-0.60547 0.39453-0.39062 0.61719-0.92188 0.61719-1.4766v-4.2031h8.3945v6.4727l-5.6797 5.6836c-0.39062 0.39062-0.60547 0.92188-0.60547 1.4766v6.2812c0.003906 1.1484 0.93359 2.0781 2.0781 2.082 0.55469 0 1.0898-0.21484 1.4805-0.60547 0.39453-0.39062 0.61328-0.92188 0.61719-1.4766v-5.4258l5.6641-5.6641c0.39453-0.39062 0.61719-0.92188 0.62109-1.4766v-9.4297c-0.003906-0.55078-0.22266-1.082-0.61719-1.4727-0.39453-0.39062-0.92578-0.60938-1.4805-0.60547z" />
                    <path
                      d="m32.809 57.906c0 1.1875-0.96094 2.1484-2.1484 2.1484s-2.1484-0.96094-2.1484-2.1484c0-0.57031 0.22656-1.1172 0.62891-1.5195 0.40234-0.40234 0.94922-0.62891 1.5195-0.62891 0.57031 0 1.1172 0.22656 1.5195 0.62891 0.40234 0.40234 0.62891 0.94922 0.62891 1.5195z"
                      fill-rule="evenodd"
                    />
                    <path d="m65.004 26.805c-0.83594 0.003906-1.5898 0.5-1.9141 1.2695l-23.434 55.023c-0.26953 0.64453-0.20312 1.3789 0.17969 1.9609 0.38672 0.58594 1.0391 0.93359 1.7383 0.9375h36.543c7.5117 0 13.645-6.1289 13.648-13.637v-31.914c-0.003906-7.5117-6.1367-13.641-13.648-13.641zm1.375 4.1641h11.742c5.2617 0 9.4805 4.2148 9.4805 9.4727v31.914c-0.003906 5.2578-4.2227 9.4766-9.4844 9.4727h-33.395z" />
                    <path d="m75.785 40.02c-1.1445 0.003907-2.0742 0.93359-2.0781 2.0781v21.996c0.003907 1.1484 0.93359 2.0781 2.0781 2.082 1.1484-0.003906 2.0781-0.93359 2.082-2.082v-21.996c-0.003907-1.1445-0.93359-2.0742-2.082-2.0781z" />
                    <path
                      d="m77.938 70.707c0 1.1875-0.96094 2.1484-2.1484 2.1484s-2.1484-0.96094-2.1484-2.1484c0-0.57031 0.22656-1.1172 0.62891-1.5195 0.40234-0.40234 0.94922-0.62891 1.5195-0.62891 0.57031 0 1.1172 0.22656 1.5195 0.62891s0.62891 0.94922 0.62891 1.5195z"
                      fill-rule="evenodd"
                    />
                  </g>
                </svg>
                disputes list
                {this.props.unreadDisputeCount > 0 && (
                  <span
                    className="badge badge-dark"
                    style={{ marginLeft: "10px" }}
                  >
                    {this.props.unreadDisputeCount}
                  </span>
                )}
              </Link>
              <Link
                onClick={countMessageCount}
                to={"/reviews"}
                className={
                  this.props.location.pathname === "/reviews" ||
                  this.props.match.path == "/review/:orderId" ||
                  this.props.match.path == "/reviews/product/:productId"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  width="100pt"
                  height="100pt"
                  version="1.1"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path d="m88.762 10.16c-0.003907 0-0.007813-0.003906-0.015625-0.003906h-32.676c-0.074218-0.074219-0.13672-0.15625-0.21094-0.23047-13.484-13.391-36.684-3.9336-36.684 15.152 0 3.7734 0.98828 7.3867 2.8125 10.578v1.7969l-1.5664 1.5586c-1.1172-0.69141-2.6055-0.55859-3.5742 0.40625l-11.273 11.238c-2.6055 2.5977-2.6055 6.8086 0 9.4102 2.5977 2.5898 6.832 2.6055 9.4375 0.007813l6.9766-6.9531v28.398l-8.6914 0.003907c-0.60156 0-1.0859 0.48828-1.0859 1.0859v6.1328c0 4.1875 3.4297 7.5977 7.6406 7.5977h55.805c4.207 0 7.6328-3.4102 7.6328-7.5977v-62.289h12c0.60156 0 1.0859-0.48828 1.0859-1.0859v-7.6055c0.003906-4.1875-3.4141-7.5938-7.6133-7.6016zm-67.41 14.922c0-17.152 20.852-25.648 32.977-13.613 6.4102 6.4023 7.5234 16.215 2.7148 23.863-10.293 16.277-35.691 8.9453-35.691-10.25zm2.6055 13.48c0.78516 0.96484 2.0117 2.2266 3.1914 3.1836l-1.9297 1.9336c-0.95312-0.95313-3.3438-3.3438-3.1914-3.1914zm-1.3359-1.7383v-0.11719c0.015625 0.023438 0.027344 0.050781 0.042968 0.074219zm-9.1406 21.715c-1.7539 1.75-4.6094 1.7422-6.3672-0.007812-1.7539-1.7461-1.7539-4.582 0-6.332l11.273-11.238c0.14062-0.14063 0.32812-0.21094 0.51562-0.21094s0.37109 0.070312 0.51562 0.21094c1.2148 1.2109 5.4922 5.4766 5.3398 5.3242 0.13281 0.13281 0.20703 0.31641 0.20703 0.50781 0 0.19141-0.074219 0.37109-0.21094 0.50781zm6.375 35.629c-3.0156 0-5.4688-2.4336-5.4688-5.4258v-5.0469h53.641v5.0469c0 1.9531 0.80859 3.9766 2.293 5.4258zm61.262-76.414v0.003906 70.984c0 7.0234-10.914 7.3008-10.914 0v-6.1328c0-0.60156-0.48828-1.0859-1.0859-1.0859h-44.953v-30.566l2.125-2.1172c0.54688-0.54688 0.84766-1.2734 0.84766-2.0469 0-0.54688-0.15625-1.0664-0.4375-1.5195l2.2383-2.2383c3.4883 2.2734 7.5625 3.4922 11.738 3.4922 7.5234-0.003906 14.34-3.918 18.207-10.035 4.7852-7.6094 4.293-17.133-0.95703-24.156h25.488c-1.4297 1.3828-2.2969 3.3242-2.2969 5.418zm13.086 6.5234h-10.914v-6.5195c0-2.9922 2.4492-5.4258 5.457-5.4258 3.0078 0 5.457 2.4336 5.457 5.4258z" />
                    <path d="m48.965 52.172h-3.8828c-1.2773 0-2.3164 1.0391-2.3164 2.3125v19.789c0 1.2734 1.0391 2.3125 2.3164 2.3125h3.8828c1.2773 0 2.3164-1.0391 2.3164-2.3125v-19.789c0-1.2734-1.0391-2.3125-2.3164-2.3125zm0.14062 22.102c0 0.074218-0.066407 0.14062-0.14062 0.14062h-3.8828c-0.074219 0-0.14062-0.066406-0.14062-0.14062v-19.789c0-0.074219 0.066406-0.13672 0.14062-0.13672h3.8828c0.074218 0 0.14062 0.0625 0.14062 0.13672z" />
                    <path d="m37.988 58.301h-3.8711c-1.2773 0-2.3164 1.0391-2.3164 2.3125v13.66c0 1.2734 1.0391 2.3125 2.3164 2.3125h3.8711c1.2773 0 2.3164-1.0391 2.3164-2.3125v-13.66c0-1.2773-1.0391-2.3125-2.3164-2.3125zm0.14453 15.973c0 0.070312-0.046874 0.14062-0.14062 0.14062h-3.8711c-0.074219 0-0.14062-0.066406-0.14062-0.14062v-13.66c0-0.074219 0.066406-0.14062 0.14062-0.14062h3.8711c0.09375 0 0.14062 0.070313 0.14062 0.14062z" />
                    <path d="m59.93 47.27h-3.8711c-1.2773 0-2.3164 1.0391-2.3164 2.3125v24.691c0 1.2734 1.0391 2.3125 2.3164 2.3125h3.8711c1.2773 0 2.3164-1.0391 2.3164-2.3125v-24.691c0-1.2734-1.0391-2.3125-2.3164-2.3125zm0.14062 27.004c0 0.074218-0.066406 0.14062-0.14062 0.14062h-3.8711c-0.09375 0-0.14062-0.070312-0.14062-0.14062l-0.003907-24.691c0-0.070312 0.046876-0.13672 0.14063-0.13672h3.8711c0.074219 0 0.14062 0.0625 0.14062 0.13672z" />
                    <path d="m71.16 41.547h-3.8711c-1.2773 0-2.3164 1.0391-2.3164 2.3125v30.414c0 1.2734 1.0391 2.3125 2.3164 2.3125h3.8711c1.2773 0 2.3164-1.0391 2.3164-2.3125v-30.414c0-1.2773-1.0391-2.3125-2.3164-2.3125zm0.14453 32.727c0 0.074218-0.066407 0.14062-0.14063 0.14062h-3.8711c-0.09375 0-0.14062-0.070312-0.14062-0.14062l-0.003906-30.414c0-0.070313 0.046874-0.13672 0.14062-0.13672h3.8711c0.074219 0 0.14062 0.0625 0.14062 0.13672z" />
                    <path d="m51.441 36.734c0.60156 0 1.0859-0.48828 1.0859-1.0859v-3.8594c0-4.1055-2.9336-7.543-6.8438-8.3828 1.3711-1.3047 2.2305-3.125 2.2305-5.1484 0-3.9453-3.2539-7.1562-7.2539-7.1562-3.9961 0-7.2461 3.2109-7.2461 7.1562 0 2.0234 0.85938 3.8438 2.2305 5.1484-3.9062 0.84375-6.8398 4.2773-6.8398 8.3828v3.8594c-0.003907 2.082 2.5195 0.65625 22.637 1.0859zm-15.855-18.48c0-2.75 2.2773-4.9844 5.0742-4.9844 2.8008 0 5.082 2.2344 5.082 4.9844 0 2.6016-2.043 4.7188-4.6328 4.9453h-0.89844c-2.5859-0.22266-4.625-2.3438-4.625-4.9453zm-4.6133 13.535c0-3.5352 2.9258-6.4102 6.5234-6.4102h6.3359c3.5977 0 6.5234 2.875 6.5234 6.4102v2.7734h-19.383z" />
                  </g>
                </svg>
                reviews list
                {this.props.unreadReviewsCount > 0 && (
                  <span
                    className="badge badge-dark"
                    style={{ marginLeft: "10px" }}
                  >
                    {this.props.unreadReviewsCount}
                  </span>
                )}
              </Link>
            </li>
            <li>
              <Link
                onClick={countMessageCount}
                to={"/payments"}
                className={
                  this.props.location.pathname === "/payments"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                >
                  <g>
                    <path
                      d="M1.54,16c2.31,0,4.62,0,6.94,0c1.75,0,3.51,0,5.26,0c0.62,0,1.09-0.27,1.38-0.83c0.12-0.23,0.15-0.48,0.15-0.73
		c0-0.67,0-1.34,0-2.01c0-0.09,0.03-0.14,0.12-0.19c0.38-0.2,0.6-0.52,0.61-0.95c0.01-0.74,0.01-1.48,0-2.22
		c-0.01-0.43-0.22-0.75-0.6-0.95c-0.1-0.05-0.12-0.1-0.12-0.2c0-0.67,0-1.34,0-2.02c0-0.48-0.16-0.89-0.53-1.2
		c-0.19-0.16-0.41-0.27-0.66-0.3c-0.11-0.01-0.17-0.06-0.23-0.16c-0.61-1.07-1.23-2.13-1.82-3.2C11.74,0.52,11.38,0.1,10.75,0
		c-0.12,0-0.25,0-0.37,0C9.4,0.37,8.56,0.98,7.66,1.49C6.05,2.4,4.46,3.35,2.85,4.28C2.76,4.33,2.64,4.36,2.53,4.36
		c-0.32,0.01-0.63,0.01-0.95,0C0.75,4.33,0.11,4.89,0.02,5.63c0,0.01-0.01,0.02-0.02,0.03c0,3.01,0,6.02,0,9.03
		c0.05,0.26,0.12,0.5,0.28,0.71C0.6,15.82,1.03,16,1.54,16z M14.79,8.72c0.34,0,0.48,0.14,0.48,0.48c0,0.65,0,1.3,0,1.95
		c0,0.34-0.13,0.47-0.47,0.47c-0.42,0-0.83,0-1.25,0c0,0,0-0.01,0-0.01c-0.44,0-0.88,0.02-1.31,0c-0.65-0.03-1.22-0.59-1.31-1.2
		c-0.11-0.77,0.38-1.44,0.99-1.61c0.17-0.05,0.36-0.08,0.54-0.08C13.23,8.72,14.01,8.72,14.79,8.72z M12.29,2.98
		c0.26,0.46,0.52,0.91,0.79,1.37c-1.04,0-2.07,0-3.14,0C10.74,3.89,11.51,3.44,12.29,2.98z M11.57,1.72
		c0.12,0.21,0.24,0.41,0.36,0.62c-0.05,0.03-0.09,0.06-0.13,0.08c-1.07,0.62-2.14,1.25-3.21,1.86C8.51,4.34,8.42,4.36,8.33,4.36
		c-0.41,0.01-0.82,0-1.23,0c0-0.01-0.01-0.02-0.01-0.03C8.58,3.46,10.07,2.6,11.57,1.72z M4.3,4.28C6.26,3.14,8.22,2,10.19,0.85
		c0.39-0.23,0.78-0.13,1.02,0.24c-0.05,0.03-0.09,0.06-0.14,0.09c-1.78,1.04-3.56,2.07-5.34,3.1c-0.09,0.05-0.2,0.08-0.31,0.08
		c-0.4,0.01-0.8,0-1.24,0C4.24,4.32,4.27,4.3,4.3,4.28z M0.72,5.93c0-0.44,0.2-0.72,0.56-0.81C1.37,5.1,1.45,5.09,1.54,5.09
		c4.07,0,8.13,0,12.2,0c0.5,0,0.8,0.28,0.8,0.76c0.01,0.69,0,1.37,0,2.06c0,0.02-0.01,0.05-0.01,0.09c-0.06,0-0.12,0-0.17,0
		c-0.66,0-1.32,0-1.98,0c-1.04,0.01-1.89,0.68-2.14,1.7c-0.31,1.25,0.63,2.56,1.91,2.64c0.72,0.04,1.45,0.02,2.17,0.02
		c0.07,0,0.13,0,0.21,0c0,0.77,0.01,1.51,0,2.26c-0.01,0.35-0.28,0.6-0.65,0.64c-0.06,0.01-0.12,0.01-0.19,0.01
		c-4.03,0-8.07,0-12.1-0.01c-0.18,0-0.38-0.05-0.53-0.13C0.81,15,0.73,14.76,0.72,14.48c0-0.32,0-0.65,0-0.97
		C0.72,10.99,0.72,8.46,0.72,5.93z"
                    />
                    <path
                      d="M11.63,10.18c0,0.4,0.33,0.72,0.73,0.72c0.4,0,0.73-0.33,0.72-0.73c0-0.4-0.33-0.72-0.73-0.72
		C11.96,9.46,11.63,9.79,11.63,10.18z"
                    />
                  </g>
                </svg>
                Payments List
              </Link>
            </li>
            {/* <li><Link to={'/add-product'} className={this.props.location.pathname === '/add-product' || this.props.match.path === '/edit-product/:slug' ? 'active' : 'null'}><i className="fas fa-list"></i> add/edit product</Link></li> */}
            <li>
              <Link
                onClick={countMessageCount}
                to={"/product-list"}
                className={
                  this.props.location.pathname === "/product-list" ||
                  this.props.match.path == "/add-product" ||
                  this.props.match.path == "/edit-product/:slug"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                >
                  <g>
                    <path
                      d="M11.09,13.31c0.2,0,0.39,0,0.59,0c0.03,0.05,0.05,0.1,0.08,0.13c-0.03-0.03-0.06-0.08-0.08-0.13
		C11.48,13.31,11.29,13.31,11.09,13.31z"
                    />
                    <path
                      d="M0.23,11.55c1.94,1.12,3.89,2.24,5.83,3.37c0.18,0.11,0.32,0.1,0.5,0c1-0.59,2.01-1.17,3.06-1.77c0,0.07,0,0.12,0,0.17
		c0,0.38,0,0.77,0,1.15c0,0.56,0.42,0.97,0.97,0.98c1.48,0,2.96,0,4.44,0c0.55,0,0.97-0.42,0.97-0.97c0-2.18,0-4.36,0-6.54
		c0-0.55-0.43-0.97-0.98-0.98c-0.23,0-0.46,0-0.7,0c-0.05,0-0.11-0.04-0.13-0.08c-0.16-0.35-0.43-0.51-0.81-0.5
		c-0.26,0-0.51,0-0.78,0c0-0.68,0-1.35,0-2.01c0-0.17-0.06-0.27-0.2-0.35c-1.96-1.13-3.91-2.26-5.87-3.39
		c-0.16-0.09-0.29-0.09-0.45,0C4.13,1.76,2.16,2.89,0.2,4.02C0.06,4.1,0,4.21,0,4.37c0,2.26,0,4.52,0,6.78
		C0,11.35,0.07,11.46,0.23,11.55z M14.16,7.53c0.04-0.09,0.09-0.12,0.18-0.12c0.22,0.01,0.44,0,0.66,0c0.32,0,0.54,0.22,0.54,0.54
		c0,2.17,0,4.34,0,6.51c0,0.32-0.21,0.53-0.53,0.53c-1.47,0-2.95,0-4.42,0c-0.32,0-0.52-0.22-0.52-0.55c0-1.08,0-2.16,0-3.24
		c0-1.06,0-2.13,0-3.19c0-0.4,0.2-0.6,0.59-0.6c0.11,0,0.22,0.01,0.32,0c0.23-0.02,0.43-0.01,0.56,0.26
		c0.1,0.21,0.34,0.29,0.58,0.29c0.45,0,0.9,0.01,1.34,0C13.79,7.96,14.02,7.81,14.16,7.53z M13.44,6.83c0.21,0,0.35,0.14,0.35,0.34
		c0,0.2-0.14,0.34-0.35,0.34c-0.21,0-0.42,0-0.63,0c-0.21,0-0.42,0-0.63,0c-0.22,0-0.36-0.14-0.36-0.35c0-0.2,0.14-0.33,0.36-0.33
		C12.6,6.83,13.02,6.83,13.44,6.83z M12.14,4.67c0,0.58,0,1.13,0,1.7c-0.37,0.04-0.63,0.23-0.75,0.59c-0.26,0-0.51,0-0.77,0
		c-0.58,0-1,0.42-1,1c0,1.5,0,3,0,4.5c0,0.12-0.04,0.19-0.14,0.25c-0.92,0.53-1.84,1.06-2.76,1.59c-0.05,0.03-0.1,0.05-0.17,0.09
		c0-0.06-0.01-0.11-0.01-0.15c0-2.06,0-4.12,0-6.18c0-0.09,0.01-0.16,0.1-0.21c1.8-1.04,3.6-2.08,5.4-3.12
		C12.07,4.71,12.09,4.7,12.14,4.67z M9.38,2.79c0.82,0.48,1.65,0.96,2.48,1.44c0.02,0.01,0.03,0.02,0.06,0.05
		c-0.06,0.04-0.11,0.07-0.16,0.1C9.98,5.39,8.21,6.42,6.44,7.44c-0.1,0.06-0.17,0.06-0.26,0C5.4,6.99,4.62,6.54,3.84,6.09
		c-0.05-0.03-0.1-0.06-0.16-0.1c0.06-0.04,0.11-0.07,0.16-0.1c1.77-1.02,3.55-2.05,5.32-3.07C9.23,2.78,9.28,2.74,9.38,2.79z
		 M2.42,3.27c1.25-0.72,2.49-1.44,3.74-2.16c0.1-0.06,0.18-0.07,0.29-0.01c0.74,0.44,1.49,0.87,2.24,1.3
		c0.04,0.02,0.08,0.05,0.13,0.09C8.63,2.6,8.46,2.7,8.28,2.8C6.64,3.75,4.99,4.7,3.35,5.65c-0.11,0.06-0.18,0.06-0.29,0
		C2.33,5.22,1.6,4.8,0.87,4.37c-0.05-0.03-0.1-0.06-0.18-0.11C1.28,3.92,1.85,3.6,2.42,3.27z M0.46,4.85c0-0.05,0-0.1,0-0.18
		C0.52,4.7,0.56,4.72,0.6,4.74c1.79,1.03,3.58,2.06,5.37,3.09c0.08,0.05,0.13,0.09,0.13,0.2c0,2.08,0,4.17,0,6.25
		c0,0.03,0,0.06-0.01,0.12c-0.1-0.06-0.18-0.1-0.26-0.15c-1.74-1-3.47-2-5.21-3c-0.11-0.06-0.16-0.13-0.16-0.26
		C0.46,8.94,0.46,6.9,0.46,4.85z"
                    />
                    <path
                      d="M10.63,11.04c0,0.33,0,0.67,0,1c0,0.17,0.09,0.26,0.26,0.26c0.33,0,0.67,0,1,0c0.17,0,0.25-0.09,0.26-0.26
		c0-0.17,0-0.33,0-0.5s0-0.33,0-0.5c0-0.17-0.09-0.26-0.26-0.26c-0.33,0-0.67,0-1,0C10.71,10.78,10.63,10.87,10.63,11.04z
		 M11.09,11.24c0.19,0,0.39,0,0.6,0c0,0.21,0,0.4,0,0.6c-0.2,0-0.39,0-0.6,0C11.09,11.65,11.09,11.45,11.09,11.24z"
                    />
                    <path
                      d="M11.09,13.91c0-0.21,0-0.4,0-0.6c0.2,0,0.39,0,0.59,0c0.03,0.05,0.05,0.1,0.08,0.13c0.09,0.1,0.2,0.12,0.3,0.04
		c0.15-0.13,0.1-0.3,0.08-0.45c-0.02-0.13-0.14-0.18-0.27-0.17c-0.31,0-0.63,0-0.94,0c-0.21,0-0.29,0.08-0.3,0.29
		c0,0.31,0,0.61,0,0.92c0,0.12,0,0.25,0.13,0.28c0.16,0.04,0.33,0.07,0.48-0.04c0.12-0.09,0.07-0.21,0.03-0.33
		c-0.01-0.01-0.02-0.02-0.03-0.03C11.19,13.94,11.13,13.92,11.09,13.91z"
                    />
                    <path
                      d="M10.81,10.22c0.23,0.05,0.43,0,0.48-0.13c0.04-0.11,0.02-0.2-0.08-0.27c-0.04-0.03-0.09-0.04-0.13-0.06
		c0-0.19,0-0.39,0-0.6c0.1,0,0.18,0,0.26,0c0.16,0.01,0.34-0.04,0.47,0.12c0.03,0.04,0.17,0.02,0.23-0.02
		c0.14-0.09,0.11-0.25,0.09-0.38c-0.02-0.12-0.12-0.17-0.24-0.17c-0.34,0-0.68,0-1.02,0c-0.16,0-0.25,0.09-0.25,0.25
		c0,0.34,0,0.68,0,1.02C10.62,10.11,10.69,10.2,10.81,10.22z"
                    />
                    <path
                      d="M12.42,9.01C12.21,9.22,12,9.43,11.76,9.67c-0.07-0.08-0.12-0.16-0.19-0.22c-0.1-0.09-0.22-0.1-0.32,0
		c-0.1,0.1-0.09,0.22,0,0.32c0.11,0.12,0.23,0.24,0.35,0.35c0.11,0.1,0.23,0.1,0.33,0c0.27-0.27,0.54-0.54,0.81-0.81
		c0.09-0.09,0.1-0.2,0.02-0.31C12.69,8.9,12.54,8.89,12.42,9.01z"
                    />
                    <path
                      d="M12.41,13.2c-0.21,0.21-0.42,0.42-0.62,0.63c-0.17-0.09-0.26-0.37-0.51-0.21c-0.14,0.09-0.11,0.22-0.04,0.34
		c0.01,0.01,0.02,0.02,0.03,0.03c0.11,0.11,0.22,0.22,0.34,0.32c0.11,0.1,0.23,0.11,0.33,0c0.28-0.27,0.55-0.55,0.82-0.82
		c0.08-0.08,0.09-0.18,0.03-0.28C12.7,13.08,12.54,13.07,12.41,13.2z"
                    />
                    <path
                      d="M13.12,9.48c0,0.14,0.1,0.23,0.27,0.23c0.44,0,0.88,0,1.32,0c0.17,0,0.28-0.09,0.28-0.22c0-0.14-0.11-0.23-0.29-0.23
		c-0.22,0-0.43,0-0.65,0c-0.22,0-0.44,0-0.66,0C13.22,9.26,13.12,9.34,13.12,9.48z"
                    />
                    <path
                      d="M14.71,11.33c-0.44,0-0.88,0-1.32,0c-0.17,0-0.27,0.09-0.27,0.23c0,0.14,0.1,0.22,0.28,0.22c0.22,0,0.43,0,0.65,0
		c0.22,0,0.44,0,0.66,0c0.18,0,0.29-0.09,0.29-0.23C14.99,11.42,14.88,11.33,14.71,11.33z"
                    />
                    <path
                      d="M14.73,13.4c-0.45,0-0.9,0-1.36,0c-0.16,0-0.26,0.09-0.26,0.23c0,0.13,0.1,0.22,0.26,0.22c0.22,0,0.44,0,0.66,0
		c0.23,0,0.46,0,0.69,0c0.16,0,0.26-0.1,0.26-0.23C14.99,13.5,14.89,13.4,14.73,13.4z"
                    />
                  </g>
                </svg>
                product list
              </Link>
            </li>
            <li>
              <Link
                onClick={countMessageCount}
                to={"/meeting-list"}
                className={
                  this.props.location.pathname === "/meeting-list"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                >
                  <g>
                    <path d="M13.67,9.24c0.02,0.02,0.04,0.04,0.06,0.07c0-0.02,0.01-0.03,0.01-0.05C13.72,9.26,13.7,9.25,13.67,9.24z" />
                    <path
                      d="M6.72,13.72C6.72,13.72,6.72,13.73,6.72,13.72C6.72,13.73,6.72,13.73,6.72,13.72C6.73,13.73,6.72,13.73,6.72,13.72
		C6.72,13.72,6.72,13.72,6.72,13.72z"
                    />
                    <path
                      d="M4.04,15.78c0.17-0.02,0.28-0.12,0.26-0.26c-0.01-0.15-0.13-0.23-0.32-0.2c-0.32,0.05-0.63,0.02-0.93-0.09
		c-0.12-0.04-0.23-0.1-0.36-0.18c0.15-0.14,0.29-0.28,0.43-0.42c0.12-0.11,0.22-0.24,0.27-0.4c0.02-0.08,0.05-0.06,0.11-0.04
		c0.41,0.19,0.75,0.14,1.07-0.18c0.5-0.5,1-1.01,1.51-1.51c0.26-0.26,0.64-0.18,0.75,0.14c0.05,0.16,0.02,0.31-0.1,0.42
		c-0.63,0.63-1.24,1.27-1.89,1.88c-0.13,0.12-0.16,0.24-0.07,0.35c0.09,0.11,0.23,0.11,0.36-0.01c0.34-0.29,0.64-0.61,0.94-0.93
		c0.18-0.19,0.33-0.25,0.6-0.19c0.99,0.24,1.98,0.2,2.97-0.07c0.09-0.02,0.14-0.01,0.2,0.05c0.29,0.3,0.59,0.59,0.88,0.88
		c0.79,0.78,1.91,0.92,2.86,0.34c0.07-0.04,0.1-0.04,0.17,0.01c0.38,0.32,0.88,0.29,1.24-0.05c0.14-0.13,0.28-0.27,0.41-0.41
		c0.35-0.36,0.37-0.86,0.06-1.24c-0.04-0.04-0.07-0.07-0.03-0.14c0.18-0.27,0.3-0.57,0.35-0.9c0-0.01,0.02-0.02,0.03-0.03
		c0-0.05,0-0.1,0-0.15c-0.06-0.11-0.02-0.23-0.03-0.34c0,0,0,0,0,0c0-0.05,0-0.09,0-0.14c-0.01,0-0.01,0-0.02-0.01
		c-0.06-0.16-0.08-0.32-0.14-0.48c-0.12-0.3-0.28-0.56-0.51-0.78c-0.29-0.28-0.57-0.58-0.87-0.86c-0.14-0.13-0.16-0.26-0.12-0.44
		c0.13-0.5,0.17-1.02,0.18-1.54c0-0.17-0.04-0.34-0.03-0.52c0-0.05-0.02-0.09-0.04-0.13c-0.04-0.07-0.1-0.12-0.19-0.1
		c-0.08,0.02-0.13,0.08-0.14,0.16c-0.01,0.11-0.01,0.21,0,0.32c0.06,0.5,0,0.99-0.09,1.48c-0.02,0.08-0.04,0.13-0.07,0.14
		c0,0.02-0.01,0.03-0.01,0.05c-0.02-0.02-0.04-0.05-0.06-0.07c-0.02-0.01-0.05-0.03-0.08-0.06c-0.16-0.14-0.3-0.31-0.51-0.38
		c-0.38-0.14-0.79,0-1.01,0.32c-0.21,0.31-0.17,0.78,0.1,1.06c0.24,0.25,0.48,0.48,0.72,0.72c0.16,0.16,0.17,0.19,0.04,0.38
		c-0.99,1.44-2.35,2.3-4.08,2.58c-0.63,0.1-1.26,0.08-1.89-0.03c-0.02,0-0.04-0.01-0.06-0.01c-0.06-0.02-0.15-0.02-0.17-0.08
		c0,0-0.01,0-0.01,0c0,0,0,0,0.01-0.01c-0.02-0.07,0.05-0.11,0.1-0.16c0.07-0.07,0.14-0.14,0.2-0.21c0.32-0.34,0.31-0.85-0.02-1.18
		c-0.3-0.31-0.84-0.32-1.16-0.02c-0.26,0.24-0.5,0.49-0.75,0.74c-0.16,0.16-0.18,0.16-0.36,0.04c-1.47-1-2.34-2.38-2.6-4.14
		C2.07,8.38,2.04,7.96,2.1,7.55c0.01-0.04,0-0.07,0-0.11c-0.01-0.1-0.07-0.17-0.18-0.18C1.81,7.26,1.75,7.33,1.74,7.43
		c-0.04,0.3-0.05,0.61-0.03,0.91c0.03,0.44,0.1,0.88,0.21,1.31c0.03,0.11-0.03,0.17-0.09,0.24c-0.29,0.29-0.59,0.58-0.87,0.87
		C0.62,11.1,0.4,11.5,0.33,11.97c-0.09,0.53-0.01,1.03,0.26,1.5c0.08,0.13,0.09,0.23-0.01,0.37c-0.26,0.36-0.21,0.79,0.09,1.12
		c0.13,0.14,0.27,0.28,0.41,0.41c0.34,0.32,0.79,0.35,1.17,0.07c0.09-0.06,0.16-0.08,0.25-0.02c0.24,0.16,0.51,0.25,0.79,0.31
		c0.04,0.01,0.11,0,0.11,0.08c0.07,0,0.14,0,0.21,0c0.06-0.05,0.12-0.05,0.18,0c0.05,0,0.1,0,0.15,0
		C3.96,15.76,4.01,15.78,4.04,15.78z M15.08,14.62c-0.13,0.14-0.27,0.28-0.42,0.42c-0.2,0.19-0.46,0.18-0.65-0.01
		c-0.27-0.26-0.53-0.53-0.8-0.8c-0.09-0.09-0.15-0.2-0.15-0.34c-0.01-0.13,0.04-0.24,0.13-0.33c0.14-0.14,0.28-0.28,0.42-0.42
		c0.19-0.18,0.45-0.18,0.64,0c0.28,0.27,0.55,0.54,0.82,0.82C15.27,14.16,15.27,14.41,15.08,14.62z M12.52,9.94
		c-0.14-0.14-0.21-0.3-0.14-0.5c0.1-0.29,0.46-0.38,0.7-0.17c0.17,0.15,0.33,0.32,0.49,0.48c0.41,0.41,0.83,0.82,1.24,1.24
		c0.6,0.61,0.72,1.5,0.31,2.21c-0.01,0.03-0.03,0.05-0.06,0.1c-0.15-0.15-0.29-0.3-0.43-0.44c-0.11-0.11-0.23-0.21-0.37-0.25
		c-0.07-0.02-0.08-0.04-0.05-0.11c0.19-0.4,0.13-0.75-0.17-1.06C13.53,10.94,13.02,10.44,12.52,9.94z M12.29,12.66
		c0.07-0.06,0.11-0.05,0.16,0.01c0.04,0.05,0.09,0.09,0.15,0.1c0.11,0.02,0.19-0.01,0.25-0.11c0.06-0.1,0.05-0.21-0.04-0.28
		c-0.12-0.1-0.09-0.16,0-0.26c0.2-0.21,0.37-0.46,0.53-0.7c0.15,0.14,0.31,0.27,0.44,0.42c0.13,0.15,0.12,0.36-0.02,0.51
		c-0.44,0.45-0.88,0.89-1.33,1.33c-0.16,0.16-0.38,0.15-0.55,0c-0.13-0.12-0.25-0.25-0.39-0.38C11.77,13.1,12.04,12.89,12.29,12.66z
		 M11.01,13.57c0.06-0.04,0.09,0,0.13,0.03c0.12,0.12,0.24,0.24,0.36,0.36c0.31,0.3,0.65,0.37,1.04,0.19
		c0.03-0.01,0.07-0.08,0.1,0.01c0.09,0.29,0.33,0.46,0.52,0.66c0.06,0.06,0.12,0.11,0.18,0.17c-0.54,0.4-1.42,0.4-2.02-0.03
		c-0.41-0.29-0.71-0.69-1.09-1.04C10.52,13.82,10.77,13.71,11.01,13.57z M2.75,11.55c0.18,0.27,0.38,0.53,0.61,0.76
		c0.05,0.05,0.04,0.08-0.01,0.12c-0.07,0.06-0.12,0.12-0.12,0.22c0,0.09,0.05,0.16,0.13,0.2c0.09,0.05,0.19,0.03,0.25-0.05
		c0.1-0.14,0.17-0.09,0.27,0c0.22,0.2,0.46,0.36,0.71,0.54c-0.14,0.14-0.26,0.26-0.38,0.38c-0.18,0.17-0.41,0.18-0.59,0
		c-0.43-0.43-0.87-0.86-1.29-1.29c-0.18-0.18-0.17-0.4,0-0.58c0.1-0.11,0.21-0.21,0.31-0.31C2.69,11.49,2.71,11.49,2.75,11.55z
		 M1.19,11.13c0.28-0.3,0.58-0.58,0.89-0.87c0.1,0.25,0.21,0.5,0.34,0.73c0.03,0.06,0.03,0.09-0.02,0.14
		c-0.1,0.09-0.19,0.19-0.28,0.28c-0.41,0.41-0.46,0.69-0.23,1.21c-0.39,0.11-0.59,0.46-0.89,0.71c-0.17-0.24-0.25-0.51-0.27-0.79
		C0.67,12.01,0.82,11.53,1.19,11.13z M2.03,15.08c-0.19,0.18-0.45,0.18-0.64,0c-0.14-0.13-0.28-0.27-0.41-0.41
		c-0.2-0.21-0.2-0.46,0-0.66c0.27-0.28,0.54-0.55,0.82-0.82c0.09-0.09,0.19-0.13,0.31-0.13c0.13,0,0.24,0.04,0.33,0.13
		c0.14,0.14,0.28,0.27,0.41,0.41c0.2,0.21,0.2,0.46,0,0.66C2.58,14.54,2.3,14.81,2.03,15.08z"
                    />
                    <path
                      d="M12.16,8.95c-0.36,0.36-0.35,0.91,0.03,1.29c0.24,0.24,0.48,0.49,0.72,0.72C13,11.05,13,11.1,12.93,11.2
		c-1.34,1.92-3.18,2.8-5.51,2.65c-0.23-0.02-0.45-0.05-0.7-0.12c0.03,0.07,0.11,0.07,0.17,0.08c0.02,0,0.04,0.01,0.06,0.01
		c0.63,0.11,1.26,0.13,1.89,0.03c1.73-0.28,3.09-1.14,4.08-2.58c0.13-0.19,0.12-0.21-0.04-0.38c-0.24-0.24-0.49-0.48-0.72-0.72
		c-0.27-0.28-0.31-0.74-0.1-1.06c0.22-0.33,0.63-0.46,1.01-0.32c0.21,0.08,0.35,0.24,0.51,0.38c0.03,0.03,0.05,0.04,0.08,0.06
		c-0.08-0.09-0.15-0.18-0.23-0.25C13.06,8.61,12.52,8.6,12.16,8.95z"
                    />
                    <path
                      d="M15.13,10.68c-0.3-0.31-0.61-0.61-0.92-0.92c-0.07-0.07-0.09-0.13-0.07-0.24c0.18-0.71,0.23-1.43,0.16-2.16
		c-0.02-0.18-0.12-0.29-0.26-0.27c-0.14,0.02-0.21,0.12-0.2,0.31c0.01,0.21,0.03,0.43,0.03,0.64c0,0.39-0.04,0.79-0.14,1.21
		c0.03-0.01,0.06-0.06,0.07-0.14c0.09-0.49,0.15-0.98,0.09-1.48c-0.01-0.11-0.01-0.21,0-0.32c0.01-0.08,0.06-0.14,0.14-0.16
		c0.09-0.02,0.14,0.03,0.19,0.1c0.03,0.04,0.04,0.08,0.04,0.13c0,0.17,0.04,0.34,0.03,0.52c0,0.52-0.05,1.04-0.18,1.54
		c-0.05,0.18-0.02,0.31,0.12,0.44c0.29,0.28,0.57,0.58,0.87,0.86c0.23,0.22,0.39,0.49,0.51,0.78c0.06,0.16,0.08,0.32,0.14,0.48
		C15.77,12,15.78,12,15.78,12c0,0,0-0.01,0-0.01C15.72,11.48,15.49,11.05,15.13,10.68z"
                    />
                    <path
                      d="M3.28,15.73C3,15.67,2.74,15.58,2.5,15.42c-0.09-0.06-0.16-0.04-0.25,0.02c-0.38,0.28-0.82,0.25-1.17-0.07
		c-0.14-0.13-0.28-0.27-0.41-0.41c-0.3-0.32-0.35-0.76-0.09-1.12c0.1-0.14,0.09-0.23,0.01-0.37c-0.27-0.47-0.35-0.97-0.26-1.5
		c0.08-0.47,0.3-0.87,0.63-1.21c0.29-0.29,0.58-0.58,0.87-0.87c0.07-0.07,0.12-0.14,0.09-0.24C1.81,9.22,1.74,8.78,1.71,8.34
		C1.69,8.03,1.69,7.73,1.74,7.43c0.01-0.1,0.08-0.17,0.19-0.16C2.03,7.27,2.09,7.34,2.1,7.44c0,0.04,0.01,0.07,0,0.11
		C2.04,7.96,2.07,8.38,2.13,8.79c0.26,1.76,1.13,3.14,2.6,4.14c0.18,0.12,0.2,0.12,0.36-0.04c0.25-0.25,0.49-0.5,0.75-0.74
		c0.32-0.3,0.86-0.29,1.16,0.02c0.33,0.33,0.34,0.84,0.02,1.18c-0.07,0.07-0.14,0.14-0.2,0.21c-0.04,0.05-0.12,0.09-0.1,0.16
		c0.11-0.1,0.2-0.19,0.29-0.28c0.37-0.38,0.39-0.92,0.04-1.28c-0.36-0.36-0.91-0.35-1.29,0.03c-0.25,0.25-0.5,0.49-0.74,0.75
		C4.95,13,4.9,13,4.82,12.95c-0.87-0.57-1.56-1.31-2.04-2.24C2.27,9.72,2.05,8.67,2.14,7.55c0.02-0.21-0.06-0.33-0.22-0.33
		C1.78,7.21,1.7,7.31,1.68,7.52C1.62,8.24,1.7,8.95,1.88,9.65C1.9,9.73,1.89,9.78,1.83,9.83c-0.3,0.3-0.6,0.59-0.9,0.89
		c-0.77,0.78-0.91,1.92-0.33,2.85c0.04,0.06,0.05,0.1-0.01,0.17c-0.32,0.38-0.3,0.88,0.05,1.24c0.13,0.13,0.26,0.26,0.39,0.39
		c0.38,0.37,0.86,0.4,1.27,0.07c0.05-0.04,0.09-0.05,0.14-0.01c0.15,0.1,0.32,0.18,0.49,0.24c0.15,0.05,0.31,0.09,0.46,0.13
		C3.39,15.73,3.33,15.74,3.28,15.73z"
                    />
                    <path
                      d="M15.79,12.15c0.01,0.11-0.02,0.23,0.02,0.34c0-0.11,0-0.22,0-0.34C15.8,12.15,15.8,12.15,15.79,12.15
		C15.79,12.15,15.79,12.15,15.79,12.15z"
                    />
                    <path
                      d="M15.79,12.15C15.79,12.15,15.79,12.15,15.79,12.15c0,0-0.01-0.01-0.01-0.01c0.01,0.11-0.04,0.23,0.03,0.34
		C15.78,12.37,15.8,12.26,15.79,12.15z"
                    />
                    <path d="M3.61,15.81c0.06,0,0.12,0,0.18,0C3.73,15.78,3.67,15.78,3.61,15.81z" />
                    <path d="M3.61,15.81c0.06-0.02,0.12-0.02,0.18,0C3.73,15.76,3.67,15.76,3.61,15.81z" />
                    <path d="M15.78,12c0.02,0.05,0.01,0.1,0.01,0.14c0.01,0,0.01,0,0.02,0c0-0.05,0-0.1,0-0.15C15.8,12,15.79,12,15.78,12z" />
                    <path d="M15.78,12c0.01,0,0.02,0,0.03,0C15.8,11.99,15.79,11.99,15.78,12C15.78,11.99,15.78,12,15.78,12z" />
                    <path
                      d="M15.79,12.14c0-0.05,0.01-0.1-0.01-0.14c0,0,0,0,0,0c0,0.05,0,0.09,0,0.14c0,0,0,0,0,0c0,0,0,0,0,0
		C15.79,12.14,15.79,12.14,15.79,12.14z"
                    />
                    <polygon points="15.78,11.99 15.78,12 15.78,12 	" />
                    <path d="M15.79,12.14c0.01,0,0.01,0,0.02,0C15.8,12.14,15.8,12.14,15.79,12.14L15.79,12.14z" />
                    <path d="M15.79,12.14c0,0-0.01-0.01-0.01,0c0,0,0,0,0,0C15.79,12.14,15.79,12.14,15.79,12.14L15.79,12.14z" />
                    <path
                      d="M2.58,7.02c0.37,0.37,0.92,0.37,1.27,0.02C4.2,6.69,4.19,6.14,3.83,5.76C3.57,5.5,3.32,5.25,3.06,5
		C3,4.94,2.99,4.9,3.04,4.83C3.21,4.57,3.39,4.32,3.6,4.09c1.09-1.2,2.45-1.85,4.06-1.97C8.2,2.09,8.73,2.14,9.31,2.27
		c-0.13,0.12-0.23,0.2-0.32,0.3C8.61,2.95,8.6,3.5,8.97,3.85c0.36,0.35,0.91,0.34,1.28-0.04c0.25-0.24,0.49-0.48,0.73-0.74
		C11.06,3,11.11,3,11.2,3.06c1.16,0.77,1.95,1.82,2.4,3.13c0.09,0.26,0.25,0.3,0.45,0.1c0.34-0.34,0.68-0.67,1.01-1.01
		c0.78-0.79,0.92-1.91,0.34-2.86c-0.04-0.07-0.04-0.1,0.01-0.17c0.32-0.38,0.29-0.88-0.05-1.24c-0.13-0.14-0.27-0.28-0.41-0.41
		c-0.36-0.35-0.85-0.37-1.24-0.05c-0.06,0.05-0.09,0.05-0.15,0.02c-1-0.6-2.09-0.47-2.92,0.36c-0.3,0.29-0.59,0.59-0.88,0.89
		C9.69,1.86,9.64,1.88,9.56,1.86C8.5,1.59,7.43,1.59,6.37,1.87c-0.1,0.03-0.16,0-0.22-0.06c-0.3-0.31-0.6-0.61-0.91-0.91
		C4.47,0.16,3.34,0.04,2.42,0.6C2.35,0.65,2.31,0.64,2.24,0.59C1.88,0.28,1.37,0.3,1.03,0.63C0.88,0.77,0.74,0.91,0.59,1.06
		C0.25,1.41,0.23,1.91,0.54,2.29C0.6,2.36,0.59,2.4,0.55,2.47C-0.02,3.38,0.1,4.53,0.85,5.3C1.42,5.88,2,6.45,2.58,7.02z
		 M13.24,4.44c-0.17-0.26-0.37-0.51-0.59-0.74c-0.06-0.06-0.05-0.09,0.01-0.14c0.09-0.08,0.15-0.17,0.09-0.29
		c-0.06-0.16-0.25-0.2-0.37-0.06c-0.1,0.11-0.16,0.09-0.25,0c-0.22-0.2-0.47-0.37-0.73-0.55c0.13-0.13,0.25-0.25,0.37-0.36
		c0.19-0.18,0.42-0.19,0.61-0.01c0.43,0.42,0.86,0.85,1.28,1.28c0.18,0.18,0.17,0.4,0.01,0.58c-0.1,0.11-0.21,0.21-0.31,0.31
		C13.31,4.52,13.28,4.5,13.24,4.44z M15.12,4.41c-0.07,0.17-0.17,0.32-0.3,0.45c-0.3,0.29-0.6,0.58-0.89,0.87
		c-0.11-0.24-0.23-0.5-0.36-0.75c-0.03-0.06,0-0.08,0.03-0.11c0.12-0.12,0.25-0.24,0.37-0.37c0.3-0.31,0.36-0.65,0.19-1.04
		c-0.01-0.03-0.08-0.07,0.01-0.1c0.29-0.09,0.46-0.33,0.66-0.52c0.06-0.06,0.1-0.12,0.17-0.2C15.35,3.23,15.39,3.81,15.12,4.41z
		 M13.97,0.93c0.19-0.19,0.45-0.19,0.64-0.02c0.15,0.14,0.3,0.29,0.44,0.44c0.08,0.08,0.12,0.18,0.12,0.3
		C15.18,1.79,15.12,1.9,15.03,2c-0.27,0.27-0.54,0.54-0.81,0.81c-0.2,0.19-0.46,0.19-0.67,0c-0.14-0.13-0.27-0.26-0.4-0.4
		c-0.19-0.2-0.2-0.46,0-0.66C13.43,1.46,13.69,1.19,13.97,0.93z M11.12,1.1c0.58-0.54,1.57-0.6,2.19-0.14
		c-0.14,0.13-0.27,0.26-0.41,0.39c-0.13,0.12-0.24,0.25-0.29,0.42c-0.02,0.07-0.05,0.06-0.09,0.03c-0.38-0.21-0.81-0.11-1.1,0.2
		c-0.49,0.51-0.99,1-1.48,1.49C9.66,3.75,9.28,3.67,9.18,3.35c-0.05-0.16-0.02-0.3,0.1-0.42C9.89,2.31,10.49,1.69,11.12,1.1z
		 M4.43,2.76c-0.26,0.17-0.5,0.36-0.72,0.58C3.64,3.4,3.61,3.39,3.55,3.33C3.42,3.2,3.3,3.19,3.19,3.29
		c-0.1,0.1-0.09,0.23,0.04,0.36c0.06,0.06,0.08,0.1,0.01,0.17c-0.22,0.23-0.4,0.49-0.59,0.78C2.52,4.46,2.39,4.34,2.28,4.21
		c-0.17-0.19-0.18-0.41,0-0.6c0.42-0.43,0.85-0.86,1.28-1.28c0.18-0.17,0.4-0.17,0.58,0c0.1,0.1,0.2,0.2,0.3,0.3
		C4.52,2.69,4.5,2.72,4.43,2.76z M2.9,0.86c0.71-0.29,1.37-0.2,1.94,0.3c0.32,0.28,0.59,0.6,0.89,0.91c-0.24,0.1-0.49,0.2-0.72,0.33
		C4.94,2.45,4.9,2.44,4.85,2.39C4.73,2.26,4.61,2.13,4.48,2.01C4.19,1.73,3.84,1.67,3.47,1.84c-0.03,0.01-0.08,0.09-0.1,0
		C3.27,1.55,3.03,1.37,2.83,1.17C2.64,0.97,2.64,0.97,2.9,0.86z M0.93,1.37C1.06,1.23,1.2,1.09,1.34,0.96
		c0.19-0.18,0.45-0.18,0.64,0c0.28,0.27,0.55,0.55,0.83,0.83C2.9,1.88,2.94,1.98,2.94,2.1C2.95,2.24,2.9,2.34,2.81,2.44
		C2.67,2.58,2.53,2.72,2.39,2.85c-0.19,0.18-0.45,0.18-0.64,0C1.47,2.59,1.2,2.31,0.93,2.04C0.73,1.83,0.73,1.58,0.93,1.37z
		 M0.95,2.69C1.08,2.82,1.2,2.95,1.31,3.07C1.45,3.21,1.59,3.34,1.78,3.4c0.06,0.02,0.06,0.04,0.03,0.09
		C1.59,3.84,1.68,4.29,2.03,4.62c0.49,0.46,0.96,0.95,1.44,1.43c0.08,0.08,0.15,0.16,0.17,0.28C3.67,6.52,3.59,6.7,3.42,6.79
		C3.26,6.88,3.07,6.86,2.93,6.73C2.31,6.12,1.69,5.52,1.1,4.88C0.55,4.3,0.51,3.38,0.95,2.69z"
                    />
                    <path
                      d="M5.54,8.86C5.35,8.65,5.11,8.64,4.9,8.83C4.54,9.14,4.18,9.46,3.83,9.78c-0.22,0.2-0.24,0.44-0.04,0.67
		c0.39,0.44,0.78,0.88,1.17,1.32c0.18,0.2,0.42,0.21,0.62,0.04c0.37-0.32,0.74-0.65,1.11-0.98c0.09-0.08,0.15-0.19,0.15-0.33
		c0.01-0.11-0.04-0.21-0.12-0.29C6.33,9.75,5.94,9.3,5.54,8.86z M4.5,10.56c-0.1-0.13-0.21-0.25-0.32-0.37
		c-0.04-0.04-0.05-0.07,0-0.12C4.5,9.79,4.82,9.51,5.14,9.23c0.02-0.02,0.04-0.03,0.08-0.06c0.02,0.03,0.03,0.06,0.05,0.08
		c0.1,0.12,0.21,0.24,0.31,0.35c0.06,0.06,0.04,0.1-0.02,0.14c-0.31,0.27-0.61,0.55-0.92,0.82C4.59,10.61,4.56,10.63,4.5,10.56z
		 M6.31,10.56c-0.34,0.29-0.67,0.59-1.01,0.89c-0.13-0.14-0.24-0.28-0.37-0.41c-0.05-0.06-0.04-0.09,0.01-0.13
		c0.33-0.3,0.67-0.6,1-0.9c0.13,0.14,0.25,0.29,0.38,0.42C6.38,10.49,6.36,10.52,6.31,10.56z"
                    />
                    <path
                      d="M11.16,4.31c-0.09-0.1-0.21-0.16-0.36-0.15c-0.11,0-0.21,0.04-0.3,0.11c-0.53,0.47-1.07,0.94-1.6,1.42
		C8.69,5.88,8.68,6.14,8.87,6.36c0.38,0.43,0.76,0.86,1.14,1.29c0.2,0.22,0.46,0.24,0.69,0.04c0.53-0.46,1.05-0.93,1.57-1.39
		c0.22-0.2,0.24-0.46,0.05-0.68C11.94,5.18,11.55,4.74,11.16,4.31z M11.93,5.99c-0.49,0.44-0.99,0.88-1.48,1.32
		c-0.05,0.05-0.08,0.05-0.13,0c-0.35-0.4-0.7-0.79-1.05-1.19C9.2,6.05,9.22,6.02,9.28,5.96c0.49-0.43,0.97-0.86,1.46-1.29
		c0.06-0.05,0.09-0.06,0.14,0c0.34,0.39,0.69,0.78,1.04,1.18c0.02,0.02,0.04,0.05,0.05,0.07C11.98,5.96,11.95,5.97,11.93,5.99z"
                    />
                  </g>
                </svg>
                one time meeting request
              </Link>
            </li>
            {/* TODO uncomment ooo meeting */}
            <li>
              <Link
                onClick={countMessageCount}
                to={"/one-on-one-meeting-list"}
                className={
                  this.props.location.pathname === "/one-on-one-meeting-list" ||
                  this.props.match.path === "/one-on-one-meetings/:meetingId"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                >
                  <g>
                    <path d="M13.67,9.24c0.02,0.02,0.04,0.04,0.06,0.07c0-0.02,0.01-0.03,0.01-0.05C13.72,9.26,13.7,9.25,13.67,9.24z" />
                    <path
                      d="M6.72,13.72C6.72,13.72,6.72,13.73,6.72,13.72C6.72,13.73,6.72,13.73,6.72,13.72C6.73,13.73,6.72,13.73,6.72,13.72
		C6.72,13.72,6.72,13.72,6.72,13.72z"
                    />
                    <path
                      d="M4.04,15.78c0.17-0.02,0.28-0.12,0.26-0.26c-0.01-0.15-0.13-0.23-0.32-0.2c-0.32,0.05-0.63,0.02-0.93-0.09
		c-0.12-0.04-0.23-0.1-0.36-0.18c0.15-0.14,0.29-0.28,0.43-0.42c0.12-0.11,0.22-0.24,0.27-0.4c0.02-0.08,0.05-0.06,0.11-0.04
		c0.41,0.19,0.75,0.14,1.07-0.18c0.5-0.5,1-1.01,1.51-1.51c0.26-0.26,0.64-0.18,0.75,0.14c0.05,0.16,0.02,0.31-0.1,0.42
		c-0.63,0.63-1.24,1.27-1.89,1.88c-0.13,0.12-0.16,0.24-0.07,0.35c0.09,0.11,0.23,0.11,0.36-0.01c0.34-0.29,0.64-0.61,0.94-0.93
		c0.18-0.19,0.33-0.25,0.6-0.19c0.99,0.24,1.98,0.2,2.97-0.07c0.09-0.02,0.14-0.01,0.2,0.05c0.29,0.3,0.59,0.59,0.88,0.88
		c0.79,0.78,1.91,0.92,2.86,0.34c0.07-0.04,0.1-0.04,0.17,0.01c0.38,0.32,0.88,0.29,1.24-0.05c0.14-0.13,0.28-0.27,0.41-0.41
		c0.35-0.36,0.37-0.86,0.06-1.24c-0.04-0.04-0.07-0.07-0.03-0.14c0.18-0.27,0.3-0.57,0.35-0.9c0-0.01,0.02-0.02,0.03-0.03
		c0-0.05,0-0.1,0-0.15c-0.06-0.11-0.02-0.23-0.03-0.34c0,0,0,0,0,0c0-0.05,0-0.09,0-0.14c-0.01,0-0.01,0-0.02-0.01
		c-0.06-0.16-0.08-0.32-0.14-0.48c-0.12-0.3-0.28-0.56-0.51-0.78c-0.29-0.28-0.57-0.58-0.87-0.86c-0.14-0.13-0.16-0.26-0.12-0.44
		c0.13-0.5,0.17-1.02,0.18-1.54c0-0.17-0.04-0.34-0.03-0.52c0-0.05-0.02-0.09-0.04-0.13c-0.04-0.07-0.1-0.12-0.19-0.1
		c-0.08,0.02-0.13,0.08-0.14,0.16c-0.01,0.11-0.01,0.21,0,0.32c0.06,0.5,0,0.99-0.09,1.48c-0.02,0.08-0.04,0.13-0.07,0.14
		c0,0.02-0.01,0.03-0.01,0.05c-0.02-0.02-0.04-0.05-0.06-0.07c-0.02-0.01-0.05-0.03-0.08-0.06c-0.16-0.14-0.3-0.31-0.51-0.38
		c-0.38-0.14-0.79,0-1.01,0.32c-0.21,0.31-0.17,0.78,0.1,1.06c0.24,0.25,0.48,0.48,0.72,0.72c0.16,0.16,0.17,0.19,0.04,0.38
		c-0.99,1.44-2.35,2.3-4.08,2.58c-0.63,0.1-1.26,0.08-1.89-0.03c-0.02,0-0.04-0.01-0.06-0.01c-0.06-0.02-0.15-0.02-0.17-0.08
		c0,0-0.01,0-0.01,0c0,0,0,0,0.01-0.01c-0.02-0.07,0.05-0.11,0.1-0.16c0.07-0.07,0.14-0.14,0.2-0.21c0.32-0.34,0.31-0.85-0.02-1.18
		c-0.3-0.31-0.84-0.32-1.16-0.02c-0.26,0.24-0.5,0.49-0.75,0.74c-0.16,0.16-0.18,0.16-0.36,0.04c-1.47-1-2.34-2.38-2.6-4.14
		C2.07,8.38,2.04,7.96,2.1,7.55c0.01-0.04,0-0.07,0-0.11c-0.01-0.1-0.07-0.17-0.18-0.18C1.81,7.26,1.75,7.33,1.74,7.43
		c-0.04,0.3-0.05,0.61-0.03,0.91c0.03,0.44,0.1,0.88,0.21,1.31c0.03,0.11-0.03,0.17-0.09,0.24c-0.29,0.29-0.59,0.58-0.87,0.87
		C0.62,11.1,0.4,11.5,0.33,11.97c-0.09,0.53-0.01,1.03,0.26,1.5c0.08,0.13,0.09,0.23-0.01,0.37c-0.26,0.36-0.21,0.79,0.09,1.12
		c0.13,0.14,0.27,0.28,0.41,0.41c0.34,0.32,0.79,0.35,1.17,0.07c0.09-0.06,0.16-0.08,0.25-0.02c0.24,0.16,0.51,0.25,0.79,0.31
		c0.04,0.01,0.11,0,0.11,0.08c0.07,0,0.14,0,0.21,0c0.06-0.05,0.12-0.05,0.18,0c0.05,0,0.1,0,0.15,0
		C3.96,15.76,4.01,15.78,4.04,15.78z M15.08,14.62c-0.13,0.14-0.27,0.28-0.42,0.42c-0.2,0.19-0.46,0.18-0.65-0.01
		c-0.27-0.26-0.53-0.53-0.8-0.8c-0.09-0.09-0.15-0.2-0.15-0.34c-0.01-0.13,0.04-0.24,0.13-0.33c0.14-0.14,0.28-0.28,0.42-0.42
		c0.19-0.18,0.45-0.18,0.64,0c0.28,0.27,0.55,0.54,0.82,0.82C15.27,14.16,15.27,14.41,15.08,14.62z M12.52,9.94
		c-0.14-0.14-0.21-0.3-0.14-0.5c0.1-0.29,0.46-0.38,0.7-0.17c0.17,0.15,0.33,0.32,0.49,0.48c0.41,0.41,0.83,0.82,1.24,1.24
		c0.6,0.61,0.72,1.5,0.31,2.21c-0.01,0.03-0.03,0.05-0.06,0.1c-0.15-0.15-0.29-0.3-0.43-0.44c-0.11-0.11-0.23-0.21-0.37-0.25
		c-0.07-0.02-0.08-0.04-0.05-0.11c0.19-0.4,0.13-0.75-0.17-1.06C13.53,10.94,13.02,10.44,12.52,9.94z M12.29,12.66
		c0.07-0.06,0.11-0.05,0.16,0.01c0.04,0.05,0.09,0.09,0.15,0.1c0.11,0.02,0.19-0.01,0.25-0.11c0.06-0.1,0.05-0.21-0.04-0.28
		c-0.12-0.1-0.09-0.16,0-0.26c0.2-0.21,0.37-0.46,0.53-0.7c0.15,0.14,0.31,0.27,0.44,0.42c0.13,0.15,0.12,0.36-0.02,0.51
		c-0.44,0.45-0.88,0.89-1.33,1.33c-0.16,0.16-0.38,0.15-0.55,0c-0.13-0.12-0.25-0.25-0.39-0.38C11.77,13.1,12.04,12.89,12.29,12.66z
		 M11.01,13.57c0.06-0.04,0.09,0,0.13,0.03c0.12,0.12,0.24,0.24,0.36,0.36c0.31,0.3,0.65,0.37,1.04,0.19
		c0.03-0.01,0.07-0.08,0.1,0.01c0.09,0.29,0.33,0.46,0.52,0.66c0.06,0.06,0.12,0.11,0.18,0.17c-0.54,0.4-1.42,0.4-2.02-0.03
		c-0.41-0.29-0.71-0.69-1.09-1.04C10.52,13.82,10.77,13.71,11.01,13.57z M2.75,11.55c0.18,0.27,0.38,0.53,0.61,0.76
		c0.05,0.05,0.04,0.08-0.01,0.12c-0.07,0.06-0.12,0.12-0.12,0.22c0,0.09,0.05,0.16,0.13,0.2c0.09,0.05,0.19,0.03,0.25-0.05
		c0.1-0.14,0.17-0.09,0.27,0c0.22,0.2,0.46,0.36,0.71,0.54c-0.14,0.14-0.26,0.26-0.38,0.38c-0.18,0.17-0.41,0.18-0.59,0
		c-0.43-0.43-0.87-0.86-1.29-1.29c-0.18-0.18-0.17-0.4,0-0.58c0.1-0.11,0.21-0.21,0.31-0.31C2.69,11.49,2.71,11.49,2.75,11.55z
		 M1.19,11.13c0.28-0.3,0.58-0.58,0.89-0.87c0.1,0.25,0.21,0.5,0.34,0.73c0.03,0.06,0.03,0.09-0.02,0.14
		c-0.1,0.09-0.19,0.19-0.28,0.28c-0.41,0.41-0.46,0.69-0.23,1.21c-0.39,0.11-0.59,0.46-0.89,0.71c-0.17-0.24-0.25-0.51-0.27-0.79
		C0.67,12.01,0.82,11.53,1.19,11.13z M2.03,15.08c-0.19,0.18-0.45,0.18-0.64,0c-0.14-0.13-0.28-0.27-0.41-0.41
		c-0.2-0.21-0.2-0.46,0-0.66c0.27-0.28,0.54-0.55,0.82-0.82c0.09-0.09,0.19-0.13,0.31-0.13c0.13,0,0.24,0.04,0.33,0.13
		c0.14,0.14,0.28,0.27,0.41,0.41c0.2,0.21,0.2,0.46,0,0.66C2.58,14.54,2.3,14.81,2.03,15.08z"
                    />
                    <path
                      d="M12.16,8.95c-0.36,0.36-0.35,0.91,0.03,1.29c0.24,0.24,0.48,0.49,0.72,0.72C13,11.05,13,11.1,12.93,11.2
		c-1.34,1.92-3.18,2.8-5.51,2.65c-0.23-0.02-0.45-0.05-0.7-0.12c0.03,0.07,0.11,0.07,0.17,0.08c0.02,0,0.04,0.01,0.06,0.01
		c0.63,0.11,1.26,0.13,1.89,0.03c1.73-0.28,3.09-1.14,4.08-2.58c0.13-0.19,0.12-0.21-0.04-0.38c-0.24-0.24-0.49-0.48-0.72-0.72
		c-0.27-0.28-0.31-0.74-0.1-1.06c0.22-0.33,0.63-0.46,1.01-0.32c0.21,0.08,0.35,0.24,0.51,0.38c0.03,0.03,0.05,0.04,0.08,0.06
		c-0.08-0.09-0.15-0.18-0.23-0.25C13.06,8.61,12.52,8.6,12.16,8.95z"
                    />
                    <path
                      d="M15.13,10.68c-0.3-0.31-0.61-0.61-0.92-0.92c-0.07-0.07-0.09-0.13-0.07-0.24c0.18-0.71,0.23-1.43,0.16-2.16
		c-0.02-0.18-0.12-0.29-0.26-0.27c-0.14,0.02-0.21,0.12-0.2,0.31c0.01,0.21,0.03,0.43,0.03,0.64c0,0.39-0.04,0.79-0.14,1.21
		c0.03-0.01,0.06-0.06,0.07-0.14c0.09-0.49,0.15-0.98,0.09-1.48c-0.01-0.11-0.01-0.21,0-0.32c0.01-0.08,0.06-0.14,0.14-0.16
		c0.09-0.02,0.14,0.03,0.19,0.1c0.03,0.04,0.04,0.08,0.04,0.13c0,0.17,0.04,0.34,0.03,0.52c0,0.52-0.05,1.04-0.18,1.54
		c-0.05,0.18-0.02,0.31,0.12,0.44c0.29,0.28,0.57,0.58,0.87,0.86c0.23,0.22,0.39,0.49,0.51,0.78c0.06,0.16,0.08,0.32,0.14,0.48
		C15.77,12,15.78,12,15.78,12c0,0,0-0.01,0-0.01C15.72,11.48,15.49,11.05,15.13,10.68z"
                    />
                    <path
                      d="M3.28,15.73C3,15.67,2.74,15.58,2.5,15.42c-0.09-0.06-0.16-0.04-0.25,0.02c-0.38,0.28-0.82,0.25-1.17-0.07
		c-0.14-0.13-0.28-0.27-0.41-0.41c-0.3-0.32-0.35-0.76-0.09-1.12c0.1-0.14,0.09-0.23,0.01-0.37c-0.27-0.47-0.35-0.97-0.26-1.5
		c0.08-0.47,0.3-0.87,0.63-1.21c0.29-0.29,0.58-0.58,0.87-0.87c0.07-0.07,0.12-0.14,0.09-0.24C1.81,9.22,1.74,8.78,1.71,8.34
		C1.69,8.03,1.69,7.73,1.74,7.43c0.01-0.1,0.08-0.17,0.19-0.16C2.03,7.27,2.09,7.34,2.1,7.44c0,0.04,0.01,0.07,0,0.11
		C2.04,7.96,2.07,8.38,2.13,8.79c0.26,1.76,1.13,3.14,2.6,4.14c0.18,0.12,0.2,0.12,0.36-0.04c0.25-0.25,0.49-0.5,0.75-0.74
		c0.32-0.3,0.86-0.29,1.16,0.02c0.33,0.33,0.34,0.84,0.02,1.18c-0.07,0.07-0.14,0.14-0.2,0.21c-0.04,0.05-0.12,0.09-0.1,0.16
		c0.11-0.1,0.2-0.19,0.29-0.28c0.37-0.38,0.39-0.92,0.04-1.28c-0.36-0.36-0.91-0.35-1.29,0.03c-0.25,0.25-0.5,0.49-0.74,0.75
		C4.95,13,4.9,13,4.82,12.95c-0.87-0.57-1.56-1.31-2.04-2.24C2.27,9.72,2.05,8.67,2.14,7.55c0.02-0.21-0.06-0.33-0.22-0.33
		C1.78,7.21,1.7,7.31,1.68,7.52C1.62,8.24,1.7,8.95,1.88,9.65C1.9,9.73,1.89,9.78,1.83,9.83c-0.3,0.3-0.6,0.59-0.9,0.89
		c-0.77,0.78-0.91,1.92-0.33,2.85c0.04,0.06,0.05,0.1-0.01,0.17c-0.32,0.38-0.3,0.88,0.05,1.24c0.13,0.13,0.26,0.26,0.39,0.39
		c0.38,0.37,0.86,0.4,1.27,0.07c0.05-0.04,0.09-0.05,0.14-0.01c0.15,0.1,0.32,0.18,0.49,0.24c0.15,0.05,0.31,0.09,0.46,0.13
		C3.39,15.73,3.33,15.74,3.28,15.73z"
                    />
                    <path
                      d="M15.79,12.15c0.01,0.11-0.02,0.23,0.02,0.34c0-0.11,0-0.22,0-0.34C15.8,12.15,15.8,12.15,15.79,12.15
		C15.79,12.15,15.79,12.15,15.79,12.15z"
                    />
                    <path
                      d="M15.79,12.15C15.79,12.15,15.79,12.15,15.79,12.15c0,0-0.01-0.01-0.01-0.01c0.01,0.11-0.04,0.23,0.03,0.34
		C15.78,12.37,15.8,12.26,15.79,12.15z"
                    />
                    <path d="M3.61,15.81c0.06,0,0.12,0,0.18,0C3.73,15.78,3.67,15.78,3.61,15.81z" />
                    <path d="M3.61,15.81c0.06-0.02,0.12-0.02,0.18,0C3.73,15.76,3.67,15.76,3.61,15.81z" />
                    <path d="M15.78,12c0.02,0.05,0.01,0.1,0.01,0.14c0.01,0,0.01,0,0.02,0c0-0.05,0-0.1,0-0.15C15.8,12,15.79,12,15.78,12z" />
                    <path d="M15.78,12c0.01,0,0.02,0,0.03,0C15.8,11.99,15.79,11.99,15.78,12C15.78,11.99,15.78,12,15.78,12z" />
                    <path
                      d="M15.79,12.14c0-0.05,0.01-0.1-0.01-0.14c0,0,0,0,0,0c0,0.05,0,0.09,0,0.14c0,0,0,0,0,0c0,0,0,0,0,0
		C15.79,12.14,15.79,12.14,15.79,12.14z"
                    />
                    <polygon points="15.78,11.99 15.78,12 15.78,12 	" />
                    <path d="M15.79,12.14c0.01,0,0.01,0,0.02,0C15.8,12.14,15.8,12.14,15.79,12.14L15.79,12.14z" />
                    <path d="M15.79,12.14c0,0-0.01-0.01-0.01,0c0,0,0,0,0,0C15.79,12.14,15.79,12.14,15.79,12.14L15.79,12.14z" />
                    <path
                      d="M2.58,7.02c0.37,0.37,0.92,0.37,1.27,0.02C4.2,6.69,4.19,6.14,3.83,5.76C3.57,5.5,3.32,5.25,3.06,5
		C3,4.94,2.99,4.9,3.04,4.83C3.21,4.57,3.39,4.32,3.6,4.09c1.09-1.2,2.45-1.85,4.06-1.97C8.2,2.09,8.73,2.14,9.31,2.27
		c-0.13,0.12-0.23,0.2-0.32,0.3C8.61,2.95,8.6,3.5,8.97,3.85c0.36,0.35,0.91,0.34,1.28-0.04c0.25-0.24,0.49-0.48,0.73-0.74
		C11.06,3,11.11,3,11.2,3.06c1.16,0.77,1.95,1.82,2.4,3.13c0.09,0.26,0.25,0.3,0.45,0.1c0.34-0.34,0.68-0.67,1.01-1.01
		c0.78-0.79,0.92-1.91,0.34-2.86c-0.04-0.07-0.04-0.1,0.01-0.17c0.32-0.38,0.29-0.88-0.05-1.24c-0.13-0.14-0.27-0.28-0.41-0.41
		c-0.36-0.35-0.85-0.37-1.24-0.05c-0.06,0.05-0.09,0.05-0.15,0.02c-1-0.6-2.09-0.47-2.92,0.36c-0.3,0.29-0.59,0.59-0.88,0.89
		C9.69,1.86,9.64,1.88,9.56,1.86C8.5,1.59,7.43,1.59,6.37,1.87c-0.1,0.03-0.16,0-0.22-0.06c-0.3-0.31-0.6-0.61-0.91-0.91
		C4.47,0.16,3.34,0.04,2.42,0.6C2.35,0.65,2.31,0.64,2.24,0.59C1.88,0.28,1.37,0.3,1.03,0.63C0.88,0.77,0.74,0.91,0.59,1.06
		C0.25,1.41,0.23,1.91,0.54,2.29C0.6,2.36,0.59,2.4,0.55,2.47C-0.02,3.38,0.1,4.53,0.85,5.3C1.42,5.88,2,6.45,2.58,7.02z
		 M13.24,4.44c-0.17-0.26-0.37-0.51-0.59-0.74c-0.06-0.06-0.05-0.09,0.01-0.14c0.09-0.08,0.15-0.17,0.09-0.29
		c-0.06-0.16-0.25-0.2-0.37-0.06c-0.1,0.11-0.16,0.09-0.25,0c-0.22-0.2-0.47-0.37-0.73-0.55c0.13-0.13,0.25-0.25,0.37-0.36
		c0.19-0.18,0.42-0.19,0.61-0.01c0.43,0.42,0.86,0.85,1.28,1.28c0.18,0.18,0.17,0.4,0.01,0.58c-0.1,0.11-0.21,0.21-0.31,0.31
		C13.31,4.52,13.28,4.5,13.24,4.44z M15.12,4.41c-0.07,0.17-0.17,0.32-0.3,0.45c-0.3,0.29-0.6,0.58-0.89,0.87
		c-0.11-0.24-0.23-0.5-0.36-0.75c-0.03-0.06,0-0.08,0.03-0.11c0.12-0.12,0.25-0.24,0.37-0.37c0.3-0.31,0.36-0.65,0.19-1.04
		c-0.01-0.03-0.08-0.07,0.01-0.1c0.29-0.09,0.46-0.33,0.66-0.52c0.06-0.06,0.1-0.12,0.17-0.2C15.35,3.23,15.39,3.81,15.12,4.41z
		 M13.97,0.93c0.19-0.19,0.45-0.19,0.64-0.02c0.15,0.14,0.3,0.29,0.44,0.44c0.08,0.08,0.12,0.18,0.12,0.3
		C15.18,1.79,15.12,1.9,15.03,2c-0.27,0.27-0.54,0.54-0.81,0.81c-0.2,0.19-0.46,0.19-0.67,0c-0.14-0.13-0.27-0.26-0.4-0.4
		c-0.19-0.2-0.2-0.46,0-0.66C13.43,1.46,13.69,1.19,13.97,0.93z M11.12,1.1c0.58-0.54,1.57-0.6,2.19-0.14
		c-0.14,0.13-0.27,0.26-0.41,0.39c-0.13,0.12-0.24,0.25-0.29,0.42c-0.02,0.07-0.05,0.06-0.09,0.03c-0.38-0.21-0.81-0.11-1.1,0.2
		c-0.49,0.51-0.99,1-1.48,1.49C9.66,3.75,9.28,3.67,9.18,3.35c-0.05-0.16-0.02-0.3,0.1-0.42C9.89,2.31,10.49,1.69,11.12,1.1z
		 M4.43,2.76c-0.26,0.17-0.5,0.36-0.72,0.58C3.64,3.4,3.61,3.39,3.55,3.33C3.42,3.2,3.3,3.19,3.19,3.29
		c-0.1,0.1-0.09,0.23,0.04,0.36c0.06,0.06,0.08,0.1,0.01,0.17c-0.22,0.23-0.4,0.49-0.59,0.78C2.52,4.46,2.39,4.34,2.28,4.21
		c-0.17-0.19-0.18-0.41,0-0.6c0.42-0.43,0.85-0.86,1.28-1.28c0.18-0.17,0.4-0.17,0.58,0c0.1,0.1,0.2,0.2,0.3,0.3
		C4.52,2.69,4.5,2.72,4.43,2.76z M2.9,0.86c0.71-0.29,1.37-0.2,1.94,0.3c0.32,0.28,0.59,0.6,0.89,0.91c-0.24,0.1-0.49,0.2-0.72,0.33
		C4.94,2.45,4.9,2.44,4.85,2.39C4.73,2.26,4.61,2.13,4.48,2.01C4.19,1.73,3.84,1.67,3.47,1.84c-0.03,0.01-0.08,0.09-0.1,0
		C3.27,1.55,3.03,1.37,2.83,1.17C2.64,0.97,2.64,0.97,2.9,0.86z M0.93,1.37C1.06,1.23,1.2,1.09,1.34,0.96
		c0.19-0.18,0.45-0.18,0.64,0c0.28,0.27,0.55,0.55,0.83,0.83C2.9,1.88,2.94,1.98,2.94,2.1C2.95,2.24,2.9,2.34,2.81,2.44
		C2.67,2.58,2.53,2.72,2.39,2.85c-0.19,0.18-0.45,0.18-0.64,0C1.47,2.59,1.2,2.31,0.93,2.04C0.73,1.83,0.73,1.58,0.93,1.37z
		 M0.95,2.69C1.08,2.82,1.2,2.95,1.31,3.07C1.45,3.21,1.59,3.34,1.78,3.4c0.06,0.02,0.06,0.04,0.03,0.09
		C1.59,3.84,1.68,4.29,2.03,4.62c0.49,0.46,0.96,0.95,1.44,1.43c0.08,0.08,0.15,0.16,0.17,0.28C3.67,6.52,3.59,6.7,3.42,6.79
		C3.26,6.88,3.07,6.86,2.93,6.73C2.31,6.12,1.69,5.52,1.1,4.88C0.55,4.3,0.51,3.38,0.95,2.69z"
                    />
                    <path
                      d="M5.54,8.86C5.35,8.65,5.11,8.64,4.9,8.83C4.54,9.14,4.18,9.46,3.83,9.78c-0.22,0.2-0.24,0.44-0.04,0.67
		c0.39,0.44,0.78,0.88,1.17,1.32c0.18,0.2,0.42,0.21,0.62,0.04c0.37-0.32,0.74-0.65,1.11-0.98c0.09-0.08,0.15-0.19,0.15-0.33
		c0.01-0.11-0.04-0.21-0.12-0.29C6.33,9.75,5.94,9.3,5.54,8.86z M4.5,10.56c-0.1-0.13-0.21-0.25-0.32-0.37
		c-0.04-0.04-0.05-0.07,0-0.12C4.5,9.79,4.82,9.51,5.14,9.23c0.02-0.02,0.04-0.03,0.08-0.06c0.02,0.03,0.03,0.06,0.05,0.08
		c0.1,0.12,0.21,0.24,0.31,0.35c0.06,0.06,0.04,0.1-0.02,0.14c-0.31,0.27-0.61,0.55-0.92,0.82C4.59,10.61,4.56,10.63,4.5,10.56z
		 M6.31,10.56c-0.34,0.29-0.67,0.59-1.01,0.89c-0.13-0.14-0.24-0.28-0.37-0.41c-0.05-0.06-0.04-0.09,0.01-0.13
		c0.33-0.3,0.67-0.6,1-0.9c0.13,0.14,0.25,0.29,0.38,0.42C6.38,10.49,6.36,10.52,6.31,10.56z"
                    />
                    <path
                      d="M11.16,4.31c-0.09-0.1-0.21-0.16-0.36-0.15c-0.11,0-0.21,0.04-0.3,0.11c-0.53,0.47-1.07,0.94-1.6,1.42
		C8.69,5.88,8.68,6.14,8.87,6.36c0.38,0.43,0.76,0.86,1.14,1.29c0.2,0.22,0.46,0.24,0.69,0.04c0.53-0.46,1.05-0.93,1.57-1.39
		c0.22-0.2,0.24-0.46,0.05-0.68C11.94,5.18,11.55,4.74,11.16,4.31z M11.93,5.99c-0.49,0.44-0.99,0.88-1.48,1.32
		c-0.05,0.05-0.08,0.05-0.13,0c-0.35-0.4-0.7-0.79-1.05-1.19C9.2,6.05,9.22,6.02,9.28,5.96c0.49-0.43,0.97-0.86,1.46-1.29
		c0.06-0.05,0.09-0.06,0.14,0c0.34,0.39,0.69,0.78,1.04,1.18c0.02,0.02,0.04,0.05,0.05,0.07C11.98,5.96,11.95,5.97,11.93,5.99z"
                    />
                  </g>
                </svg>
                One On One Meeting list
                {this.props.unreadOMeetingCount > 0 && (
                  <span
                    className="badge badge-dark"
                    style={{ marginLeft: "10px" }}
                  >
                    {this.props.unreadOMeetingCount}
                  </span>
                )}
              </Link>
            </li>
            <li>
              <Link
                onClick={countMessageCount}
                to={"/order-return-request"}
                className={
                  this.props.location.pathname === "/order-return-request"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 17 19"
                >
                  <desc>Created with Sketch.</desc>
                  <g>
                    <path
                      d="M14,3v0.5C14,3.8,13.8,4,13.5,4S13,3.8,13,3.5V3H9v0.5C9,3.8,8.8,4,8.5,4S8,3.8,8,3.5V3H4v0.5C4,3.8,3.8,4,3.5,4
		S3,3.8,3,3.5V3H1.5C1.2,3,1,3.2,1,3.5V5h15V3.5C16,3.2,15.8,3,15.5,3H14z M14,2h1.5C16.3,2,17,2.7,17,3.5v14c0,0.8-0.7,1.5-1.5,1.5
		h-14C0.7,19,0,18.3,0,17.5v-14C0,2.7,0.7,2,1.5,2H3V0.5C3,0.2,3.2,0,3.5,0S4,0.2,4,0.5V2h4V0.5C8,0.2,8.2,0,8.5,0S9,0.2,9,0.5V2h4
		V0.5C13,0.2,13.2,0,13.5,0S14,0.2,14,0.5V2z M16,6H1v11.5C1,17.8,1.2,18,1.5,18h14c0.3,0,0.5-0.2,0.5-0.5V6z M12,12h-1.4
		c0,1-0.2,1.9-0.5,2.6C11.1,14.1,11.8,13.2,12,12L12,12z M12,11c-0.2-1.2-0.9-2.1-1.9-2.6c0.3,0.7,0.5,1.6,0.5,2.6H12L12,11z M5,12
		c0.2,1.2,0.9,2.1,1.9,2.6C6.6,13.9,6.5,13,6.4,12H5L5,12z M5,11h1.4c0-1,0.2-1.9,0.5-2.6C5.9,8.9,5.2,9.8,5,11L5,11z M9.6,12H7.4
		c0.1,1.7,0.6,3,1.1,3S9.5,13.7,9.6,12z M7.4,11h2.2C9.5,9.3,8.9,8,8.5,8S7.5,9.3,7.4,11z M13,11.5C13,14,11,16,8.5,16S4,14,4,11.5
		S6,7,8.5,7S13,9,13,11.5z"
                    />
                  </g>
                </svg>
                Order return request
                {this.props.pendingReturnRequestCount > 0 && (
                  <span
                    className="badge badge-dark"
                    style={{ marginLeft: "10px" }}
                  >
                    {this.props.pendingReturnRequestCount}
                  </span>
                )}
              </Link>
            </li>
            {/* <li>
              <Link
                onClick={countMessageCount}
                to={"/global-calendar"}
                className={
                  this.props.location.pathname === "/global-calendar"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 17 19"
                >
                  <desc>Created with Sketch.</desc>
                  <g>
                    <path
                      d="M14,3v0.5C14,3.8,13.8,4,13.5,4S13,3.8,13,3.5V3H9v0.5C9,3.8,8.8,4,8.5,4S8,3.8,8,3.5V3H4v0.5C4,3.8,3.8,4,3.5,4
		S3,3.8,3,3.5V3H1.5C1.2,3,1,3.2,1,3.5V5h15V3.5C16,3.2,15.8,3,15.5,3H14z M14,2h1.5C16.3,2,17,2.7,17,3.5v14c0,0.8-0.7,1.5-1.5,1.5
		h-14C0.7,19,0,18.3,0,17.5v-14C0,2.7,0.7,2,1.5,2H3V0.5C3,0.2,3.2,0,3.5,0S4,0.2,4,0.5V2h4V0.5C8,0.2,8.2,0,8.5,0S9,0.2,9,0.5V2h4
		V0.5C13,0.2,13.2,0,13.5,0S14,0.2,14,0.5V2z M16,6H1v11.5C1,17.8,1.2,18,1.5,18h14c0.3,0,0.5-0.2,0.5-0.5V6z M12,12h-1.4
		c0,1-0.2,1.9-0.5,2.6C11.1,14.1,11.8,13.2,12,12L12,12z M12,11c-0.2-1.2-0.9-2.1-1.9-2.6c0.3,0.7,0.5,1.6,0.5,2.6H12L12,11z M5,12
		c0.2,1.2,0.9,2.1,1.9,2.6C6.6,13.9,6.5,13,6.4,12H5L5,12z M5,11h1.4c0-1,0.2-1.9,0.5-2.6C5.9,8.9,5.2,9.8,5,11L5,11z M9.6,12H7.4
		c0.1,1.7,0.6,3,1.1,3S9.5,13.7,9.6,12z M7.4,11h2.2C9.5,9.3,8.9,8,8.5,8S7.5,9.3,7.4,11z M13,11.5C13,14,11,16,8.5,16S4,14,4,11.5
		S6,7,8.5,7S13,9,13,11.5z"
                    />
                  </g>
                </svg>
                global calendar
              </Link>
            </li> */}

            <li>
              <Link
                to={"/profile"}
                className={
                  this.props.location.pathname === "/profile"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                >
                  <g>
                    <path
                      d="M4.96,1.36C4.02,2.43,3.78,4.14,4.38,5.43C5.02,6.8,6.43,7.81,8.15,7.7c1.31-0.09,2.3-0.71,3.01-1.78
		c0.54-0.81,0.68-1.71,0.55-2.66c-0.19-1.45-1.4-2.8-2.82-3.13C8.68,0.08,8.47,0.04,8.27,0C8.03,0,7.79,0,7.55,0
		C7.4,0.03,7.25,0.05,7.1,0.08C6.24,0.26,5.54,0.71,4.96,1.36z M7.9,0.94c1.62,0,2.92,1.29,2.92,2.91c0.01,1.61-1.29,2.91-2.91,2.92
		C6.3,6.78,4.99,5.48,4.99,3.86C4.98,2.24,6.28,0.94,7.9,0.94z"
                    />
                    <path
                      d="M13.87,9.36c-0.49-0.89-1.21-1.45-2.23-1.6c-0.39-0.06-0.75-0.03-1.08,0.22c-0.17,0.13-0.36,0.24-0.54,0.35
		C8.68,9.16,7.34,9.16,6,8.33C5.77,8.19,5.55,8.03,5.31,7.9C5.17,7.82,5,7.74,4.84,7.74c-0.51-0.01-1,0.1-1.46,0.35
		C2.65,8.47,2.2,9.11,1.91,9.84c-0.5,1.24-0.66,2.55-0.54,3.87c0.06,0.74,0.43,1.34,1.04,1.77C2.94,15.87,3.55,16,4.19,16
		c1.27,0,2.54,0,3.81,0c1.24,0,2.49,0,3.73,0c0.18,0,0.36-0.01,0.55-0.03c1.4-0.14,2.37-1.18,2.38-2.58
		c0.01-0.47-0.02-0.95-0.06-1.42C14.52,11.06,14.31,10.17,13.87,9.36z M11.96,15.06c-1.32,0.01-2.65,0-3.97,0c0,0,0,0,0,0
		c-1.27,0-2.53,0-3.8,0c-0.39,0-0.77-0.05-1.11-0.25c-0.53-0.31-0.79-0.79-0.8-1.38c-0.03-1.07,0.07-2.13,0.46-3.14
		C2.89,9.89,3.1,9.53,3.4,9.22C3.77,8.86,4.22,8.7,4.72,8.67c0.05,0,0.1,0.02,0.14,0.05c0.21,0.13,0.41,0.26,0.62,0.39
		C6.35,9.67,7.3,9.95,8.33,9.87c0.76-0.06,1.46-0.3,2.11-0.71c0.23-0.15,0.46-0.3,0.7-0.44c0.06-0.03,0.14-0.05,0.2-0.05
		c0.81,0.05,1.35,0.49,1.73,1.18c0.36,0.67,0.51,1.39,0.6,2.13c0.05,0.41,0.06,0.82,0.07,1.23C13.75,14.34,13.07,15.05,11.96,15.06z
		"
                    />
                  </g>
                </svg>
                profile
              </Link>
            </li>
            {!localStorage.getItem("socialLogin") && (
              <li>
                <Link
                  onClick={countMessageCount}
                  to={"/change-password"}
                  className={
                    this.props.location.pathname === "/change-password"
                      ? "active"
                      : "null"
                  }
                >
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 16 16"
                  >
                    <g>
                      <path
                        d="M14.01,14.43c0-2.29,0-4.58,0-6.87c0-0.91-0.65-1.55-1.56-1.55c-0.14,0-0.29,0-0.44,0c0-0.65,0.01-1.28,0-1.91
		c0-0.26-0.02-0.52-0.06-0.78c-0.33-2.11-2.42-3.61-4.53-3.27C5.41,0.37,4,2.02,4,4.05c0,0.59,0,1.19,0,1.78c0,0.05,0,0.11,0,0.17
		c-0.19,0-0.37,0-0.54,0C2.66,6.02,2,6.67,2,7.46c-0.01,2.36-0.01,4.72,0,7.07c0,0.6,0.31,1.05,0.86,1.31
		c0.13,0.07,0.28,0.1,0.42,0.15c3.15,0,6.3,0,9.45,0c0.01-0.01,0.02-0.02,0.04-0.02C13.55,15.8,14.01,15.23,14.01,14.43z M5,5.85
		c0-0.61,0-1.22,0-1.83C5,2.29,6.41,0.94,8.16,1.01c1.5,0.06,2.77,1.3,2.84,2.81C11.03,4.54,11,5.26,11,6C8.99,6,7,6,5,6
		C5,5.94,5,5.9,5,5.85z M12.43,15c-1.48,0-2.95,0-4.43,0c-1.47,0-2.94,0-4.41,0c-0.41,0-0.6-0.19-0.6-0.59c0-2.27,0-4.55,0-6.82
		C2.99,7.2,3.19,7,3.58,7c2.95,0,5.89,0,8.84,0c0.4,0,0.59,0.19,0.59,0.58c0,2.28,0,4.56,0,6.84C13.01,14.81,12.81,15,12.43,15z"
                      />
                      <path
                        d="M7.83,8.68C7.26,8.76,6.79,9.2,6.69,9.75c-0.1,0.59,0.17,1.16,0.69,1.43c0.1,0.05,0.12,0.1,0.12,0.2
		c-0.01,0.23,0,0.47,0,0.7c0,0.23,0,0.47,0,0.7c0,0.32,0.2,0.55,0.49,0.55c0.29,0.01,0.51-0.23,0.51-0.55c0-0.46,0.01-0.92,0-1.37
		c0-0.12,0.04-0.19,0.15-0.25c0.57-0.31,0.82-0.97,0.62-1.58C9.07,8.98,8.47,8.6,7.83,8.68z M8,10.34c-0.18,0-0.34-0.16-0.33-0.34
		C7.67,9.82,7.83,9.67,8,9.67c0.18,0,0.33,0.15,0.33,0.33C8.34,10.18,8.18,10.34,8,10.34z"
                      />
                      <path d="M7.5,11.38c-0.01,0.23,0,0.47,0,0.7c0,0.23,0,0.47,0,0.7c0-0.23,0-0.47,0-0.7C7.5,11.85,7.5,11.61,7.5,11.38z" />
                    </g>
                  </svg>
                  change password
                </Link>
              </li>
            )}

            <li>
              <Link
                onClick={countMessageCount}
                to={"/subscription-packages"}
                className={
                  this.props.location.pathname === "/subscription-packages"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                >
                  <g>
                    <path
                      d="M507.85,378.35c-1.87-2.23-4.25-5.16-6.69-5.45c-2.11-0.25-5.08,2.38-6.77,4.47c-1.11,1.37-0.79,4.04-0.8,6.13
		c-0.05,19.73,0.02,39.45-0.07,59.18c-0.02,4.39-0.09,8.86-0.94,13.14c-5.08,25.4-25.57,41.42-52.95,41.43
		c-108.15,0.04-216.29,0.02-324.44,0c-1.66,0-3.31-0.19-5.38-0.32c0.62-0.93,0.83-1.4,1.17-1.72
		c14.75-13.83,21.16-30.86,21.06-51.04c-0.28-54.95-0.1-109.9-0.1-164.85c0-86.13,0-172.26,0-258.39c0-1.88,0-3.75,0-5.87
		c120.76,0,240.81,0,361.61,0c0,2.86,0,5.13,0,7.4c0,51.96,0,103.92,0.01,155.88c0,1.93-0.1,3.9,0.22,5.79
		c0.72,4.22,3.29,6.65,7.67,6.35c4.08-0.28,6.31-2.78,6.64-6.86c0.11-1.4,0.07-2.82,0.07-4.22c0-56.01,0-112.02,0-168.03
		c0-9.6-1.62-11.17-11.46-11.17c-122.59,0-245.18,0-367.77,0c-1.58,0-3.55-0.56-4.68,0.16c-2.43,1.56-4.98,3.47-6.44,5.87
		c-1.04,1.71-0.45,4.46-0.45,6.74c-0.01,85.95-0.01,171.91-0.01,257.86c0,2.22,0,4.44,0,7.21c-2.81,0-4.89,0-6.96,0
		c-32.41,0-64.82-0.02-97.23,0.02c-6.99,0.01-9.37,2.3-9.37,9.03c-0.02,54.07-0.27,108.15,0.14,162.22
		c0.17,22.78,10.96,40.38,29.97,52.78c12.57,8.2,26.62,9.93,41.4,9.9c121.71-0.21,243.42-0.11,365.13-0.12
		c39.13,0,67.61-28.42,67.73-67.59c0.06-20.43,0.01-40.86,0-61.29C508.15,381.41,508.65,379.32,507.85,378.35z M79.93,495.16
		c-30.22,7.75-60.75-14.38-61.24-45.56c-0.69-43.82-0.24-87.65-0.27-131.48c-0.01-8.24,0-16.47,0-25.11c33.34,0,65.83,0,98.64,0
		c0.11,2.01,0.28,3.72,0.28,5.43c0.01,48.93,0.07,97.87-0.02,146.8C117.28,470.19,102.85,489.28,79.93,495.16z"
                    />
                    <path
                      d="M431.96,385.9c8.27,0,10.48-2.3,10.49-10.77c0.01-17.61,0-35.21,0-52.82c0-18.14,0.02-36.27-0.01-54.41
		c-0.01-7.54-2.27-9.91-9.61-9.92c-32.57-0.03-65.15-0.03-97.72,0c-7.39,0.01-9.75,2.37-9.75,9.84c-0.03,35.92-0.03,71.84,0,107.76
		c0.01,7.99,2.39,10.32,10.46,10.33C367.87,385.91,399.92,385.91,431.96,385.9z M340.39,272.78c29.06,0,57.86,0,87.18,0
		c0,32.91,0,65.27,0,98.05c-28.94,0-57.87,0-87.18,0C340.39,338.29,340.39,305.68,340.39,272.78z"
                    />
                    <path
                      d="M168.44,131.95c-3.95,0.83-6.23,3.34-6,7.45c0.23,4.06,2.63,6.4,6.71,6.78c1.75,0.16,3.52,0.07,5.28,0.07
		c45.41,0,90.82,0,136.23,0c45.59,0,91.17,0,136.76,0c1.76,0,3.54,0.11,5.28-0.09c4.09-0.47,6.41-2.99,6.56-6.97
		c0.14-3.78-1.99-6.33-5.73-7.16c-1.69-0.37-3.49-0.34-5.25-0.34c-91.7-0.01-183.4-0.01-275.1,0
		C171.59,131.7,169.97,131.63,168.44,131.95z"
                    />
                    <path
                      d="M168.44,52.45c-3.95,0.83-6.23,3.34-6,7.45c0.23,4.06,2.63,6.4,6.71,6.78c1.75,0.16,3.52,0.07,5.28,0.07
		c45.41,0,90.82,0,136.23,0c45.59,0,91.17,0,136.76,0c1.76,0,3.54,0.11,5.28-0.09c4.09-0.47,6.41-2.99,6.56-6.97
		c0.14-3.78-1.99-6.33-5.73-7.16c-1.69-0.37-3.49-0.34-5.25-0.34c-91.7-0.01-183.4-0.01-275.1,0
		C171.59,52.19,169.97,52.13,168.44,52.45z"
                    />
                    <path
                      d="M169.06,438.77c1.55,0.24,3.16,0.1,4.75,0.1c45.59,0,91.18,0,136.77,0c4.4,0,8.8,0,13.2,0c42.07,0,84.14,0.01,126.21-0.01
		c5.79,0,9.16-2.66,9.33-7.09c0.17-4.6-3.41-7.5-9.36-7.61c-0.7-0.01-1.41,0-2.11,0c-91.35,0-182.71,0-274.06,0
		c-1.58,0-3.21-0.16-4.74,0.11c-4.13,0.74-6.75,3.13-6.59,7.53C162.58,435.93,165.12,438.17,169.06,438.77z"
                    />
                    <path
                      d="M447.88,204.86c-91.34-0.01-182.68-0.01-274.02,0c-8.12,0-11.62,2.32-11.47,7.52c0.15,5.05,3.31,7.02,11.37,7.02
		c45.58,0,91.17,0,136.75,0c45.76,0,91.52,0,137.28,0c8.11,0,11.69-2.33,11.53-7.46C459.15,207,455.74,204.86,447.88,204.86z"
                    />
                    <path
                      d="M293.17,351.13c-40.11,0-80.21-0.01-120.32,0c-1.58,0-3.62-0.5-4.65,0.28c-2.2,1.66-5.29,3.94-5.41,6.1
		c-0.13,2.44,2.2,5.48,4.25,7.4c1.23,1.15,4,0.87,6.07,0.88c19.88,0.05,39.76,0.03,59.63,0.03c19.88,0,39.76,0.01,59.63-0.01
		c1.75,0,3.57,0.08,5.24-0.33c3.92-0.98,5.99-3.63,5.66-7.72c-0.31-3.86-2.67-5.99-6.43-6.54
		C295.65,351.04,294.41,351.13,293.17,351.13z"
                    />
                    <path
                      d="M297.47,278.36c-1.14-0.87-3.41-0.31-5.16-0.31c-19.7-0.01-39.39-0.01-59.09-0.01c-20.4,0-40.8-0.02-61.2,0.01
		c-6.41,0.01-9.59,2.43-9.64,7.15c-0.04,4.71,3.26,7.31,9.48,7.47c0.35,0.01,0.7,0,1.06,0c39.92,0,79.84,0.01,119.77-0.02
		c1.91,0,4.45,0.34,5.61-0.7c2.12-1.89,4.81-4.78,4.67-7.1C302.82,282.55,299.76,280.1,297.47,278.36z"
                    />
                    <path
                      d="M508.09,307.16c-0.16-4.36-2.63-6.88-6.93-7.05c-4.06-0.16-7.06,2.09-7.26,6.09c-0.38,7.87-0.36,15.78,0.03,23.64
		c0.2,4.05,3.35,6.29,7.29,6.07c4.16-0.23,6.75-2.77,6.88-7.18c0.1-3.33,0.02-6.67,0.02-10
		C508.12,314.88,508.23,311.02,508.09,307.16z"
                    />
                    <path
                      d="M508.12,234.97c-0.12-4.56-2.43-7.37-7.09-7.49c-4.43-0.12-7.09,2.57-7.28,6.76c-0.33,7.38-0.29,14.79,0.02,22.17
		c0.18,4.29,3.06,6.93,7.31,6.77c4.46-0.17,6.99-2.97,7.05-7.61c0.04-3.34,0.01-6.69,0.01-10.04
		C508.14,242.02,508.22,238.49,508.12,234.97z"
                    />
                  </g>
                </svg>
                subscription packages
              </Link>
            </li>
            {/* /vendor/shopify */}
            <li>
              <Link
                onClick={countMessageCount}
                to={"/vendor/store-sy"}
                className={
                  this.props.location.pathname === "/vendor/store-sy"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                >
                  <g>
                    <path d="M1.8,7.72c0-0.01,0.01-0.02,0.01-0.04C1.81,7.7,1.81,7.71,1.8,7.72C1.8,7.72,1.8,7.72,1.8,7.72z" />
                    <path d="M1.35,11.23c0.01,0.01,0.01,0.02,0,0.04C1.35,11.26,1.35,11.24,1.35,11.23C1.35,11.23,1.35,11.23,1.35,11.23z" />
                    <path
                      d="M10.61,1.89c-0.06,0.02-0.13,0.04-0.19,0.06C10.36,1.97,10.31,2,10.25,2c-0.04,0.01-0.07,0.02-0.11,0.03
		c-0.04,0.01-0.07,0-0.08-0.05c-0.06-0.06-0.06-0.14-0.09-0.21C9.89,1.6,9.81,1.43,9.71,1.27C9.69,1.25,9.67,1.22,9.65,1.2
		C9.56,1.07,9.46,0.96,9.36,0.85c-0.06,0-0.08-0.05-0.11-0.08C9.17,0.72,9.09,0.66,9.01,0.62c-0.1-0.04-0.19-0.07-0.29-0.11
		C8.67,0.5,8.61,0.5,8.56,0.49c-0.12,0-0.24,0.03-0.31-0.11c-0.12-0.1-0.23-0.2-0.37-0.27c-0.01,0-0.02,0-0.03,0
		c-0.06-0.02-0.13-0.05-0.2-0.06C7.63,0.04,7.6,0.03,7.59,0c0,0,0,0-0.01,0C7.52,0.03,7.44,0.04,7.37,0
		C7.37,0.01,7.37,0.02,7.36,0.02c-0.09,0-0.17,0.05-0.26,0.02c0,0,0,0,0,0C7.09,0.05,7.08,0.07,7.06,0.07C6.9,0.1,6.75,0.15,6.6,0.2
		c-0.01,0-0.03,0-0.04,0c0,0,0,0,0,0C6.47,0.23,6.39,0.28,6.31,0.33C6.19,0.4,6.07,0.48,5.95,0.56C5.94,0.57,5.93,0.59,5.91,0.6
		c0,0,0,0,0,0C5.87,0.67,5.8,0.71,5.73,0.76C5.69,0.8,5.64,0.85,5.6,0.89c0,0,0,0,0,0C5.55,0.94,5.53,1.01,5.47,1.04
		C5.46,1.05,5.45,1.06,5.44,1.07C5.36,1.2,5.27,1.32,5.17,1.43c0,0-0.01,0.01-0.01,0.01C5.1,1.54,5.04,1.65,4.97,1.75
		C4.96,1.77,4.95,1.8,4.94,1.82C4.89,1.87,4.9,1.95,4.83,1.98C4.79,2.07,4.76,2.15,4.72,2.24C4.7,2.29,4.68,2.35,4.64,2.4
		C4.62,2.44,4.62,2.5,4.58,2.53C4.54,2.62,4.51,2.71,4.47,2.8C4.45,2.88,4.43,2.95,4.39,3.02c0,0.02-0.01,0.04-0.02,0.05
		c-0.02,0.1-0.07,0.18-0.09,0.27c-0.02,0.1-0.04,0.2-0.09,0.3C4.19,3.74,4.16,3.82,4.12,3.9C4.11,3.91,4.09,3.91,4.08,3.91
		C3.9,3.99,3.72,4.03,3.54,4.09C3.48,4.11,3.41,4.13,3.35,4.15c-0.02,0-0.05,0-0.07,0C3.25,4.18,3.21,4.18,3.17,4.19c0,0,0,0,0,0
		C3.16,4.2,3.15,4.2,3.13,4.2c-0.09,0.07-0.2,0.07-0.29,0.1C2.76,4.33,2.69,4.37,2.61,4.37C2.57,4.38,2.54,4.39,2.5,4.41
		C2.46,4.43,2.41,4.44,2.37,4.46C2.23,4.55,2.2,4.7,2.19,4.85c0,0.02,0,0.05-0.01,0.07c0,0,0,0,0,0C2.16,5.04,2.15,5.16,2.12,5.28
		c0,0.04-0.01,0.08-0.01,0.12C2.09,5.61,2.06,5.82,2.04,6.02C2,6.33,1.95,6.64,1.91,6.95c-0.03,0.1-0.01,0.2-0.05,0.3
		C1.86,7.32,1.85,7.38,1.84,7.45C1.83,7.53,1.82,7.61,1.82,7.68c0,0.17-0.04,0.33-0.06,0.5C1.74,8.31,1.73,8.44,1.7,8.56
		c0,0.01,0,0.03-0.01,0.04C1.67,8.77,1.65,8.93,1.63,9.09C1.62,9.18,1.61,9.27,1.59,9.36c0,0.09-0.01,0.17-0.03,0.25
		c0,0.19-0.04,0.38-0.07,0.58c0,0.04,0.01,0.09-0.02,0.13c0,0.19-0.04,0.38-0.06,0.56c-0.01,0.13-0.02,0.26-0.06,0.38
		c-0.01,0.11-0.01,0.21-0.05,0.32h0c0,0.08,0,0.16-0.02,0.23c-0.01,0.07-0.02,0.15-0.03,0.22c-0.01,0.22-0.05,0.43-0.08,0.64
		c-0.02,0.17-0.05,0.34-0.07,0.52c-0.02,0.11-0.03,0.22-0.05,0.33c0,0.08-0.01,0.15-0.03,0.22c-0.01,0.09-0.01,0.18-0.04,0.27
		c0,0.06,0,0.11-0.01,0.17c-0.04,0.13,0.08,0.12,0.14,0.16c0.09,0,0.17,0.02,0.25,0.04c0.09,0.02,0.18,0.02,0.27,0.06
		c0.1,0,0.19,0.02,0.27,0.05c0.09-0.01,0.17,0.02,0.25,0.03c0.13,0.02,0.25,0.05,0.38,0.07c0.07,0,0.13,0.05,0.19,0.04
		c0.09,0.02,0.19,0.01,0.27,0.05c0.08,0.01,0.17,0.03,0.25,0.04c0.12,0.01,0.24,0.03,0.36,0.07c0.07,0,0.14,0.02,0.2,0.05
		c0.1-0.01,0.19,0.01,0.28,0.04c0.09,0,0.18,0.03,0.27,0.05c0.05,0,0.1,0,0.15,0.02c0.16,0.02,0.32,0.04,0.47,0.09
		c0.03,0.01,0.06,0.01,0.09,0.02c0.05,0.01,0.11,0.02,0.16,0.03c0.08,0,0.14,0.03,0.22,0.04c0.09,0.01,0.17,0.03,0.26,0.04
		c0.13,0,0.25,0.04,0.38,0.07c0.12,0.02,0.24,0.03,0.35,0.07c0.03,0.01,0.07-0.01,0.09,0.01c0.02,0,0.04,0,0.06,0.02
		c0.08,0,0.15,0.02,0.23,0.04c0.09,0.01,0.19,0.02,0.27,0.05c0.05-0.01,0.08,0.02,0.13,0.02c0.14,0.01,0.28,0.04,0.43,0.07
		c0.19,0.04,0.38,0.07,0.57,0.11c0.11,0.02,0.22,0.04,0.33,0.06c0.11,0.01,0.22,0.04,0.33,0.06c0.12,0.02,0.24,0.05,0.36,0.07
		c0.07,0.02,0.15,0.02,0.22,0.04c0.03,0,0.05,0,0.08,0.01c0.01,0,0.02,0.01,0.02,0.02c0.03-0.03,0.07-0.01,0.1,0
		c0.06,0.01,0.12,0.02,0.18,0.03c0.02,0.01,0.05,0.01,0.06,0.04c0,0,0.01,0,0.01,0c0.04-0.02,0.08-0.02,0.1,0.02
		c0.02-0.02,0.04-0.02,0.07-0.02c0,0,0,0,0,0c-0.01-0.05,0-0.11,0-0.16c0.02-0.47,0.04-0.94,0.06-1.4c0.04-0.71,0.07-1.42,0.11-2.13
		c0.03-0.57,0.05-1.14,0.08-1.71c0.03-0.55,0.06-1.09,0.08-1.64c0.02-0.49,0.05-0.98,0.07-1.47c0.02-0.5,0.05-1.01,0.07-1.51
		c0.03-0.68,0.07-1.35,0.11-2.03c0.03-0.64,0.06-1.29,0.08-1.93c0-0.05,0.01-0.08,0.07-0.07c0,0,0,0,0,0
		C10.76,1.88,10.68,1.9,10.61,1.89z M7.87,1.23C8.06,1.1,8.07,1.13,8.12,1.31c0.04,0.15,0.08,0.31,0.09,0.46
		c0.01,0.04,0.01,0.09,0.01,0.13c0.01,0.12,0.03,0.24,0.03,0.37c0,0.09,0,0.17,0,0.26c0.01,0.05,0.01,0.09-0.05,0.1
		C8.11,2.67,8.01,2.69,7.92,2.71C7.83,2.74,7.75,2.77,7.66,2.8C7.47,2.87,7.28,2.93,7.08,3C7.07,3,7.05,3,7.03,3
		C6.96,3.02,6.9,3.04,6.83,3.06c0,0-0.01,0-0.01,0C6.78,3.08,6.73,3.09,6.7,3.07C6.67,3.05,6.7,3,6.71,2.97
		c0.02-0.11,0.07-0.21,0.11-0.31c0-0.01,0-0.02,0-0.03c0.01-0.03,0.01-0.05,0.02-0.07c0.03-0.11,0.07-0.22,0.14-0.31
		C7.03,2.18,7.04,2.11,7.1,2.07c0-0.04,0.03-0.06,0.05-0.08C7.27,1.77,7.42,1.59,7.6,1.43C7.67,1.36,7.76,1.3,7.85,1.26
		C7.86,1.25,7.86,1.23,7.87,1.23z M5,3.05C5.03,2.97,5.05,2.88,5.1,2.8c0.03-0.1,0.07-0.2,0.12-0.3c0-0.02,0.01-0.03,0.02-0.04
		C5.29,2.39,5.3,2.29,5.37,2.23c0.01-0.06,0.04-0.1,0.07-0.14v0C5.46,2.05,5.49,2,5.51,1.96c0.03-0.05,0.06-0.11,0.1-0.16
		c0-0.08,0.08-0.12,0.11-0.18c0.03-0.05,0.07-0.1,0.11-0.15c0.07-0.1,0.13-0.19,0.24-0.26c0-0.02,0.03-0.02,0.04-0.04
		C6.15,1.1,6.22,1.04,6.3,1c0.08-0.06,0.15-0.14,0.25-0.17c0.03-0.05,0.09-0.05,0.13-0.08c0-0.04,0.03-0.04,0.06-0.03
		C6.77,0.7,6.8,0.7,6.83,0.69C7.01,0.6,7.2,0.57,7.39,0.56C7.47,0.56,7.54,0.57,7.61,0.6C7.64,0.61,7.68,0.6,7.68,0.64
		c0.01,0.04-0.04,0.03-0.06,0.04C7.54,0.74,7.45,0.8,7.35,0.85c-0.11,0.1-0.22,0.19-0.33,0.29C6.96,1.22,6.89,1.3,6.83,1.38
		C6.81,1.4,6.79,1.42,6.78,1.44c-0.07,0.1-0.13,0.19-0.2,0.29C6.54,1.81,6.49,1.9,6.45,1.98C6.39,2.11,6.34,2.24,6.28,2.37
		C6.27,2.42,6.25,2.48,6.23,2.53C6.2,2.62,6.18,2.72,6.14,2.8C6.14,2.84,6.13,2.88,6.12,2.91C6.09,3.03,6.07,3.16,6.04,3.28
		C6.02,3.35,5.95,3.33,5.9,3.35c-0.01,0-0.02,0-0.03,0C5.75,3.4,5.63,3.43,5.51,3.46c-0.1,0.05-0.2,0.07-0.31,0.09
		C5.07,3.6,4.96,3.65,4.83,3.67C4.78,3.65,4.8,3.6,4.81,3.58C4.88,3.41,4.91,3.22,5,3.05z M8.27,6.12L8.27,6.12
		c0,0.06-0.04,0.12-0.06,0.18C8.2,6.33,8.2,6.37,8.18,6.39C8.16,6.47,8.13,6.54,8.11,6.61C8.07,6.71,8.05,6.82,8,6.92
		C7.99,6.99,7.97,7.06,7.94,7.12C7.93,7.14,7.92,7.17,7.91,7.2C7.88,7.27,7.86,7.35,7.83,7.43C7.82,7.51,7.79,7.54,7.71,7.5
		c-0.12-0.05-0.24-0.1-0.36-0.14C7.27,7.34,7.18,7.32,7.1,7.3C6.99,7.29,6.87,7.27,6.76,7.26c-0.07,0.03-0.13,0-0.2,0.01
		C6.37,7.27,6.19,7.3,6.01,7.37C5.96,7.4,5.9,7.43,5.85,7.47c-0.03,0.03-0.06,0.07-0.1,0.1c-0.04,0.05-0.08,0.11-0.1,0.17
		C5.61,7.82,5.61,7.91,5.6,7.99c0.02,0.09,0.03,0.18,0.07,0.27c0,0.01,0.01,0.01,0.01,0.02c0.08,0.09,0.17,0.18,0.25,0.27
		C5.95,8.57,5.96,8.58,5.97,8.6c0.1,0.06,0.2,0.13,0.29,0.21h0c0.01,0.01,0.02,0.02,0.03,0.03c0.1,0.07,0.21,0.12,0.29,0.22
		c0.11,0.04,0.18,0.12,0.26,0.2C6.86,9.28,6.9,9.3,6.92,9.33c0.07,0.07,0.15,0.12,0.2,0.2C7.15,9.56,7.19,9.57,7.2,9.61
		c0.07,0.07,0.13,0.15,0.18,0.23c0.02,0.01,0.03,0.02,0.03,0.04c0.1,0.11,0.15,0.24,0.2,0.38c0.09,0.15,0.11,0.32,0.14,0.49
		c0.02,0.14,0.02,0.29,0.02,0.44c0,0.02,0.01,0.05-0.01,0.07c0,0.21-0.03,0.41-0.09,0.6c0,0.03-0.01,0.05-0.02,0.08
		c-0.01,0.03-0.02,0.07-0.04,0.1c-0.01,0.02-0.01,0.04-0.02,0.06c-0.03,0.09-0.06,0.18-0.1,0.26c-0.02,0.07-0.06,0.12-0.1,0.18
		c-0.01,0.05-0.04,0.08-0.07,0.11c-0.07,0.08-0.11,0.2-0.22,0.25c-0.06,0.12-0.17,0.18-0.28,0.26c-0.01,0.01-0.02,0.02-0.03,0.03
		c-0.07,0.04-0.14,0.1-0.22,0.13c-0.06,0.05-0.13,0.07-0.19,0.1c0,0,0,0,0,0c-0.02,0-0.04,0.02-0.06,0.02
		c-0.14,0.06-0.29,0.1-0.45,0.12c-0.2,0.04-0.4,0.05-0.6,0.02c-0.26,0.01-0.51-0.05-0.76-0.11c-0.04-0.01-0.1,0-0.13-0.05
		c-0.01,0-0.01-0.01-0.02-0.01c-0.09-0.03-0.19-0.06-0.27-0.11c-0.09-0.02-0.16-0.07-0.24-0.11c-0.01-0.01-0.02-0.01-0.04-0.02
		c-0.1-0.05-0.19-0.11-0.27-0.17c-0.07-0.02-0.12-0.07-0.16-0.12c-0.05-0.02-0.07-0.06-0.11-0.09c0,0,0,0,0,0
		c-0.15-0.07-0.19-0.15-0.13-0.31c0.05-0.14,0.07-0.28,0.13-0.42c0.03-0.08,0.05-0.17,0.08-0.26c0.05-0.18,0.09-0.37,0.16-0.54
		c0.04-0.1,0.07,0.01,0.11,0.03c0.06,0.04,0.12,0.08,0.17,0.13c0.07,0.02,0.12,0.05,0.16,0.1c0.16,0.09,0.32,0.17,0.49,0.26
		c0.07,0.04,0.15,0.05,0.23,0.09c0.08,0.02,0.17,0.04,0.25,0.06c0.09,0,0.18,0,0.27,0c0.1-0.02,0.18-0.07,0.27-0.11
		c0-0.02,0-0.03,0.02-0.03c0.06-0.06,0.11-0.12,0.15-0.2c0.03-0.11,0.06-0.21,0.06-0.33c0-0.08-0.02-0.16-0.04-0.23
		c-0.04-0.1-0.1-0.19-0.16-0.28c-0.1-0.09-0.21-0.19-0.29-0.3c-0.1-0.08-0.2-0.16-0.29-0.24c-0.01-0.01-0.03-0.03-0.04-0.04
		c-0.09-0.05-0.16-0.13-0.23-0.2C4.6,9.96,4.58,9.94,4.55,9.92C4.48,9.85,4.41,9.78,4.35,9.7C4.33,9.68,4.32,9.66,4.31,9.63
		C4.23,9.56,4.18,9.46,4.13,9.36C4.13,9.35,4.12,9.34,4.12,9.34C4.07,9.31,4.07,9.27,4.06,9.23c-0.04-0.13-0.1-0.26-0.1-0.4
		c0-0.01,0-0.01,0-0.02C3.94,8.72,3.91,8.63,3.93,8.54C3.9,8.38,3.92,8.21,3.94,8.05c0.01-0.05,0-0.1,0.03-0.14
		c0-0.07,0.01-0.13,0.04-0.19c0.03-0.19,0.1-0.36,0.17-0.53c0.03-0.12,0.11-0.23,0.17-0.34c0.08-0.14,0.19-0.27,0.3-0.39
		c0.08-0.09,0.16-0.19,0.27-0.25c0.05-0.04,0.11-0.09,0.16-0.13c0.03-0.04,0.06-0.06,0.11-0.07C5.28,5.95,5.37,5.9,5.46,5.86
		C5.5,5.83,5.54,5.8,5.59,5.79c0.03-0.01,0.07-0.03,0.1-0.04C5.79,5.7,5.9,5.67,6.01,5.64C6.09,5.6,6.17,5.59,6.26,5.58
		c0.09-0.05,0.2-0.04,0.3-0.07c0.22-0.04,0.43-0.04,0.65-0.04c0.1,0,0.21-0.01,0.31,0.02C7.66,5.51,7.8,5.52,7.94,5.57
		c0.09,0,0.17,0.03,0.26,0.07c0.03,0.01,0.05,0.02,0.08,0.02C8.35,5.67,8.4,5.72,8.38,5.81C8.34,5.91,8.31,6.02,8.27,6.12z
		 M9.42,2.25c-0.1,0.03-0.19,0.06-0.29,0.1C9.05,2.4,8.95,2.4,8.86,2.44C8.8,2.47,8.79,2.4,8.79,2.36c0.02-0.15,0-0.3-0.01-0.45
		c0-0.1-0.04-0.19-0.02-0.29c0,0,0,0,0,0C8.76,1.57,8.75,1.51,8.74,1.46C8.67,1.37,8.7,1.27,8.66,1.18c0-0.01,0-0.02-0.01-0.03
		c-0.03-0.12-0.03-0.12,0.1-0.08c0.11,0.04,0.21,0.09,0.29,0.18c0.1,0.09,0.18,0.19,0.24,0.31C9.39,1.69,9.45,1.84,9.5,2
		c0.01,0.02,0.02,0.04,0.02,0.05c0,0,0,0,0,0c0,0,0,0,0,0C9.57,2.21,9.57,2.2,9.42,2.25z"
                    />
                    <polygon points="7.61,10.27 7.61,10.27 7.61,10.27 	" />
                    <polygon points="3.94,8.53 3.93,8.54 3.94,8.54 	" />
                    <path
                      d="M14.93,14.51c-0.01-0.07-0.02-0.15-0.03-0.22c-0.01-0.12-0.05-0.23-0.05-0.35c-0.03-0.18-0.05-0.36-0.08-0.55
		c-0.01-0.07-0.02-0.15-0.03-0.22c-0.04-0.17-0.06-0.35-0.08-0.53c-0.05-0.2-0.07-0.41-0.09-0.62c-0.04-0.16-0.05-0.33-0.07-0.49
		c-0.04-0.28-0.08-0.56-0.12-0.84c-0.01-0.04-0.01-0.08-0.02-0.13c-0.02-0.16-0.05-0.32-0.07-0.47c0-0.02,0-0.05,0-0.07
		c-0.04-0.14-0.05-0.28-0.07-0.42c0-0.02,0-0.04,0-0.05c-0.04-0.11-0.05-0.23-0.05-0.35c-0.02-0.04-0.02-0.08-0.02-0.13
		c-0.02-0.12-0.05-0.24-0.05-0.36c-0.02-0.04-0.01-0.09-0.02-0.13C14.03,8.43,14,8.27,13.99,8.1c0-0.01,0-0.01,0-0.02
		c-0.04-0.16-0.05-0.32-0.07-0.47c-0.01-0.04-0.01-0.09-0.02-0.13c-0.04-0.11-0.04-0.23-0.06-0.35c-0.02-0.14-0.05-0.27-0.05-0.41
		c-0.03-0.03-0.02-0.06-0.02-0.09c-0.02-0.15-0.05-0.3-0.07-0.45c0-0.02,0-0.05,0-0.07c-0.04-0.14-0.05-0.28-0.07-0.42
		c-0.02-0.13-0.04-0.27-0.06-0.4c-0.02-0.11-0.04-0.22-0.05-0.33c-0.04-0.23-0.08-0.46-0.1-0.69c-0.02-0.12-0.05-0.24-0.05-0.36
		c-0.02-0.12-0.04-0.23-0.06-0.35c-0.02-0.16-0.05-0.32-0.07-0.48C13.22,3.05,13.19,3.02,13.17,3c-0.12,0-0.24-0.02-0.36-0.03
		c-0.08,0-0.16,0-0.24-0.02c-0.17,0-0.33-0.02-0.49-0.03c-0.02,0-0.04,0.02-0.05,0c-0.11,0-0.23,0.01-0.29-0.11
		c-0.01-0.01-0.01-0.01-0.02-0.02c-0.09-0.08-0.18-0.16-0.25-0.26c-0.01-0.01-0.01-0.01-0.02-0.02c-0.09-0.07-0.18-0.16-0.26-0.25h0
		c-0.01-0.01-0.01-0.01-0.02-0.02C11.08,2.15,11,2.07,10.91,1.98c-0.01-0.01-0.01-0.01-0.02-0.02c-0.03-0.02-0.06-0.04-0.1-0.05
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.04,0.05-0.04,0.1-0.05,0.16c-0.02,0.53-0.06,1.07-0.08,1.6C10.66,4,10.64,4.33,10.62,4.66
		c-0.04,0.67-0.06,1.34-0.1,2.01c-0.03,0.6-0.06,1.21-0.09,1.81c-0.04,0.77-0.07,1.53-0.12,2.3c-0.03,0.47-0.04,0.95-0.07,1.42
		c-0.04,0.65-0.06,1.31-0.1,1.96c-0.02,0.44-0.04,0.87-0.06,1.31c-0.01,0.17,0,0.35-0.04,0.52c0.11-0.02,0.22-0.05,0.32-0.07
		c0.03-0.01,0.06-0.01,0.08,0c0,0,0,0,0.01,0c0.01,0,0.02-0.01,0.03-0.01c0.01,0,0.01,0,0.02,0c0.01-0.02,0.03-0.03,0.05-0.03
		c0.21-0.05,0.42-0.09,0.63-0.14c0.01,0,0.02,0,0.04,0c0.01,0,0.01,0.01,0.02,0.01c0-0.04,0.04-0.04,0.07-0.05
		c0.21-0.04,0.41-0.09,0.62-0.13c0.02,0,0.04,0,0.06,0c0.01,0.01,0,0.01,0,0.01c0,0,0.01,0,0.01,0c0.06-0.02,0.12-0.04,0.18-0.04
		c0.11-0.04,0.22-0.06,0.33-0.07c0.08-0.02,0.17-0.05,0.25-0.06c0.1-0.03,0.19-0.06,0.29-0.07c0.13-0.03,0.25-0.06,0.38-0.08
		c0.16-0.04,0.31-0.08,0.48-0.1c0.04-0.01,0.07-0.01,0.11-0.02c0.22-0.05,0.45-0.1,0.67-0.15c0.08-0.03,0.17-0.04,0.26-0.05
		c0.03-0.01,0.04-0.02,0.03-0.06C14.96,14.77,14.95,14.64,14.93,14.51z"
                    />
                  </g>
                </svg>
                Connect with shopify
              </Link>
            </li>
            <li>
              <Link
                onClick={countMessageCount}
                to={"/vendor/woocommerce"}
                className={
                  this.props.location.pathname === "/vendor/woocommerce"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                >
                  <g>
                    <path
                      d="M310.73,5.03c-20.91-4.19-42.03-5.71-62.87-4.76C134.38,5.4,28.72,83.43,5.15,200.67
		c-20.12,100.09,21.94,208.92,106.05,267.47c125.66,87.47,305.61,38.51,374.11-97.21C558.82,225.28,472.62,37.44,310.73,5.03z
		 M418.38,299.07c-0.09,19.44-12.83,31.92-32.46,31.98c-29.73,0.09-59.45,0.02-90.57,0.02c4,10.64,7.54,20.03,11.07,29.43
		c-0.67,0.63-1.35,1.26-2.02,1.89c-15.13-8.34-30-17.22-45.52-24.78c-7.2-3.51-15.63-6.11-23.56-6.26
		c-36.32-0.65-72.66-0.24-108.99-0.3c-20.92-0.04-33.16-12.4-33.21-33.55c-0.07-31.74-0.07-63.49,0-95.23
		c0.05-21.15,12.27-33.52,33.18-33.53c86.43-0.04,172.86-0.04,259.3,0c20.19,0.01,32.74,12.55,32.8,32.8
		C418.49,234.06,418.53,266.56,418.38,299.07z"
                    />
                    <path
                      d="M237.74,190.4c-3.39-0.86-10.01,1.95-11.65,5c-4.27,7.95-7.4,16.74-9.51,25.55c-3.4,14.16-5.63,28.6-8.35,42.92
		c-1.03,0.17-2.06,0.35-3.1,0.53c-3.61-12.91-6.9-25.92-11-38.66c-1.6-4.96-5.04-9.32-7.64-13.95c-4.36,3.55-10.17,6.26-12.81,10.8
		c-9.33,16.05-17.58,32.74-26.24,49.19c-0.96-0.1-1.92-0.2-2.88-0.3c-1.06-3.59-2.45-7.12-3.13-10.79
		c-3.24-17.57-6.16-35.2-9.48-52.75c-1.18-6.24-1.74-13.37-11.35-12.54c-9.67,0.84-8.03,7.89-7.43,13.64
		c1.02,9.81,2.43,19.65,4.6,29.26c4.94,21.85,9.82,43.75,15.94,65.29c3.49,12.27,9.26,12.89,16.95,2.53
		c4.97-6.69,8.83-14.26,12.8-21.64c5.35-9.95,10.33-20.1,16.63-32.46c2.81,7.22,4.15,11.97,6.43,16.23
		c6.8,12.72,13.42,25.59,21.3,37.62c2.4,3.66,8.65,4.79,13.14,7.08c1.79-4.63,4.98-9.21,5.13-13.89
		c1.05-32.54,4.32-64.64,16.89-95.05C245.84,197.09,243.94,191.97,237.74,190.4z"
                    />
                    <path
                      d="M398.49,234.49c-0.99-14.07-9.5-25.66-21.55-29.55c-13.62-4.4-26.26-0.96-35.46,11.18c-13.36,17.64-18.15,37.53-11.97,59.2
		c3.95,13.85,13.8,22.14,27.88,23.69c11.74,1.29,21.52-4.17,28.58-13.64c8.92-11.98,13.72-25.42,12.56-43.07
		C398.53,241.36,398.73,237.91,398.49,234.49z M370.16,273.54c-8.56,8.51-17.71,6.58-21.88-4.84c-1.41-3.86-2-8.02-2.96-12.05
		c-0.67-0.46-1.34-0.91-2-1.37c4.92-9.48,8.71-19.84,15.1-28.21c6.25-8.18,13.9-6.15,18.75,3.08
		C383.61,242.45,380.21,263.55,370.16,273.54z"
                    />
                    <path
                      d="M289.46,203.61c-12.22-2.18-21.74,2.55-29.3,11.34c-10.34,12.03-15.16,26.29-14.69,42.25c0.13,2.29,0.24,4.58,0.38,6.86
		c1.27,19.97,9.45,30.37,27.1,34.48c12.87,3,26.65-4.58,35.18-19.9c8.84-15.89,12.05-32.92,7.22-50.64
		C311.81,214.98,303.56,206.13,289.46,203.61z M288.13,272.76c-7.59,10.16-18.33,7.54-22.41-4.38c-5.35-15.6-0.74-29.26,9.61-41
		c7.26-8.24,15.06-6.75,19.6,3.21c2.16,4.74,3.14,10.02,5.27,17.08C296.59,255.46,293.8,265.18,288.13,272.76z"
                    />
                  </g>
                </svg>
                Connect with Woocommerce
              </Link>
            </li>
            <li>
              <Link
                onClick={countMessageCount}
                to={"/zoom-meeting"}
                className={
                  this.props.location.pathname === "/zoom-meeting"
                    ? "active"
                    : "null"
                }
                style={{ textTransform: "capitalize" }}
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.1,14.32c1.26,0.99,2.69,1.53,4.29,1.66c0.04,0,0.08,0.02,0.12,0.03c0.33,0,0.67,0,1,0c0.3-0.04,0.6-0.07,0.89-0.12
	c2.24-0.42,4.01-1.58,5.28-3.47c0.92-1.36,1.37-2.87,1.32-4.51c-0.09-2.98-1.42-5.29-3.98-6.81c-2.77-1.65-6.2-1.44-8.78,0.49
	C1.4,2.96,0.34,4.8,0.06,7.08C0.04,7.22,0.02,7.37,0,7.51c0,0.33,0,0.67,0,1C0.04,8.8,0.07,9.09,0.12,9.37
	C0.48,11.4,1.48,13.05,3.1,14.32z M10.44,7.11c0-0.11,0.04-0.17,0.12-0.23c0.71-0.51,1.41-1.03,2.12-1.54
	c0.08-0.05,0.18-0.07,0.28-0.1c0.03,0.09,0.08,0.19,0.09,0.28c0.01,0.83,0,1.66,0,2.48c0,0.82,0,1.63,0,2.45
	c0,0.1-0.06,0.21-0.09,0.31c-0.1-0.04-0.22-0.05-0.31-0.12c-0.7-0.5-1.39-1.01-2.08-1.51c-0.09-0.06-0.13-0.13-0.13-0.24
	C10.44,8.31,10.44,7.71,10.44,7.11z M3.17,5.23c0.9,0,1.8,0,2.7,0c0,0,0,0,0,0c0.9,0,1.79-0.01,2.69,0
	c0.72,0.01,1.32,0.49,1.48,1.18c0.03,0.13,0.04,0.27,0.04,0.4c0,1.2,0,2.41,0,3.61c0,0.28-0.09,0.37-0.38,0.37
	c-1.77,0-3.54,0-5.31,0c-0.96,0-1.62-0.66-1.62-1.62c0-1.19,0-2.37,0-3.56C2.78,5.31,2.87,5.23,3.17,5.23z"
                  />
                </svg>
                Connect with Zoom
              </Link>
            </li>
            <li>
              <Link
                to={"/attribute-list"}
                className={
                  this.props.location.pathname === "/attribute-list" ||
                  this.props.match.path == "/add-attribute" ||
                  this.props.match.path == "/edit-attribute/:attributeId"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 16 16"
                >
                  <g>
                    <path
                      d="M14.99,7.2C11.55,7.21,8.11,7.2,4.67,7.2c-0.09,0-0.18,0-0.26,0.01C3.87,7.27,3.55,7.83,3.77,8.32
		C3.93,8.67,4.22,8.8,4.59,8.8c3.2,0,6.4,0,9.61,0c0.33,0,0.66,0,0.98,0c0.4-0.01,0.66-0.21,0.79-0.59
		c0.01-0.02,0.02-0.04,0.03-0.05c0-0.1,0-0.21,0-0.31C15.84,7.35,15.49,7.19,14.99,7.2z"
                    />
                    <path
                      d="M15.02,2.19c-3.45,0-6.9,0-10.36,0c-0.07,0-0.14,0-0.2,0C4.12,2.21,3.84,2.43,3.74,2.75C3.64,3.06,3.75,3.4,4,3.61
		c0.17,0.14,0.38,0.18,0.6,0.18c3.5,0,7.01,0,10.51,0c0.04,0,0.08,0,0.12,0c0.33-0.02,0.56-0.19,0.7-0.49
		C15.96,3.25,15.98,3.2,16,3.16c0-0.1,0-0.21,0-0.31C15.78,2.32,15.59,2.19,15.02,2.19z"
                    />
                    <path
                      d="M14.99,12.21c-3.44,0.01-6.87,0.01-10.31,0.01c-0.07,0-0.14,0-0.2,0c-0.34,0.01-0.62,0.22-0.73,0.54
		c-0.18,0.54,0.21,1.05,0.81,1.05c3.53,0,7.06,0,10.59,0c0.06,0,0.13,0,0.19-0.01c0.36-0.07,0.56-0.3,0.67-0.64c0-0.1,0-0.21,0-0.31
		C15.83,12.36,15.48,12.21,14.99,12.21z"
                    />
                    <path d="M1.08,4.12c0.59,0,1.07-0.49,1.07-1.08c0-0.59-0.49-1.07-1.08-1.07C0.48,1.98,0,2.46,0,3.05C0,3.64,0.49,4.13,1.08,4.12z" />
                    <path
                      d="M1.08,14.02c0.59,0,1.07-0.49,1.07-1.08c0-0.59-0.49-1.07-1.08-1.07C0.48,11.88,0,12.36,0,12.95
		C0,13.54,0.49,14.03,1.08,14.02z"
                    />
                    <path
                      d="M0,7.98c-0.01,0.59,0.46,1.08,1.06,1.08c0.59,0.01,1.07-0.46,1.09-1.05c0.01-0.59-0.47-1.08-1.06-1.08
		C0.49,6.93,0.01,7.39,0,7.98z"
                    />
                  </g>
                </svg>
                Attribute list
              </Link>
            </li>
            <li>
              <Link
                to={"/page/support"}
                target="_blank"
                className={
                  this.props.location.pathname === "/page/support"
                    ? "active"
                    : "null"
                }
              >
                <svg
                  width="100pt"
                  height="100pt"
                  version="1.1"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path d="m28.512 43.461h0.26172-0.003907c0.27734 0.003906 0.53906-0.13672 0.69141-0.37109 0.81641 1.7383 1.7734 3.4141 2.8594 5l0.10938 0.12109c0.37109 0.55859 0.83984 1.2305 1.4102 2l0.35938 0.46094 0.058593 0.070313 0.32031 0.39062 0.089844 0.10156 0.003906-0.003906c2.4219 2.9805 5.457 5.4023 8.8984 7.1094 5.4297 2.5312 10.699 2.2305 16.621-1.9414 1.5625-1.1406 2.9688-2.4844 4.1797-4h1.5391c3.9375-0.027344 7.1133-3.2383 7.0898-7.1797v-2.1602c2.5156-0.92188 4.1914-3.3164 4.1914-6v-6.3594c0-2.3555-1.293-4.5234-3.3711-5.6406-0.39844-6.0078-2.9922-11.66-7.2852-15.887-4.293-4.2227-9.9883-6.7227-16.004-7.0234-0.089844 0-0.17969-0.14844-0.26953-0.14844-5.8008-0.125-11.461 1.7812-16 5.3906-5.332 4.3594-8.5352 10.797-8.793 17.68-2.0703 1.1133-3.3672 3.2773-3.3672 5.6289v6.3594c0.003907 3.5391 2.8711 6.4023 6.4102 6.4023zm42 0v1.9414-0.003906c0 2.3086-1.8164 4.2109-4.1211 4.3125 0.66016-1 1.3281-2 2-3.1094 0.66797-1.1133 1.1406-2.2031 1.6094-3.332 0.13281 0.11328 0.30469 0.17969 0.48047 0.17969zm-39.082-12.672c0.015624-0.27344 0.22656-0.49609 0.5-0.52734 5.1211-0.80078 8.7305-4 10.781-9.5312 0.082031-0.19141 0.25391-0.32422 0.46094-0.35156 0.20312-0.03125 0.40625 0.050782 0.53906 0.21094 5.3008 7.2617 11 10.352 24.609 9.7617 0.30469-0.015624 0.57422 0.20313 0.62109 0.50781 0.44922 5.5508-0.73828 11.113-3.4102 16-0.53516 0.98438-1.1289 1.9336-1.7812 2.8398h-8.3984v-2c0-1.2461-1.0117-2.2578-2.2617-2.2578h-2.8906c-1.2461 0-2.2578 1.0117-2.2578 2.2578v3.3008c0.003906 0.73047 0.59766 1.3242 1.3281 1.3281h12.301c-2.1914 2.3203-4.9141 4.0742-7.9297 5.1133-1.3281 0.45312-2.7461 0.60156-4.1406 0.42969-4.7969-0.69922-9.1484-3.2031-12.16-7-4.0312-4.8711-6.4414-11.949-5.9102-20.082z" />
                    <path d="m56 86.75 0.82031-2.1719c0.16797-0.42969 0.12891-0.91797-0.10937-1.3164l-3.9297-6.7109c-0.19922-0.35938-0.10547-0.80859 0.21875-1.0625 7.1602-6.1602 4.1094-11.25-3.2188-11.789h-0.14844c-7.3203 0.53906-10.379 5.6289-3.2188 11.789h-0.003906c0.3125 0.26172 0.38672 0.71094 0.17969 1.0625l-3.4609 6.2812v-0.003906c-0.21484 0.39453-0.25391 0.85938-0.097656 1.2812l1 2.6406c0.023438 0.085938 0.066406 0.16406 0.12891 0.23047 1.4297 4.0195 2.8398 7.8594 3.8398 10.691 0.019531 0.10547 0.019531 0.21875 0 0.32812h4c-0.019531-0.10938-0.019531-0.22266 0-0.32812 1-2.832 2.4102-6.6719 3.8711-10.672 0.058594-0.074219 0.10156-0.16016 0.12891-0.25z" />
                    <path d="m40 86.051c-0.058594-0.066406-0.10938-0.14453-0.14062-0.23047l-7.1289-18.82c-0.16016-0.39453-0.58594-0.60938-1-0.51172-7.5 2.1328-14.211 4.5117-18.91 7.3711-4.6992 2.8594-6.3984 12.871-5.4922 19.141v0.12109c0.89844 2.75 2.2695 4.4883 4.2695 4.8906h32.113c0.12891 0.007812 0.25391-0.046875 0.33594-0.14844 0.082031-0.10156 0.10938-0.23828 0.074219-0.36328-1.043-2.8984-2.5312-7.0508-4.1211-11.449z" />
                    <path d="m87.18 73.859c-4.6992-2.8594-11.41-5.2383-18.879-7.3477-0.41406-0.10156-0.83984 0.11328-1 0.50781l-7.1289 18.801c-0.035156 0.085938-0.082031 0.16406-0.14062 0.23047-1.5898 4.3984-3.0781 8.5508-4.1211 11.441v-0.003907c-0.035156 0.125-0.007812 0.26172 0.074219 0.36328 0.078125 0.10156 0.20703 0.15625 0.33594 0.14844h32.051c2-0.39844 3.3711-2.1406 4.2695-4.8906v-0.12109c0.9375-6.2578-0.75-16.348-5.4609-19.129z" />
                  </g>
                </svg>
                Support
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isShowSidebar: state.commonReducer.showSidebar,
    unreadMessageCount: state.userReducer.unreadMessageCount,
    unreadDisputeCount: state.userReducer.unreadDisputeCount,
    unreadOMeetingCount: state.userReducer.unreadOMeetingCount,
    unreadReviewsCount: state.userReducer.unreadReviewsCount,
    pendingReturnRequestCount: state.userReducer.pendingReturnRequestCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSidebar,
      getMessageCountBySellerId,
      getDisputeCountBySellerId,
      getOneOnOneMeetingCountBySellerId,
      getReviewCountBySellerId,
      getOrderReturnRequestCountBySellerId,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
