import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import {
  getMeetingRequestList,
  updateMeetingStatus,
} from "../../Redux/Actions/meetingActions";
import "./meeting-list.scss";
import {Link, useLocation} from "react-router-dom";
import Calendar from "../Add-Product/Calendar";
import {CLOSE_MEETING_MODAL, MEETING_ALREADY_EXISTS} from "../../Redux/ActionType";
import AlertModal from "../../Components/AlertModal/AlertModal";
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';

function MeetingList() {
  let dispatch = useDispatch();
  const location = useLocation()
  let sellerId = localStorage.getItem("sellerId");
  const sellerData = JSON.parse(localStorage.getItem("sellerData"));
  let { meetingExists } = useSelector(
      (state) => state.meetingReducer
  );
  const [showLoader, setshowLoader] = useState(false);
  const [showViewDetails, setShowViewDetails] = useState(false);
  const [showAcceptDetail, setShowAcceptDetail] = useState(false);
  const [showDeclineDetail, setShowDeclineDetail] = useState(false);
  const [meetingDetails, setMeetingDetails] = useState({});
  const [acceptDetails, setAcceptDetails] = useState({});
  const [declineNotes, setDeclineNotes] = useState("");
  const [pagination, setPagination] = useState({
    limit: 10,
    pageNumber: 1,
  });
  const [btnDisabled, setButtonDisabled] = useState(false);
  const [showPendingMeetingModal, setShowPendingMeetingModal] = useState(false);
  const { timezone } = JSON.parse(localStorage.getItem("sellerData"));
  const [title, setTitle] = useState("Meeting List");
  const [showCalendar, setShowCalendar] = useState(false);
  const [zoom, setZoom] = useState()
  const [alertState, setAlertState] = useState({
    open : false,
    message : '',
    singleButton : true
  })

  const {
    meetingRequestList,
    hasMoreMeetingRequests,
    successMessage,
    errorMessage,
  } = useSelector((state) => state.meetingReducer);

  useEffect(() => {
    fetchMeetingList();
  }, [pagination]);

  const fetchMeetingList = async () => {
    dispatch(
        getMeetingRequestList(
            sellerId,
            pagination.limit,
            pagination.pageNumber,
            sellerData.timezone
        )
    );
  };

  const handleUpdateStatus = async (meeting_id, status) => {
    setShowDeclineDetail(false);
    if (meeting_id) {
      await dispatch(
        updateMeetingStatus({
          userId: sellerId,
          meeting_id,
          status,
          declineNotes,
        })
      );
    } else {
      await dispatch(
        updateMeetingStatus({
          userId: sellerId,
          meeting_id: meetingDetails["meeting_id"],
          status,
          declineNotes,
        })
      );
    }
    setTitle(" " + title);
  };

  // const handleChange = () => { };

  const handleAcceptModalSubmit = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    if (!acceptDetails.date || acceptDetails.date.trim().length === 0) {
      alertModal({message : "Please select a date", singleButton :true});
    } else if (
      !acceptDetails.startDateTime ||
      acceptDetails.startDateTime.trim().length === 0
    ) {
      alertModal({message : "Please select a start time",  singleButton : true});
    } else if (
      !acceptDetails.endDateTime ||
      acceptDetails.endDateTime.trim().length === 0
    ) {
      alertModal({message : "Please select a end time", singleButton : true});
    }else if( new Date(acceptDetails.startDateTime).getTime() >= new Date(acceptDetails.endDateTime).getTime()){
      alertModal({message : "End time must be greater than start time", singleButton : true});
    }else if( new Date(acceptDetails.endDateTime).getTime() - new Date(acceptDetails.startDateTime).getTime() < 60000*5){
      alertModal({message : "Meeting duration should be at least 5 mins!", singleButton : true});
    } else if(Math.ceil((Math.abs(new Date(acceptDetails.startDateTime) - new Date(acceptDetails.endDateTime))) / (1000 * 60 * 60 * 24)) > 1 ){
      alertModal({message : "Meeting length too long!", singleButton : true});
    } else {
      const res = await dispatch(
        updateMeetingStatus({
          userId: sellerId,
          meeting_id: acceptDetails.meetingId,
          customerTimezone: acceptDetails.customerTimezone,
          status: "accepted",
          date: acceptDetails.date,
          startTime: acceptDetails.startDateTime,
          endTime: acceptDetails.endDateTime,
          timezone : sellerData.timezone,
          validate:true,
          productId : acceptDetails.productId,
          storeEmail : sellerData.storeEmail,
          storeName : sellerData.storename,
        })
      );
      if (res && !res.isError) {
        setShowAcceptDetail(false);
        setZoom(res.zoomLink)
      }
    }
    setTitle(" " + title);
    setButtonDisabled(false);
    setShowAcceptDetail(false)
  };

  const handleCloseMeetingExistsModal = () => {
    dispatch({
      type: MEETING_ALREADY_EXISTS,
      payload: false
    })
  };

  const confirmUpdateMeeting = async () => {
    await handleCloseMeetingExistsModal()
    const res = await dispatch(
        updateMeetingStatus({
          userId: sellerId,
          meeting_id: acceptDetails.meetingId,
          customerTimezone: acceptDetails.customerTimezone,
          status: "accepted",
          date: acceptDetails.date,
          startTime: acceptDetails.startDateTime,
          endTime: acceptDetails.endDateTime,
          timezone : sellerData.timezone,
          validate:false,
          productId : acceptDetails.productId,
          storeEmail : sellerData.storeEmail,
          storeName : sellerData.storename,
        })
    );
    if (res && !res.isError) {
      setShowAcceptDetail(false);
      setTitle(" " + title);
    }
  }

  useEffect(()=>{
    if(meetingExists && location.pathname === "/meeting-list"){
      alertModal({
        message : "For this slot meeting already Exists! Do you still want to continue?",
        singleButton : false,
        positiveButton : "Yes, Continue",
        negativeButton : "No, Cancel",
        id : 1,
      })
    }
  },[meetingExists])


  const handleMeetingClick = (item) => {
    setMeetingDetails(item);
    if (item.status === "pending") {
      setShowPendingMeetingModal(true);
    } else {
      setShowViewDetails(true);
    }
  };

  const alertModal = (data) => {
    setAlertState({...data, open : true})
  }

  const closeAlertModal = () => {
    setAlertState({
      open : false,
      message: '',
      singleButton : true
    })
  }

  const handleAlertResponse = (type, id, data) => {
    if(id === 1){
      if(type === "positive"){
        confirmUpdateMeeting()
      }else{
        handleCloseMeetingExistsModal()
      }
    }
    closeAlertModal()
  }


  const handleDateTimeChange = (e, key, type) => {
    const oldDate = new Date(acceptDetails[key]);
    let newDate;
    if (type === 'date') {
      e = moment(e).format("YYYY-MM-DD")
      newDate = new Date(e + ' ' + moment(oldDate).format("HH:mm"))
    } else {
      newDate = new Date(moment(oldDate).format('YYYY-MM-DD') + " " + e)
    }

    if (key === 'startDateTime' && type === 'date') {
      const dateDiff = oldDate.getDate() - newDate.getDate()
    }


    setAcceptDetails({
      ...acceptDetails,
      [key]: moment(newDate).format("YYYY-MM-DD HH:mm")
    })
  }


  useEffect(()=>{
    console.log('useEffect acceptDetails -- ', acceptDetails)},[acceptDetails])

  return (
    <div className="admin-card">
      <div className="admin-card-head">
        <h2>Meeting request list</h2>
      </div>
      {successMessage ? (
        <>
          <div className="alert alert-success">{successMessage}</div>
        </>
      ) : null}
      {errorMessage ? (
        <>
          <div className="alert alert-danger">{errorMessage}</div>
        </>
      ) : null}

      <div className="admin-card-box">
        <div className="vendor-table">
          <div className="vendor-table-head">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                {/* <div className="entries-drop">
                  <label>show</label>
                  <select
                      className="entries-controls"
                    onChange={(e) => {
                      setPagination({
                        limit: e.target.value,
                        pageNumber: 1,
                      });
                    }}
                    value={pagination.limit}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="all">All</option>
                  </select>
                  <p>Entries</p>
                </div> */}

                <div className="mt-4 mb-4">
                  <button
                    onClick={() => setShowCalendar(!showCalendar)}
                    className="orange-btn"
                    disabled = {true}
                  >
                    {showCalendar ? "Hide" : "Show"} Calendar
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showCalendar && (
            <div className="calendar-colorbox">
              <Calendar
                title={title}
                seller_id={sellerId}
                handleMeetingClick={(item) => handleMeetingClick(item)}
              />
            </div>
          )}
          <div className="vendor-table-body">
            <div className="table-responsive">
              <InfiniteScroll
                dataLength={meetingRequestList.length}
                next={() => {
                  setPagination({
                    limit: pagination.limit,
                    pageNumber: pagination.pageNumber + 1,
                  });
                }}
                hasMore={hasMoreMeetingRequests}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <td align="center">Meeting request Day</td>
                      <td>Details</td>
                      {/* <td>sales</td> */}
                      <td align="right" style={{paddingRight:"80px"}}>status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {meetingRequestList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td align="center"> {item.start_time}</td>
                          <td
                            style={{
                              maxWidth: "200px",
                              wordBreak: "break-all",
                            }}
                          >
                            <h6>{item.userFullName}</h6>
                            <p><strong>{item.productName}</strong></p> 
                            <p>{item.comment}</p>
                          </td>
                          <td align="right" style={{whiteSpace: "nowrap"}}>
                            <div className="vendor-table-btn">
                              {item.status === "pending" ? (
                                <>
                                  <button
                                    className="orange-btn"
                                    onClick={() => {
                                      setShowAcceptDetail(true);
                                      setMeetingDetails(item);
                                      setAcceptDetails({
                                        meetingId: item.meeting_id,
                                        customerTimezone:
                                          item.customer_timezone,
                                        date: moment(item.date).format(
                                          "YYYY-MM-DD"
                                        ),
                                        startTime: moment(
                                          new Date(item.start_time),
                                          "hh:mm A"
                                        ).format("HH:mm"),
                                        endTime: moment(
                                          new Date(item.end_time),
                                          "hh:mm A"
                                        ).format("HH:mm"),
                                        productId : item.product_id,
                                        startDateTime : item.start_time,
                                        endDateTime : item.end_time,
                                      });
                                    }}
                                  //onClick={() => handleUpdateStatus(item.meeting_id, "accepted")}
                                  >
                                    Accept
                                  </button>
                                  <button
                                    className="orange-outline-btn"
                                    onClick={() => {
                                      setShowDeclineDetail(true);
                                      setShowViewDetails(false);
                                      setMeetingDetails(item);
                                      setDeclineNotes("");
                                    }}
                                  >
                                    Decline
                                  </button>
                                </>
                              ) : (
                                <>
                                  <Link
                                      to="#"
                                    className="orange orange-a"
                                    onClick={() => {
                                      setShowViewDetails(true);
                                      setMeetingDetails(item);
                                    }}
                                  >
                                    View Details
                                  </Link>
                                  <a
                                      href={item.zoomLink ? item.zoomLink : zoom}
                                      target="_blank"
                                  >
                                    <button className="orange-btn">
                                      Join Now
                                    </button>
                                  </a>
                                  <button
                                    className="orange-outline-btn"
                                    onClick={() => {
                                      setShowDeclineDetail(true);
                                      setMeetingDetails(item);
                                      setShowViewDetails(false);
                                      setDeclineNotes("");
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </InfiniteScroll>
              {showLoader ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <Modal
            show={showViewDetails}
            onHide={() => setShowViewDetails(false)}
            className="calendar-modal"
            centered
          >
            <Modal.Header closeButton onClick={() => setShowViewDetails(false)}>
              <b>Meeting Details</b>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div>
                    <label>{meetingDetails.userFullName}</label>
                    <br />
                    <label>Class Type: {meetingDetails.productName}</label>
                    <br />
                    <label>{meetingDetails.comment}</label>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  {meetingDetails.start_time}
                </div>
              </div>
              <div className="modal-btn-group">
                <a href={meetingDetails.zoom_link ? meetingDetails.zoom_link : zoom} target="_blank">
                  <Button
                    variant="secondary"
                    //onClick={(e) => handleSetMeetingType(e, 0)}
                    className="orange-btn"
                  >
                    Join Now
                  </Button>
                </a>

                <Button
                  variant="secondary"
                  className="orange-btn"
                  onClick={() => {
                    setShowDeclineDetail(true);
                    setShowViewDetails(false);
                    setMeetingDetails(meetingDetails);
                    setDeclineNotes("");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={showAcceptDetail}
            onHide={() => setShowAcceptDetail(false)}
            className="calendar-modal"
            centered
          >
            <Modal.Header
              closeButton
              onClick={() => setShowAcceptDetail(false)}
            >
              <b>Accept Request</b>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                {/* <div className="col-md-12"> */}
                <div className="form-group"></div>
                {/* </div> */}
                <>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <b>START DATE</b>
                      </label>
                      {/*<input
                        type="date"
                        name="date"
                        required
                        onChange={(e) => handleDateTimeChange(e, 'startDateTime', 'date')}
                        className="form-control"
                        value={moment(acceptDetails.startDateTime).format("YYYY-MM-DD")}
                      />*/}
                      <DatePicker
                          name="date"
                          dayPlaceholder={"dd"}
                          monthPlaceholder={"mm"}
                          yearPlaceholder={"yyyy"}
                          required
                          clearIcon={null}
                          onChange={(e) => handleDateTimeChange(e, 'startDateTime', 'date')}
                          className="form-control"
                          value={new Date(moment(acceptDetails.startDateTime).format("YYYY-MM-DD"))}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <b>END DATE</b>
                      </label>
                      {/*<input
                          type="date"
                          name="date"
                          required
                          onChange={(e) => handleDateTimeChange(e, 'endDateTime', 'date')}
                          className="form-control"
                          value={moment(acceptDetails.endDateTime).format("YYYY-MM-DD")}
                      />*/}
                      <DatePicker
                          name="date"
                          dayPlaceholder={"dd"}
                          monthPlaceholder={"mm"}
                          yearPlaceholder={"yyyy"}
                          required
                          clearIcon={null}
                          onChange={(e) => handleDateTimeChange(e, 'endDateTime', 'date')}
                          className="form-control"
                          value={new Date(moment(acceptDetails.endDateTime).format("YYYY-MM-DD"))}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <b>START TIME</b>
                      </label>
                      {/*<input
                        type="time"
                        name="start"
                        required
                        onChange={(e) => handleDateTimeChange(e, 'startDateTime', 'time')}
                        className="form-control"
                        value={moment(acceptDetails.startDateTime).format("HH:mm")}
                        // value="2021-08-19T17:30:00.000Z"
                      />*/}
                      <TimePicker
                          value={moment(acceptDetails.startDateTime).format("HH:mm")}
                          format="hh:mm a"
                          name="start"
                          disableClock={true}
                          clearIcon={null}
                          onChange={(e) => handleDateTimeChange(e, 'startDateTime', 'time')}
                          required={true}
                          className="form-control"
                          />
                          <span className="clock-icon"><i class="far fa-clock"></i></span>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <b>END TIME</b>
                      </label>
                      {/*<input
                          type="time"
                        name="end"
                        min="00:00"
                        max="23:59"
                          onChange={(e) => handleDateTimeChange(e, 'endDateTime', 'time')}
                        required
                        className="form-control"
                        value={moment(acceptDetails.endDateTime).format("HH:mm")}
                      />*/}
                      <TimePicker
                          value={moment(acceptDetails.endDateTime).format("HH:mm")}
                          format="hh:mm a"
                          name="end"
                          disableClock={true}
                          clearIcon={null}
                          onChange={(e) => handleDateTimeChange(e, 'endDateTime', 'time')}
                          required={true}
                          className="form-control"
                          />
                          <span className="clock-icon"><i class="far fa-clock"></i></span>
                    </div>
                  </div>
                </>
              </div>
              <div className="button-center">
                <button
                  onClick={handleAcceptModalSubmit}
                  className="orange-btn"
                  disabled={btnDisabled}
                >
                  Share Link
                </button>
              </div>
            </Modal.Body>
          </Modal>

          {/* decline modal */}

          <Modal
            show={showDeclineDetail}
            onHide={() => setShowDeclineDetail(false)}
            className="calendar-modal"
            centered
          >
            <Modal.Header
              closeButton
              onClick={() => setShowDeclineDetail(false)}
            >
              {" "}
              <b>
                {meetingDetails.status === "accepted" ? (
                  <>Cancel</>
                ) : (
                  <>Decline</>
                )}{" "}
                Request
              </b>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <p>
                    Are you sure you want to{" "}
                    {meetingDetails.status === "accepted" ? (
                      <>Cancel</>
                    ) : (
                      <>Decline</>
                    )}
                    ?
                  </p>
                </div>
                <>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <b>
                          {meetingDetails.status === "accepted" ? (
                            <>Cancel</>
                          ) : (
                            <>Decline</>
                          )}{" "}
                          note
                        </b>
                      </label>
                      <input
                        type="text"
                        name="decline note"
                        placeholder={`Please enter ${meetingDetails.status === "accepted"
                            ? "Cancel"
                            : "Decline"
                          } note.`}
                        required
                        onChange={(e) => setDeclineNotes(e.target.value)}
                        className="form-control"
                        value={declineNotes}
                      />
                    </div>
                  </div>
                </>
              </div>
              <div className="button-center">
                <button
                  disabled={declineNotes === ""}
                  onClick={() => {
                    handleUpdateStatus(null, "rejected");
                  }}
                  className="orange-btn"
                >
                  {meetingDetails.status === "accepted" ? (
                    <>Cancel</>
                  ) : (
                    <>Decline</>
                  )}
                </button>
                {meetingDetails.status !== "accepted" && (
                  <button
                    onClick={() => {
                      setShowDeclineDetail(false);
                    }}
                    className="orange-btn"
                  >
                    Cancel
                  </button>
                )}
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={showPendingMeetingModal}
            onHide={() => setShowDeclineDetail(false)}
            className="calendar-modal"
            centered
          >
            <Modal.Header
              closeButton
              onClick={() => setShowPendingMeetingModal(false)}
            >
              {" "}
              <b>Pending Request</b>
            </Modal.Header>
            <Modal.Body>
              <div className="button-center">
                <button
                  className="orange-btn"
                  onClick={() => {
                    setShowPendingMeetingModal(false);
                    setShowAcceptDetail(true);
                    setAcceptDetails({
                      meetingId: meetingDetails.meeting_id,
                      customerTimezone: meetingDetails.customer_timezone,
                      date: moment(meetingDetails.date).format("YYYY-MM-DD"),
                      startTime: moment(meetingDetails.start, "hh:mm A").format(
                        "HH:mm"
                      ),
                      endTime: moment(meetingDetails.end, "hh:mm A").format(
                        "HH:mm"
                      ),
                      productId : meetingDetails.productId,
                      startDateTime : moment(meetingDetails.start_time).format("YYYY-MM-DD HH:mm"),
                      endDateTime : moment(meetingDetails.end_time).format("YYYY-MM-DD HH:mm"),
                    });
                  }}
                >
                  Accept
                </button>
                <button
                  className="orange-btn"
                  onClick={() => {
                    setShowPendingMeetingModal(false);
                    setShowDeclineDetail(true);
                    setDeclineNotes("");
                  }}
                >
                  Decline
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <AlertModal
              data={alertState}
              closeAlertModal={closeAlertModal}
              handleAlertResponse={(type, id, data)=>handleAlertResponse(type, id, data)}
          />
        </div>
      </div>
    </div>
  );
}

export default MeetingList;
