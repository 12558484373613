import React, { Component } from 'react'
import '../../Styles/dashboard.scss'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from '../../Redux/Actions/authAction';
import { toggleSidebar } from '../../Redux/Actions/commonAction';



class Header extends Component {

    constructor(props) {
        super(props);
    }

    handleLogout = async (event) => {
        event.preventDefault();
        this.props.logout();
    };

    render() {
        return (
            <div className="main-header">
                <div className="row">
                    <div className="col-lg-10 col-md-10">
                        <div className="header-top-left">
                            <div className="toggle-btn">
                                <button onClick={() => this.props.toggleSidebar()} type="button"><i className="fas fa-bars"></i></button>
                            </div>
                        </div>


                    </div>
                    <div className="col-lg-2 col-md-2">
                        <div className="header-top-menu">
                            <ul>
                                <li><a href="#" className="header-addtocart-item"><i className="far fa-bell"></i>
                                    {/*<span>30</span>*/}
                                </a></li>
                                <li><a title="sign out" onClick={(event) => this.handleLogout(event)} href="#"><i className="fas fa-power-off"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ logout, toggleSidebar }, dispatch)
}

export default connect(null, mapDispatchToProps)(Header)
