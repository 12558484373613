import { GET_SELLER_MEETING_LIST, GET_MEETING_REQUEST_LIST, DELETE_MEETING, UPDATE_MEETING_STATUS, DELETE_SELECTED_SLOT, CREATE_MASTER_MEETING, MEETING_ALREADY_EXISTS, CLOSE_MEETING_MODAL } from '../ActionType'
export const initialState = {
    meetingList: [],
    meetingExists: false,
    meetingRequestList: [],
    hasMoreMeetingRequests: false,
    successMessage: "",
    errorMessage: "",loading: false,
};

export const meetingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SELLER_MEETING_LIST:
            return {
                ...state,
                meetingList: action.payload.data.masterSchedule.map((slot, index) => ({ ...slot, title: slot.userFullName, start_time: slot['start'], end_time: slot['end'], start: new Date(slot['start'].replace(/-/g, "/")), end: new Date(slot['end'].replace(/-/g, "/")) }))
            }
        case CREATE_MASTER_MEETING:
            return {
                ...state,
                meetingList: [...state.meetingList, action.payload]
            }
        case MEETING_ALREADY_EXISTS:
            return {
                ...state,
                meetingExists: action.payload
            }
        case CLOSE_MEETING_MODAL:
            return {
                ...state,
                meetingExists: action.payload
            }
        case GET_MEETING_REQUEST_LIST:
            return {
                ...state,
                meetingRequestList: action.payload.data,
                hasMoreMeetingRequests: action.payload.hasMore
            }
        case 'ADD_MEETING_REQUEST_LIST':
            return {
                ...state,
                meetingRequestList: [...state.meetingRequestList, ...action.payload.data],
                hasMoreMeetingRequests: action.payload.hasMore
            }
        case UPDATE_MEETING_STATUS:
            const updateMeeting = state.meetingRequestList.map(item => item.meeting_id === action.payload.meeting_id ? ({ ...item, status: action.payload.status }) : item)
            return {
                ...state,
                meetingRequestList: [...updateMeeting]
            }

        case DELETE_MEETING:
            const deleteMeeting = state.meetingRequestList.filter(item => item.meeting_id !== action.payload.meeting_id);
            return {
                ...state,
                meetingRequestList: [...deleteMeeting]
            }
        case "SET_SUCCESS":
            return {
                ...state,
                successMessage: action.payload.message,
            }
        case "SET_ERROR":
            return {
                ...state,
                errorMessage: action.payload.message
            }

        case "LOADING_STARTED":
            return {
                  ...state,
                  loading: true,
                };
        case "LOADING_COMPLETED":
            return {
                  ...state,
                  loading: false,
                };
        default:
            return state
    }
}